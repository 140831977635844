import React from 'react';
import RoomIcon from '@material-ui/icons/Explore';

const StationWrap = ({ state, stations, changeStationMobile, classes }) => {
  return (
    <>
      <div className={classes.stateWrapper}>
        <p className={classes.stateText}>
          <RoomIcon className={classes.stateIcon} />
          {state}
        </p>
        <div className={classes.lineDivider} />
      </div>

      {stations.map(station => {
        return (
          <div
            key={station.name + station.city}
            className={classes.stationGroup}
            onClick={() => changeStationMobile(station)}
          >
            <p className={classes.stationText}>{station.name}</p>
            <span className={classes.stationSpan}>{station.city}</span>
          </div>
        );
      })}
    </>
  );
};

export default StationWrap;
