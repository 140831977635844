import calculateMean from './mean';
import calculateExposure from './exposure-calculator';
import getLevel from './get-level';
import calculateDistribution from './calculate-distribution';
import apiErrorCheck from './api-error-check';
import { BANDS } from '../config';

const getSafeLimit = pollutant => {
  return {
    value: BANDS[0].range[pollutant],
    color: BANDS[0].color,
    label: BANDS[0].label,
  };
};

export {
  getSafeLimit,
  calculateMean,
  calculateExposure,
  getLevel,
  calculateDistribution,
  apiErrorCheck,
};
