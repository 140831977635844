import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { getRegions } from 'common/api/region/get-region';

const getRegionsList = async setRegions => {
  const { data } = await getRegions();
  setRegions(data.availableRegions);
};

const RegionRoutes = ({ classes, toggleSidebar }) => {
  const [regions, setRegions] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getRegionsList(setRegions);
  }, [setRegions]);

  return (
    <>
      {regions !== null && (
        <>
          <ListItem
            className={classes.listItem}
            button
            onClick={() => setOpen(!open)}
          >
            Region Dashboard
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {regions.map(region => (
              <>
                <List component="div">
                  <NavLink
                    to={`/region/${region}`}
                    className={classes.linkStyle}
                    activeClassName={classes.activeRoute}
                    onClick={() => toggleSidebar()}
                  >
                    <ListItem
                      button
                      className={classes.listItem}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {region}
                    </ListItem>
                  </NavLink>
                </List>
              </>
            ))}
          </Collapse>
        </>
      )}
    </>
  );
};

export default RegionRoutes;
