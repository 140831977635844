import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { URLS } from 'common/config';

const useStyles = makeStyles(theme => ({
  termsAndPolicy: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    '& > a': {
      margin: '0px 8px',
      '&:hover': {
        color: '#1a63fb !important',
      },
    },
    height: '100%',
    justifyContent: 'center',
  },
}));

const ExternalLink = ({ url, text }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: 'inherit', textDecoration: 'none' }}
  >
    {text}
  </a>
);
const TermsPrivacyComponent = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.termsAndPolicy}>
        <ExternalLink url={URLS['terms']} text={'Terms of Use'} />
        •
        <ExternalLink url={URLS['privacy']} text={'Privacy Policy'} />
      </div>
    </>
  );
};

export default TermsPrivacyComponent;
