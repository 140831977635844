import React from 'react';
import { connect } from 'react-redux';
import CrashPage from '../pages/crash-page';

function ErrorBoundary({ appCrash, children }) {
  if (appCrash) return <CrashPage />;
  else return children;
}

const mapStateToProps = ({ appCrash }) => {
  return { appCrash };
};

export default connect(mapStateToProps)(ErrorBoundary);
