import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Footer, Header } from '../layout';
import Button from '@material-ui/core/Button';
import GetCode from '../components/get-code';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Embeds } from 'common/config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function SimpleDialog(props) {
  const { onClose, open, title } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1.5rem 10rem 0 10rem',
  },

  tabsRoot: {
    minHeight: 'unset',
  },
  tabRoot: {
    minWidth: 'unset',
    minHeight: 'unset',
    padding: '1rem',
    color: 'rgba(0,0,0,0.7)',
    '&.tabRootSelected': {
      color: '#000',
    },
  },
  disable: {
    opacity: 0.5,
  },
  flexContainer: {
    justifyContent: 'space-between',
  },
  tabsIndicator: {
    backgroundColor: '#000',
    transition: 'all 600ms cubic-bezier(0.4,0,0.2,1) 0ms',
  },
  tab: {
    fontSize: 12,
    minWidth: 140,
  },
  [theme.breakpoints.down('md')]: {
    root: {
      padding: '0rem 5rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '0 2rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: '0 0.5rem',
    },
    tabRoot: {
      padding: '0rem',
    },
  },
}));

function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [embedUrl, setEmbedUrl] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getEmbedUrl = () => {
    const iframeWindow = document.getElementById('embed').contentWindow;
    const iframeSrc = iframeWindow.location.href;
    setOpen(true);
    setEmbedUrl(iframeSrc);
  };

  const {
    location: { origin },
  } = window;
  return (
    <>
      <Header />
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '20px 0',
          }}
        >
          <Button
            variant="contained"
            onClick={() => getEmbedUrl()}
            disableElevation={true}
          >
            Get Code
          </Button>
        </div>
        <SimpleDialog
          open={open}
          onClose={() => setOpen(false)}
          title={'Embed iframe code'}
        >
          <GetCode url={embedUrl} />
        </SimpleDialog>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          classes={{
            root: classes.tabsRoot,
            flexContainer: classes.flexContainer,
            indicator: classes.tabsIndicator,
          }}
        >
          {Object.keys(Embeds).map((key, i) => {
            return <Tab label={Embeds[key]} className={classes.tab} />;
          })}
        </Tabs>
        {Object.keys(Embeds).map((key, i) => {
          return (
            <TabPanel value={value} index={i} dir={theme.direction}>
              <iframe
                title={Embeds[key]}
                src={`${origin}/embed/${key}`}
                width="100%"
                height="500px"
                id="embed"
                style={{ border: '1px solid #C9C9C9', borderRadius: 8 }}
              ></iframe>
            </TabPanel>
          );
        })}
      </div>
      <Footer />
    </>
  );
}

export default FullWidthTabs;
