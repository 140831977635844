import { colors as COLORS, getDuration, Embeds } from 'common/config';
import React from 'react';
import VizCard from './visualization-card';
import { Exposure as ExposureDialog } from './dialog-content';
import BarChart from './barchart';
import { SupportingChartsEvent } from '../ga-events';

const Exposure = ({ exposure, duration }) => {
  if (!exposure || !duration) return null;
  const exposureCount = Object.keys(exposure).length;

  let graphData = Object.keys(exposure)
    .map((slug, index) => {
      if (!exposure[slug]) return null;
      return {
        val: exposure[slug].value || 0,
        label: exposure[slug].value ? (exposure[slug].value || 0) + 'X' : '',
        fill: COLORS[index],
        item: `${
          exposureCount === 1 ? 'Actual Level' : `Station ${index + 1}`
        }`,
      };
    })
    .filter(element => element !== null);

  const safeData = {
    val: 1,
    label: '1.0X',
    fill: '#009900',
    item: `${exposureCount < 3 ? 'Safe Level' : `Safe`}`,
  };
  graphData.unshift(safeData);

  return (
    <VizCard
      embedName={Object.keys(Embeds)[4]}
      label={`Last ${getDuration(duration).title}`}
      chart={<BarChart data={graphData} />}
      title="Exposure"
      dialog={<ExposureDialog />}
      dialogEvent={({ action }) =>
        SupportingChartsEvent({ action: action, label: 'exposure graph' })
      }
    />
  );
};

export default Exposure;
