import React from 'react';
import { getRangeWithLabel as POLLUTANT_RANGE } from 'common/config';

export default function PollutantRanges({ pollutant }) {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {POLLUTANT_RANGE.map((value, index) => {
        const [start, end] = value.range[pollutant];
        let text = '';
        if (index === 0) {
          text = `<${end}`;
        } else if (index === POLLUTANT_RANGE.length - 1) {
          text = `>${start}`;
        } else {
          text = `${start}-${end}`;
        }

        return (
          <div
            key={index}
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: 10,
            }}
          >
            <div
              style={{
                paddingTop: 2,
                paddingBottom: 2,
                backgroundColor: value.color,
                color: 'white',
              }}
            >
              {text}
            </div>
            <div style={{ marginTop: 'auto', color: '#A6A6A6' }}>
              {value.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}
