import { pollutantOptions as Pollutants } from 'common/config';
import { getLastUpdate, getLastKnownValue } from '../../utils/chart-utils';
import dayjs from 'dayjs';
import TableCell from '@material-ui/core/TableCell';
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { getLevel } from 'common/utils';
import Paper from '@material-ui/core/Paper';
import { TableSortLabel } from '@material-ui/core';

function calculateData(data) {
  const result = Object.keys(data).reduce(
    (acc, slug) => {
      if (!data[slug]) return acc;
      const cigaretteData = Object.assign(acc.cigarettes, {
        [slug]: getCigarettes(data[slug].data),
      });

      const dataObj = Object.keys(Pollutants).map(key => ({
        key,
        label: Pollutants[key],
        val: getLastKnownValue(data[slug].data, key)
          ? getLastKnownValue(data[slug].data, key)[key]
          : '-',
      }));

      return {
        pollutantData: Object.assign(acc.pollutantData, { [slug]: dataObj }),
        cigarettes: cigaretteData,
      };
    },
    {
      pollutantData: {},
      cigarettes: {},
    }
  );

  return result;
}

function createSortableData(
  data,
  exposure,
  cigarettes,
  availabilityGraphData,
  mean
) {
  if (!data) return null;
  const updated = calculateUpdateDashboard(data);
  let dataArray = [];

  Object.keys(data).forEach(slug => {
    data[slug].exposure = exposure[slug].value
      ? parseFloat(exposure[slug].value)
      : 0;
    data[slug].cigarettes = cigarettes[slug] ? cigarettes[slug] : 0;
    data[slug].updated = updated[slug] !== null ? dayjs(updated[slug]) : 0;
    data[slug].availabilityGraphData = availabilityGraphData[slug];
    data[slug].mean = !isNaN(mean[slug]) ? mean[slug] : 0;
    dataArray.push(data[slug]);
  });

  return dataArray;
}

function getCigarettes(data) {
  if (!data) return null;
  const arr = data.map(({ pm25 }) => pm25).filter(e => e);
  if (arr.length <= 0) return;

  const avg = arr.reduce((acc, val) => val + acc, 0) / arr.length;
  return Math.round(avg / 22);
}

function calculateUpdate(data) {
  if (!data) return null;
  const lastData = Object.keys(data).reduce((acc, slug) => {
    return Object.assign(acc, {
      [slug]: getLastUpdate(data[slug].data.slice().reverse()),
    });
  }, {});
  const updated = Object.keys(lastData).reduce((acc, slug) => {
    if (!lastData[slug]) return Object.assign(acc, { [slug]: '-' });
    return Object.assign(acc, { [slug]: dayjs(lastData[slug]).fromNow() });
  }, {});
  return updated;
}

function calculateUpdateDashboard(data) {
  if (!data) return null;
  const lastData = Object.keys(data).reduce((acc, slug) => {
    return Object.assign(acc, {
      [slug]: getLastUpdate(data[slug].data.slice().reverse()),
    });
  }, {});
  const updated = Object.keys(lastData).reduce((acc, slug) => {
    return Object.assign(acc, { [slug]: lastData[slug] });
  }, {});
  return updated;
}

function getAvailabilityGraph(data, pollutant) {
  if (!data || !pollutant) return null;
  const dataAvailability = Object.keys(data).reduce((acc, slug) => {
    return Object.assign(acc, {
      [slug]: Math.round(
        (data[slug].data.filter(({ [pollutant]: val }) => val).length /
          data[slug].data.length) *
          100
      ),
    });
  }, {});

  const availabilityGraphData = Object.keys(dataAvailability).reduce(
    (acc, slug, index) => {
      return Object.assign(acc, {
        [slug]: [
          {
            val: 100 - dataAvailability[slug],
            color: '#F1EFF1',
          },
          {
            val: dataAvailability[slug],
            color: '#9B9A9B',
          },
        ],
      });
    },
    {}
  );

  return availabilityGraphData;
}

const StyledTableCell = withStyles(theme => ({
  root: {
    fontSize: '12px',
    paddingRight: 0,
    paddingLeft: 0,
    textAlign: 'center',
    borderBottom: '1px solid #F4F4F4',

    width: props => (props.fixedwidth ? '3rem' : ''),
  },
}))(TableCell);

const pollutantCellStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    paddingRight: '0.25rem',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    minWidth: '3rem',
  },
  selectedLabel: {
    background: '#F4F4F4',
    fontWeight: 'bold',
  },
  hovered: {
    background: '#F4F4F4',
  },
  [theme.breakpoints.down('md')]: {
    root: {
      fontSize: 12,
      minWidth: '2.5rem',
    },
  },
}));

const PollutantCell = ({
  selectedPollutant,
  pollutant,
  label,
  value,
  hoveredOver,
  setHoveredOver,
  changePollutant,
  googleEvent,
}) => {
  const classes = pollutantCellStyles();
  return (
    <StyledTableCell
      className={` ${classes.root}
        ${hoveredOver === pollutant ? classes.hovered : ''}
        ${selectedPollutant === pollutant ? classes.selectedLabel : ''} 
        `}
      onMouseEnter={() => setHoveredOver(pollutant)}
      onMouseLeave={() => setHoveredOver(null)}
      onClick={() => {
        changePollutant(pollutant);
        googleEvent({
          action: `changed pollutant to ${pollutant}`,
          label: 'change by clicking table',
        });
      }}
      align="center"
      style={{
        color: getLevel(label, pollutant).color,
        opacity: isNaN(value) ? 0.4 : 1,
      }}
    >
      {label || '-'}
    </StyledTableCell>
  );
};

const PollutantNameCell = ({
  selectedPollutant,
  pollutant,
  label,
  hoveredOver,
  setHoveredOver,
  changePollutant,
  googleEvent,
}) => {
  const classes = pollutantCellStyles();
  return (
    <StyledTableCell
      className={` ${classes.root}
        ${hoveredOver === pollutant ? classes.hovered : ''}
        ${selectedPollutant === pollutant ? classes.selectedLabel : ''}
        `}
      onMouseEnter={() => setHoveredOver(pollutant)}
      onMouseLeave={() => setHoveredOver(null)}
      onClick={() => {
        changePollutant(pollutant);
        googleEvent({
          action: `changed pollutant to ${pollutant}`,
          label: 'change by clicking table',
        });
      }}
      align="center"
      style={{ color: '#000' }}
    >
      {label}
    </StyledTableCell>
  );
};

//Functional component
const RegionDBPollutantNameCell = ({
  selectedPollutant,
  pollutant,
  label,
  hoveredOver,
  setHoveredOver,
  changePollutant,
  order,
  orderBy,
  createSortHandler,
}) => {
  const classes = pollutantCellStyles();
  return (
    <StyledTableCell
      className={` ${classes.root}
        ${hoveredOver === pollutant ? classes.hovered : ''}
        ${selectedPollutant === pollutant ? classes.selectedLabel : ''}
        `}
      onMouseEnter={() => setHoveredOver(pollutant)}
      onMouseLeave={() => setHoveredOver(null)}
      align="center"
      style={{ color: '#000' }}
    >
      <TableSortLabel
        active={orderBy === pollutant}
        direction={orderBy === pollutant ? order : 'asc'}
        onClick={createSortHandler(pollutant)}
      >
        {label}
      </TableSortLabel>
    </StyledTableCell>
  );
};

//Functional component
const CustomDataAvailabilityTooltip = ({ data }) => {
  if (data.length < 1) return null;

  return (
    <Paper
      style={{
        minWidth: '10rem',
        textAlign: 'center',
        padding: '0.5rem',
        position: 'relative',
        top: -30,
        left: -10,
      }}
    >
      Data Availability: {data[1].val} %
    </Paper>
  );
};

const SmallPieChart = ({ data, color }) => {
  return (
    <ResponsiveContainer width="100%" height={50}>
      <PieChart>
        <Pie
          data={data}
          labelLine={false}
          innerRadius={'20%'}
          outerRadius={'50%'}
          dataKey="val"
          startAngle={-270}
          name="Data Availability"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              stroke={entry.color}
              fill={entry.color}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomDataAvailabilityTooltip data={data} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

function getPollutantData(
  tableEvents,
  data,
  pollutant,
  hoveredOver,
  setHoveredOver,
  changePollutant,
  mobile,
  tablet
) {
  const emptyData = Object.keys(Pollutants).map(value => {
    return { key: value, label: Pollutants[value], val: '-' };
  });
  const cellData = data ? data : emptyData;
  return cellData.map(({ key, val }) => {
    if (!key || !val) {
      key = '';
      val = '-';
    }
    if (key !== pollutant && (mobile || tablet)) return null;
    return (
      <PollutantCell
        googleEvent={tableEvents}
        key={key}
        selectedPollutant={pollutant}
        pollutant={key}
        label={val}
        value={val}
        hoveredOver={hoveredOver}
        setHoveredOver={setHoveredOver}
        changePollutant={changePollutant}
      />
    );
  });
}

export {
  getPollutantData,
  PollutantCell,
  PollutantNameCell,
  SmallPieChart,
  calculateData,
  calculateUpdate,
  CustomDataAvailabilityTooltip,
  getAvailabilityGraph,
  createSortableData,
  RegionDBPollutantNameCell,
  calculateUpdateDashboard,
};
