import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Factory } from '../assets/images/factory2.svg';
import { ReactComponent as Pollution } from '../assets/images/pollution.svg';

// TODO: Reduce the CSS and combine the media queries

const crashPageStyles = makeStyles(theme => ({
  crash: {
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FCFDFF',
    textAlign: 'center',
    overflowX: 'hidden',
  },
  crashWrapper: { zIndex: '99999999' },
  factory: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  pollution: {
    width: '450px',
  },
  crashH1: {
    fontSize: '2.1rem',
    color: '#154784',
    marginBottom: '0px',
  },
  crashP: {
    margin: 0,
    fontSize: '1.5rem',
    color: '#154784',
    opacity: '0.7',
  },
  crashButton: {
    margin: 0,
    fontSize: '1rem',
    outline: 0,
    border: 0,
    background: '#154784',
    color: 'white',
    fontWeight: 500,
    padding: '7px 20px',
    borderRadius: '5px',
    borderBottom: '4px solid #0b203a',
    marginTop: '1.8rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      background: '#265c9e',
      transition: 'all 0.3s linear',
    },
    '&:active': {
      border: 0,
      transition: 'all 0.5s ease-in',
    },
  },
  goHome: {
    display: 'block',
    color: '#154784ad',
    marginTop: '5px',
    fontSize: '0.9rem',
    fontWeight: '600',
    textDecoration: 'none',
    '&:hover': {
      color: '#154784',
    },
  },
  [theme.breakpoints.down(1441)]: {
    pollution: {
      width: '300px',
    },
    crashH1: {
      fontSize: '1.8rem',
    },
    crashP: {
      fontSize: '1.2rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    pollution: {
      width: '80%',
    },
    crashH1: {
      fontSize: '1.2rem',
      width: '80%',
      margin: '0 auto',
      marginTop: '13px',
    },
    crashP: {
      fontSize: '1rem',
    },
  },
}));

function CrashPage() {
  const classes = crashPageStyles();

  return (
    <>
      <div className={classes.crash}>
        <div className={classes.crashWrapper}>
          <Pollution className={classes.pollution} />
          <h1 className={classes.crashH1}>
            Too much pollution, even BreeZo can’t breathe
          </h1>
          <p className={classes.crashP}>Give BreeZo a breath of fresh air.</p>
          <a href=" ">
            <button className={classes.crashButton}>Refresh BreeZo</button>
          </a>
          <a href="/" className={classes.goHome}>
            go to home
          </a>
        </div>
      </div>
      <Factory className={classes.factory} />
    </>
  );
}
export default CrashPage;
