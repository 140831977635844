import React, { Component } from 'react';
import { compose } from 'redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OpenExternalIcon from '@material-ui/icons/OpenInNew';
import UnderConstructionSVG from '../assets/images/under-construction.svg';
import { ReactComponent as LogoImage } from '../assets/icons/breezo-blue.svg';
import { Auth0Context } from '../auth/auth0';
import { toggleSidebar } from 'common/store/actions';
import { SideBarEvent, UserEvent } from './ga-events';
import RegionRoutes from './region-dashboard/region-routes';
import { URLS } from 'common/config';

// TODO: Just go over it and make normal changes

const styles = theme => ({
  logo: {
    height: 110,
    width: 'auto',
    margin: '20px auto',
  },
  heading: {
    fontSize: '22px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    color: theme.palette.text.primary,
    padding: '1rem 24px',
    margin: 0,
  },
  listItem: {
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  activeRoute: {
    display: 'block',
    borderLeft: `4px solid ${theme.palette.text.primary}`,
  },
  [theme.breakpoints.down('md')]: {
    heading: {
      padding: '1rem 16px',
    },
    displayNone: {
      display: 'none',
    },
  },
});

const ExternalLinks = ({ classes }) => {
  const { t } = useTranslation();
  return (
    <List component="nav">
      {[
        {
          label: t('breezoWhat'),
          to: URLS['breezo-about'],
        },
        {
          label: t('breezoWho'),
          to: URLS['bsa-about'],
        },
        {
          label: 'Get API Access',
          to: URLS['breezo-about'],
        },
      ].map(page => (
        <ListItem
          target="_blank"
          title="Will Open in a new Tab"
          className={classes.listItem}
          key={page.label}
          to={page.to}
          onClick={() => {
            SideBarEvent({ action: `clicked on ${page.label}` });
            window.open(page.to);
          }}
          button
        >
          <span>{page.label}</span>
          <OpenExternalIcon style={{ fontSize: 14 }} />
        </ListItem>
      ))}
    </List>
  );
};

class Sidebar extends Component {
  static contextType = Auth0Context;

  render() {
    const { toggleSidebar, classes, t } = this.props;
    const { isAuthenticated, logout, login, user } = this.context;

    return (
      <React.Fragment>
        <LogoImage className={classes.logo} />

        <List component="nav">
          {[
            {
              label: t('nav.home'),
              to: '/',
              exact: true,
            },
            {
              label: 'All Stations',
              to: '/monitors',
            },
            {
              label: t('nav.news'),
              to: '/news',
            },
            {
              label: t('nav.embed'),
              to: '/embed',
              hideMobile: true,
            },
          ].map(page => (
            <NavLink
              key={page.label}
              to={page.to}
              className={`${classes.linkStyle} ${
                page.hideMobile ? classes.displayNone : ''
              }`}
              exact={page.exact}
              activeClassName={classes.activeRoute}
              onClick={() => {
                SideBarEvent({ action: `clicked on ${page.label}` });
                toggleSidebar();
              }}
            >
              <ListItem className={classes.listItem} button>
                <span>{page.label}</span>
                {page.underConstruction && (
                  <img
                    height={14}
                    src={UnderConstructionSVG}
                    alt="under construction"
                  />
                )}
              </ListItem>
            </NavLink>
          ))}
        </List>

        <Divider />
        <ExternalLinks {...this.props} />

        <List
          style={{
            marginTop: 'auto',
          }}
          component="nav"
        >
          {isAuthenticated ? (
            <React.Fragment>
              <RegionRoutes classes={classes} toggleSidebar={toggleSidebar} />
              <NavLink
                to="/view-stats"
                className={classes.linkStyle}
                activeClassName={classes.activeRoute}
                onClick={toggleSidebar}
              >
                <ListItem className={classes.listItem}>
                  Internal System Stats
                </ListItem>
              </NavLink>
              <NavLink
                to="/monitor-stats"
                className={classes.linkStyle}
                activeClassName={classes.activeRoute}
                onClick={toggleSidebar}
              >
                <ListItem className={classes.listItem}>Monitor Stats</ListItem>
              </NavLink>
              <NavLink
                to="/metrics-monitoring"
                className={classes.linkStyle}
                activeClassName={classes.activeRoute}
                onClick={toggleSidebar}
              >
                <ListItem className={classes.listItem}>
                  Metrics Monitor
                </ListItem>
              </NavLink>
              <ListItem className={classes.listItem}>
                {user ? user.name : ''}
              </ListItem>
              <ListItem
                className={classes.listItem}
                onClick={() => {
                  logout();
                }}
                button
              >
                {t('signOut')}
              </ListItem>
            </React.Fragment>
          ) : (
            <ListItem
              className={classes.listItem}
              onClick={() => {
                UserEvent({
                  action: 'clicked on sign in',
                });
                login();
              }}
              button
            >
              {t('signIn')}
            </ListItem>
          )}
        </List>
      </React.Fragment>
    );
  }
}

export default compose(
  connect(null, { toggleSidebar }),
  withRouter,
  withStyles(styles, { withTheme: true }),
  withTranslation()
)(Sidebar);
