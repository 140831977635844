import React, { useState } from 'react';
import Dropdown from './year-selector';
import VizCard from './visualization-card';
import { RadarGraph } from './dialog-content';
import Chart from './radar-chart';
import { Embeds, YEARS, getAvailabilityYear } from 'common/config';
import { useSelector } from 'react-redux';
import { SupportingChartsEvent } from '../ga-events';

function RadarComponent({ pollutant, selectedStations, earliestDuration }) {
  const [year, changeYear] = useState(YEARS[0]);

  const years = getAvailabilityYear(earliestDuration).map(key => ({
    label: key,
    key,
  }));

  const locations = useSelector(state => state.locations);

  const stations = selectedStations.map(slug => {
    return locations.find(({ slug: stationSlug }) => stationSlug === slug);
  });

  return (
    <VizCard
      embedName={Object.keys(Embeds)[5]}
      label={
        <Dropdown
          selected={year}
          options={years}
          onChange={changeYear}
          googleEvent={({ action }) => {
            SupportingChartsEvent({ action: action, label: 'radar' });
          }}
        />
      }
      chart={
        <Chart
          year={year}
          stations={stations}
          pollutant={pollutant}
          changeYear={changeYear}
        />
      }
      title="Radar Graph" //title for info dialog box
      dialog={<RadarGraph />}
      dialogEvent={({ action }) =>
        SupportingChartsEvent({ action: action, label: 'radar graph' })
      }
    />
  );
}

export default RadarComponent;
