import React from 'react';
import {
  SafeLine,
  MeanLine,
  FestiveLine,
  CustomizedAxisTick,
  CustomToolTip,
  CustomCursor,
  ReferenceBands,
  RenderLegend,
  PredictedReferenceArea,
} from './chart-inner-components';
import {
  LineChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Line,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  Legend,
} from 'recharts';
import { colors as COLORS } from 'common/config';
import { getSafeLimit } from 'common/utils';

export default function MultiStationChart(props) {
  const {
    chartData,
    settings,
    xAxisTicks,
    stationName,
    festiveDataPoints,
    pollutant,
    mean,
    tablet,
    duration,
    data,
    hideLegend,
    forecasting,
    mobile,
    iPadSize,
  } = props;

  return (
    <ResponsiveContainer
      width={mobile || iPadSize ? '118.5%' : tablet ? '100%' : '110.8%'}
      height="100%"
    >
      <LineChart
        data={chartData}
        margin={{
          right: mobile || tablet || iPadSize ? 0 : 130,
          left: tablet ? 20 : 0,
          top: 0,
          bottom: mobile ? 15 : 30,
        }}
      >
        {/* TODO: Try to use `hide` property wherever possible */}
        {settings.grid && <CartesianGrid stroke="#F4F4F4" vertical={false} />}
        {settings.mean &&
          Object.keys(data).map((slug, index) => {
            return MeanLine(mean[slug], tablet, COLORS[index]);
          })}
        {settings.annotations &&
          festiveDataPoints.map(value => {
            if (!value) return null;
            return FestiveLine(
              value.datetime,
              value.payload,
              tablet,
              value.name,
              pollutant
            );
          })}
        {settings.healthy && SafeLine(pollutant, getSafeLimit, tablet)}
        <ReferenceArea shape={<ReferenceBands pollutant={pollutant} />} />
        {PredictedReferenceArea(forecasting || [], mobile)}
        {!hideLegend && (
          <Legend
            verticalAlign="top"
            height={36}
            content={<RenderLegend stations={stationName} />}
            align="left"
            wrapperStyle={{ bottom: -24, left: 60 }}
          />
        )}

        <YAxis
          domain={[0, dataMax => (dataMax <= 1000 ? dataMax + 10 : 1000)]}
          tickLine={false}
          axisLine={false}
          tick={{
            stroke: tablet ? '#878787' : '#c8c8c8',
            strokeWidth: tablet ? 0.7 : 0.5,
            fontSize: 12,
            transform: '',
          }}
        />
        <XAxis
          tickLine={true}
          dataKey="datetime"
          tick={<CustomizedAxisTick duration={duration} />}
          ticks={xAxisTicks}
          minTickGap={0}
          interval={0}
        />
        {Object.keys(data).map((slug, index) => {
          return (
            <Line
              key={slug}
              type="monotone"
              strokeWidth={2}
              dataKey={`${slug}-${pollutant}`}
              connectNulls={settings.interpolation}
              dot={settings.points}
              stroke={COLORS[index]}
              isAnimationActive={settings.points ? false : true}
            />
          );
        })}

        {Object.keys(data).map((slug, index) => {
          return (
            <Line
              key={`forecasting-${slug}`}
              type="monotone"
              strokeDasharray="5 5"
              strokeWidth={2}
              dataKey={`forecasting-${slug}-${pollutant}`}
              connectNulls={settings.interpolation}
              dot={settings.points}
              stroke={COLORS[index]}
              isAnimationActive={settings.points ? false : true}
            />
          );
        })}

        <Tooltip
          content={
            <CustomToolTip
              duration={duration}
              pollutant={pollutant}
              multi={true}
            />
          }
          cursor={<CustomCursor />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
