import React, { useState, useEffect, useRef } from 'react';
import Downshift from 'downshift';
import groupBy from 'lodash/groupBy';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/SearchRounded';
import Fab from '@material-ui/core/Fab';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { currentLocation } from '../../utils';
import { StationWrap } from '../';

async function goToNearestStation(redirect, updateSnackbar) {
  try {
    const result = await currentLocation(message => {
      updateSnackbar(message);
      return true;
    });
    redirect(true, result);
  } catch (e) {
    redirect(false, e);
  }
}

function renderInput(inputProps) {
  const {
    updateSnackbar,
    InputProps,
    classes,
    ref,
    allStations,
    history,
    redirect,
    mobile,
    inputRef,
    ...other
  } = inputProps;

  const locationButtonClicked = () => {
    goToNearestStation(redirect, updateSnackbar);
  };

  return (
    <div style={{ display: 'flex' }} className={classes.inputContainer}>
      <TextField
        InputProps={{
          inputRef: inputRef,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          startAdornment: (
            <>
              <SearchIcon className={classes.icons} />
            </>
          ),
          endAdornment: (
            <>
              <MyLocationIcon
                className={classes.iconsRight}
                onClick={locationButtonClicked}
              />
              <span className={classes.redPinger}></span>
            </>
          ),
          disableUnderline: true,
          ...InputProps,
        }}
        {...other}
      />
    </div>
  );
}

// TODO: Rather than using `useStyles` let's do proper naming from now on.
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'none',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    overflowY: 'auto',
    maxHeight: '40vh',
  },
  iconCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputContainer: {
    backgroundColor: '#F2F2F2',
    height: '2.4rem',
    borderRadius: 8,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  changeStationArrows: {
    display: 'flex',
    margin: 'auto',
    paddingRight: '16px',
    paddingLeft: 16,
  },
  fabStyle: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 40,
    left: 'auto',
    position: 'fixed',
    zIndex: '99',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    fontSize: 16,
    backgroundColor: '#fff',
    '&:hover': {
      transition: 'all ease-in 0.3s',
      backgroundColor: '#F2F2F2',
    },
    padding: '0.6rem 0',
    paddingLeft: 16,
  },
  icons: {
    fontSize: 22,
    padding: '0 1rem',
  },
  fabDisplay: {
    display: 'none',
  },
  mobileDisplay: {
    display: 'none',
  },
  stationWrap: {
    padding: '0.3rem',
    width: '100%',
  },
  stationText: {
    fontSize: '1rem',
    color: 'white',
    margin: '0px',
    marginBottom: '-4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '95%',
  },
  stateText: {
    fontSize: '1rem',
    color: 'white',
    margin: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '95%',
    display: 'flex',
  },
  stateIcon: { margin: '0px 7px' },
  lineDivider: {
    width: '96%',
    height: '1.7px',
    background: 'white',
    margin: '12px 0px',
  },
  stateWrapper: {
    marginTop: '25px',
    marginBottom: '10px',
    '&:first-child': {
      marginTop: '0px',
    },
  },
  stationSpan: {
    fontSize: '0.8rem',
    opacity: '0.5',
  },
  redPinger: {
    position: 'absolute',
    right: 25,
    top: 8,
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: '#ff5722',
    animationName: '$blinker',
    animationDuration: '0.8s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      display: 'contents',
    },
    mobileDisplay: {
      display: 'flex',
    },
    desktopDisplay: {
      display: 'none',
    },
    fabDisplay: {
      display: 'flex',
    },
    container: {
      margin: '0 auto',
      width: '90%',
      padding: '0px',
      background: 'inherit',
      backgroundAttachment: 'fixed',
    },
    locationSelectorCard: {
      width: '100%',
      height: '100%',
      background: '#0037af',
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '11',
      overflowY: 'auto',
    },
    locationSelectorCardNoDisplay: {
      display: 'none',
    },
    inputInput: {
      padding: '15px 60px',
      color: 'white',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    changeStationArrows: {
      display: 'none',
    },
    inputContainer: {
      backgroundColor: '#ffffff40',
      borderRadius: '50px',
      height: 'auto',
    },
    icons: {
      position: 'absolute',
      fontSize: 22,
      color: 'white',
      padding: '0 2rem 0rem 1.5rem',
    },
    iconsRight: {
      position: 'absolute',
      right: 0,
      fontSize: 22,
      color: 'white',
      padding: '0 2rem 0rem 1.5rem',
    },
    stationGroup: {
      display: 'block',
      color: 'white',
      alignItems: 'center',
      marginBottom: '14px',
      width: '100%',
    },
    noStation: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',
      color: 'white',
      textAlign: 'center',
    },
    noStationIcon: {
      fontSize: '7rem',
    },
    inputSticky: {
      padding: '20px 0px',
      position: 'sticky',
      top: 0,
      background: 'inherit',
      backgroundAttachment: 'fixed',
      zIndex: '999999',
    },
  },
}));

export default function IntegrationDownshift(props) {
  const {
    allStations,
    onChange,
    station,
    mobile,
    search,
    setSearch,
    redirect,
  } = props;

  const classes = useStyles();

  const [text, setText] = useState('');
  const suggestions = allStations.map(station => ({
    ...station,
    label: `${station.name} - ${station.city} - ${station.state}`,
  }));

  const inputRef = useRef(null);

  useEffect(() => {
    if (search) {
      inputRef.current.focus();
    }
  }, [search]);

  useEffect(() => {
    setText('');
  }, [station]);

  const changeStationMobile = station => {
    onChange(station);
    setText('');
    setSearch(false);
  };

  return (
    <div className={classes.root}>
      {search && (
        <Fab
          size="medium"
          className={`${classes.fabDisplay} ${classes.fabStyle}`}
          color="primary"
          aria-label="Search location"
        >
          <CloseRoundedIcon onClick={() => setSearch(false)} />
        </Fab>
      )}
      <div
        className={`${classes.locationSelectorCard} ${
          search ? null : classes.locationSelectorCardNoDisplay
        }`}
      >
        <Downshift
          onChange={item => {
            onChange(suggestions.find(({ label }) => item === label));
          }}
          inputValue={text}
          selectedItem={text}
          onInputValueChange={inputValue => setText(inputValue)}
          onSelect={() => {
            setText('');
            setSearch(false);
          }}
        >
          {({ getInputProps, getLabelProps, inputValue }) => {
            const { onBlur, onFocus, ...inputProps } = getInputProps({
              placeholder: mobile ? 'Search Station' : 'Add Station',
            });

            const filteredSuggestion = groupBy(
              suggestions.filter(val =>
                val.label.toLowerCase().includes(inputValue.toLowerCase())
              ),
              'state'
            );

            return (
              <div className={classes.container}>
                <div className={classes.inputSticky}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    InputLabelProps: getLabelProps({ shrink: true }),
                    inputProps,
                    station,
                    mobile,
                    inputRef,
                    redirect,
                  })}
                </div>

                <div className={classes.mobileDisplay}>
                  <div className={classes.stationWrap}>
                    {Object.keys(filteredSuggestion).map(state => {
                      return (
                        <StationWrap
                          key={state}
                          stations={filteredSuggestion[state]}
                          state={state}
                          classes={classes}
                          changeStationMobile={changeStationMobile}
                        />
                      );
                    })}
                  </div>
                </div>

                {Object.keys(filteredSuggestion).length === 0 && (
                  <div
                    className={`${classes.mobileDisplay} ${classes.noStation}`}
                  >
                    <div>
                      <SentimentDissatisfiedRoundedIcon
                        className={classes.noStationIcon}
                      />
                      <p>NO STATIONS FOUND</p>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Downshift>
      </div>
    </div>
  );
}
