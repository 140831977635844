import React, { Component } from 'react';
import Axios from 'axios';
import { Auth0Context } from '../auth/auth0';
import { envOptions } from 'common/config';
import { Meta } from '.';

const { root_URL } = envOptions;

let styles = {
  display: 'flex',
  margin: '3rem auto',
  fontSize: 24,
  opacity: 0.6,
  flexDirection: 'column',
};

const auth_test_url = `${root_URL}/auth-test`;

// Use this component to learn lifecyle hooks
class Demo extends Component {
  state = {
    auth: null,
    secret: "This is the default secret, means u don't have access to see this",
  };

  static contextType = Auth0Context;

  middlewareForUpdatingData() {
    const { isAuthenticated, user } = this.context;

    console.log('Component Updated from ForceUpdate() on top');
    console.log('Is Authenticated =>>', isAuthenticated ? 'YES' : 'NO');
    console.log(user);
    if (isAuthenticated && this.state.auth === null) {
      console.log('Will update the Data now');
      this.updateData();
    }
  }

  componentDidMount() {
    console.group('componentDidMount');
    console.log(' I did Mount ');
    console.groupEnd('componentDidMount');
  }

  // this is important for first mount i.e. no update took place
  // If disabled then reaching demo the siderbar won't work and it won't authenticate
  // componentWillMount() {
  //   console.group('componentWillMount');
  //   console.count('componentWillMount');
  //   this.middlewareForUpdatingData();
  //   console.groupEnd('componentWillMount');
  // }

  componentDidUpdate() {
    console.group('componentDidUpdate');
    console.count('componentDidUpdate');
    this.middlewareForUpdatingData();
    console.groupEnd('componentDidUpdate');
  }

  // componentWillUnmount(){
  // }

  async updateData() {
    const { getIdTokenClaims } = this.context;
    if (!getIdTokenClaims) return;

    const token = await getIdTokenClaims();
    console.log('Updating the Data');
    Axios.get(auth_test_url, {
      headers: { Authorization: `Bearer ${token.__raw}` },
    }).then(result => {
      console.log('From the server', result);
      this.setState({
        auth: true,
        secret: result.data.secret,
      });
    }, console.error);
  }

  async doAPostRequest() {
    const { getIdTokenClaims } = this.context;
    if (!getIdTokenClaims) return;

    const token = await getIdTokenClaims();

    Axios.post(
      auth_test_url,
      { data: 'this is what Is send across' },
      { headers: { Authorization: `Bearer ${token.__raw}` } }
    ).then(response => {
      this.setState({
        ...this.state,
        secret: response.data.data,
      });
    }, console.error);
  }

  render() {
    const { secret, auth } = this.state;
    return (
      <div style={styles}>
        <Meta title="Demo" description="demo" />
        <p>{secret}</p>
        {auth ? (
          <button onClick={() => this.doAPostRequest()}>
            Click me to do a POST request
          </button>
        ) : null}
      </div>
    );
  }
}

export default Demo;
