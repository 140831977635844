import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import InfoDialog from './info-dialog';
import { PollutantSelectors } from './dialog-content';
import {
  pollutantOptions as POLLUTANTS,
  units as UNITS,
  pollutantFullNames as POLLUTANTSNAMES,
} from 'common/config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    minWidth: '8rem',
    paddingTop: '0.5rem',
  },
  pollutantSelector: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    color: '#323232',
  },
  menuUnit: {
    fontSize: 10,
    display: 'block',
    color: '#c8c8c8',
    position: 'relative',
    top: -20,
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
  menuTitle: {
    fontSize: 14,
    color: '#323232',
  },
  unit: {
    fontSize: 10,
    color: '#c8c8c8',
  },
  iconContainer: {
    display: 'flex',
    position: 'relative',
  },
  infoIcon: {
    position: 'relative',
    top: 4,
  },
  icons: {
    color: '#C8C8C8',
  },
  menuRoot: {
    height: '42px',
    lineHeight: 'auto',
  },
  arrowIcon: {
    fontSize: 30,
  },
  popper: {
    zIndex: 1,
    width: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      minWidth: '7rem',
    },
    arrowIcon: {
      fontSize: 25,
    },
    infoIcon: {
      top: 0,
    },
    title: {
      fontSize: 16,
    },
  },
}));

export default function PollutantSelector({
  pollutant,
  onChange,
  pollutantStatus,
  googleEvent,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const changePollutant = key => () => {
    onChange(key);
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <div ref={anchorRef} className={classes.pollutantSelector}>
        <div
          onClick={handleToggle}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <div>
            <div className={classes.title}>{POLLUTANTS[pollutant]}</div>
            <div className={classes.unit}> {UNITS[pollutant]}</div>
          </div>
          <div className={classes.iconContainer}>
            <ArrowDown className={`${classes.icons} ${classes.arrowIcon}`} />
          </div>
        </div>
        <div className={classes.infoIcon}>
          <InfoDialog
            title="Pollutant"
            googleEvent={({ action }) => {
              googleEvent({ action: action, label: 'pollutant modal' });
            }}
          >
            <PollutantSelectors pollutant={pollutant} />
          </InfoDialog>
        </div>
      </div>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {Object.keys(POLLUTANTS).map((key, index) => (
                    <MenuItem
                      key={key}
                      onClick={changePollutant(key)}
                      className={classes.menuRoot}
                    >
                      <div>
                        <p
                          className={classes.menuTitle}
                          style={{
                            opacity: pollutantStatus[index]['key'] ? 1 : 0.4,
                          }}
                        >
                          {' '}
                          {POLLUTANTSNAMES[key]}
                        </p>

                        <p className={classes.menuUnit}>{UNITS[key]} </p>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
