import React from 'react';
import { Link } from 'react-router-dom';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { pollutantOptions as POLLUTANTS } from 'common/config';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';

const POLLUTANT = Object.keys(POLLUTANTS)[0];

export default function TopPollutionDom({
  link,
  data,
  googleEvent,
  disableClickEvent,
  effect,
  status,
  classes,
  label,
  subData,
}) {
  return (
    <Link
      to={link}
      exact="true"
      onClick={() => {
        googleEvent({
          action: 'Navigate to station by clicking on card',
        });
      }}
      style={{
        textDecoration: 'none',
        pointerEvents: disableClickEvent ? 'none' : 'auto',
      }}
    >
      <Tooltip title={data.city} placement="top-end">
        <Card
          className={
            effect
              ? status === 1
                ? `${classes.card} ${classes.depthEffect}`
                : `${classes.cardOffline} ${classes.depthEffect}`
              : `${classes.card}`
          }
          elevation={0}
        >
          <Typography className={classes.header} noWrap={true}>
            {data.city}
          </Typography>
          <Typography className={classes.subHeader} noWrap={true}>
            {data.state}
          </Typography>
          <ResponsiveContainer width="100%" height="16%">
            <LineChart data={data.data} cursor="pointer">
              <Line
                connectNulls
                type="monotone"
                dot={false}
                dataKey={POLLUTANT}
                stroke={status === 1 ? '#4AAAEF' : '#a3a2a0'}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
          <CardContent className={classes.bottomCard}>
            <Grid container spacing={1}>
              <Grid item xs={4} className={classes.gridStyle}>
                <Typography
                  className={classes.text}
                  style={{
                    color: status === 1 ? label.color : '#a3a2a0',
                  }}
                >
                  {data[POLLUTANT]}
                </Typography>
                <Typography className={classes.title} color="textSecondary">
                  Now
                </Typography>
              </Grid>
              {/** SubData contains mean and exposure */}
              {Object.keys(subData).map(ele => {
                if (!subData[ele].value) return null;
                return (
                  <Grid item xs={4} className={classes.gridStyle}>
                    <Typography
                      className={classes.text}
                      style={{
                        color: status === 1 ? subData[ele].color : '#a3a2a0',
                      }}
                    >
                      {subData[ele].value}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary">
                      {ele}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Tooltip>
    </Link>
  );
}
