import { colors as COLORS, getDuration, Embeds } from 'common/config';
import { getSafeLimit } from 'common/utils';
import React from 'react';
import VizCard from './visualization-card';
import { MeanLevel } from './dialog-content';
import BarChart from './barchart';
import { SupportingChartsEvent } from '../ga-events';

const MeanData = ({ mean, pollutant, duration }) => {
  if (!mean || !pollutant || !duration) return null;
  const meanCount = Object.keys(mean).length;

  let graphData = Object.keys(mean)
    .map((slug, index) => {
      if (!mean[slug]) return null;
      return {
        val: mean[slug],
        label: mean[slug] ? mean[slug] : '',
        fill: COLORS[index],
        item: `${meanCount === 1 ? 'Actual Level' : `Station ${index + 1}`}`,
      };
    })
    .filter(element => element !== null);
  const safeLimit = getSafeLimit(pollutant);
  const safeData = {
    val: safeLimit.value,
    label: safeLimit.value,
    fill: safeLimit.color,
    item: `${meanCount < 3 ? 'Safe Level' : `Safe`}`,
  };
  graphData.unshift(safeData);
  return (
    <VizCard
      embedName={Object.keys(Embeds)[4]}
      label={`Last ${getDuration(duration).title}`}
      chart={<BarChart data={graphData} />}
      title="Mean"
      dialog={<MeanLevel />}
      dialogEvent={({ action }) =>
        SupportingChartsEvent({ action: action, label: 'mean graph' })
      }
    />
  );
};

export default MeanData;
