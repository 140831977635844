const styles = theme => ({
  root: {
    padding: '1.5rem 10rem 0 10rem',
    overflow: 'hidden',
  },
  mobilePollutantCards: {
    display: 'none',
  },
  locationSelector: {
    display: 'none',
  },
  locationSlider: {
    display: 'none',
    justifyContent: 'space-between',
    height: 'calc(7rem + 8px)',
  },
  fabStyle: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 40,
    left: 'auto',
    position: 'fixed',
    zIndex: '999',
  },
  stationArrow: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '2rem',
  },
  shareEmbed: {
    gridArea: 'share',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 20,
  },
  topSection: {
    paddingTop: '1rem',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    gridTemplateAreas:
      '"pollutant duration share controls" "chart chart chart chart"',
    '&:fullscreen': {
      backgroundColor: 'white',
      paddingLeft: 60,
      paddingRight: 150,
      paddingTop: 50,
    },
  },
  pollutant: {
    gridArea: 'pollutant',
  },
  duration: {
    gridArea: 'duration',
  },
  settings: {
    gridArea: 'controls',
    '&:fullscreen': {
      paddingBottom: '2rem',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lineChart: {
    // 200 px is the width of y axis
    width: 'auto',
    height: '56vh',
    maxHeight: '500px',
    margin: '2rem 0px',
    marginLeft: '-60px',
    gridArea: 'chart',
    '&:fullscreen': {
      height: '70vh',
      maxHeight: '70vh',
    },
  },
  settingIcon: {
    color: '#171717',
    cursor: 'pointer',
  },
  locations: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    display: 'block',
  },
  charts: {
    display: 'grid',
    gridColumnGap: '1%',
  },
  distribution: {
    margin: '2rem 0',
  },
  heatmapContainer: {
    marginTop: '2rem',
  },
  heatmap: {
    display: 'grid',
    gridTemplateColumns: '100%',
  },

  [theme.breakpoints.down('md')]: {
    root: {
      padding: '2rem 5rem 0',
    },
    lineChart: {
      width: '90vw',
    },

    settings: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  //had to manually define width in pixels because material ui predefined breakpoints are not that
  //much flexible
  //below this screen width table starting to get distort
  [theme.breakpoints.down(750)]: {
    table: {
      display: 'none',
    },
    mobilePollutantCards: {
      display: 'block',
      marginBottom: '1rem',
    },
    locationSlider: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 'calc(7rem + 8px)',
    },
    lineChart: {
      width: '100vw',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '2rem 2rem 0',
    },
    topSection: {
      gridTemplateAreas: `
        'pollutant controls'
        'chart chart'
        'duration share'`,
      gridTemplateColumns: 'auto',
    },
    duration: {
      marginBottom: '1rem',
    },
    shareEmbed: {
      marginBottom: '1rem',
    },
    lineChart: {
      width: '85vw',
      marginLeft: '-70px',
      marginTop: '1rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: '0rem 0.5rem 0',
    },
    pollutant: {
      display: 'none',
    },
    settingIcon: {
      display: 'none',
    },
    topSection: {
      marginTop: '10px',
      gridTemplateAreas: `
        'pollutant controls'
        'chart chart'
        'duration duration'
        'share share'`,
      gridTemplateColumns: '1fr 1fr',
      padding: '0px',
    },
    shareEmbed: {
      display: 'none',
    },
    charts: {
      display: 'block',
      '& > div': {
        marginTop: '2rem',
      },
    },
    table: {
      display: 'none',
    },
    mobilePollutantCards: {
      display: 'block',
    },
    locationSelector: {
      display: 'flex',
      marginBottom: '1rem',
    },
    // TODO: Better way for this,
    // rather than using hiding them we shouldn't render them at all
    heatmapContainer: {
      display: 'none',
    },
    locationSlider: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 'calc(7rem + 8px)',
    },
    lineChart: {
      width: '100vw',
      marginBottom: 0,
    },
    duration: {
      position: 'relative',
    },
  },
});
export default styles;
