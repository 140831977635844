// TODO: No need to use `index` in import path
import { Right, NewLeftHome } from '../components/home/index';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { findNearestStation } from '../utils';
import { useSelector } from 'react-redux';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Fade from 'react-reveal/Fade';
import '../assets/styles/home.sass';

// TODO: Combine all the media queries
// TODO: Reduce CSS if possible
const useStyles = makeStyles(theme => ({
  redirectScreen: {
    background: 'linear-gradient(138.75deg, #005DFB 8.35%, #047ba5 123.98%)',
    fontSize: '2rem',
    color: 'white',
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  locationWrap: {
    width: '90%',
  },
  locationIcon: {
    fontSize: '6.5rem',
  },
  locationHeading: {
    padding: '0px',
    margin: '0px',
    fontWeight: 600,
    fontSize: '1.5rem',
    marginBottom: '-1.2rem',
  },
  locationSubHeading: {
    fontSize: '1.2rem',
    opacity: '0.9',
  },
  locationSpan: {
    fontSize: '0.9rem',
    opacity: '0.7',
    display: 'flex',
    lineHeight: '140%',
    justifyContent: 'center',
  },
  right: {
    width: '65vw',
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    left: '35vw',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      left: 0,
      position: 'static',
      paddingRight: '40px',
    },
  },
  left: {
    width: '35vw',
    position: 'fixed',
    zIndex: 5,
    top: 0,
    boxShadow: ' 0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)',

    [theme.breakpoints.between('xs', 'md')]: {
      width: '100vw',
      position: 'relative',
      zIndex: 11,
      paddingBottom: 0,
      height: '100%',
      minHeight: '600px',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      position: 'relative',
      minHeight: '600px',
      height: '100%',
      boxShadow: 'none',
    },
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  footer: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    marginLeft: 0,
  },
  [theme.breakpoints.down('xs')]: {
    redirectScreen: {
      fontSize: 20,
    },
    locationSubHeading: {
      fontSize: '1rem',
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

function Redirect(result, locations, setStation, props) {
  const nearest = findNearestStation(locations, result.coords);
  // TODO: Use query string
  const path = `/chart?duration=1d&pollutant=pm25&stations=${nearest.station.slug}`;

  setStation(nearest.station.name);
  setTimeout(() => {
    props.history.push(path);
  }, 2000);
}

// TODO: Change names here
function NewHome(props) {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const locations = useSelector(state => state.locations);

  const [nearStation, setStation] = useState('...');
  return (
    <div>
      {redirect === true ? (
        <div className={classes.redirectScreen}>
          <div className={classes.locationWrap}>
            <Fade>
              <LocationOnRoundedIcon className={classes.locationIcon} />
            </Fade>
            <Fade>
              <p className={classes.locationHeading}> {nearStation} station</p>
            </Fade>
            <Fade>
              <p className={classes.locationSubHeading}>
                Nearest Station found! You are being enrouted to this station.
                <span className={classes.locationSpan}>
                  (This stations is found based on the approximate location and
                  might be wrong in some cases.)
                </span>
              </p>
            </Fade>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.left}>
            <NewLeftHome
              redirect={(goto, result) => {
                if (goto) {
                  setRedirect(true);
                  Redirect(result, locations, setStation, props);
                }
              }}
            />
          </div>
          <hr />

          <div className={classes.right}>
            <Right />
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(NewHome);
