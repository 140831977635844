import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Meta } from '.';
import UnderConstructionSVG from '../assets/images/under-construction.svg';

// TODO: Talk to Abhi and Kaustubh to change this
const styles = theme => ({
  container: {
    minHeight: 'calc(100vh  - 100px)',
    textAlign: 'center',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 1rem',
  },
  heading: {
    fontWeight: 400,
    fontSize: '2.5rem',
    color: theme.palette.text.primary,
  },
  bodyText: {
    width: '90%',
    fontWeight: 400,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    opacity: '0.7',
    margin: '0.5rem auto',
  },
  icon: {
    height: 300,
    margin: '1rem auto',
  },
  [theme.breakpoints.down('sm')]: {
    heading: {
      fontSize: '1.8rem',
    },
    icon: {
      height: 180,
      margin: '0.3rem auto',
    },
  },
});

const ComingSoon = ({ classes }) => {
  return (
    <div className={classes.container}>
      <Meta
        title="Coming Soon"
        description="Awesome visualizations under construction"
      />
      <h1 className={classes.heading}>
        Awesome visualizations under construction
      </h1>
      <img
        className={classes.icon}
        src={UnderConstructionSVG}
        alt="under construction"
      />
      <p className={classes.bodyText}>
        Please forgive the inconvenience and check out the rest of the App.
      </p>
      <p className={classes.bodyText}>It's okay, we're excited too!</p>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ComingSoon);
