import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { pollutantOptions as POLLUTANTS } from 'common/config';
import { getLevel, calculateExposure, calculateMean } from 'common/utils';
import Tooltip from '@material-ui/core/Tooltip';
import { getLastKnownValue, getOnlineStatus } from '../../utils/chart-utils';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '8px',
    padding: '1rem',
    width: '15.5rem',
    boxShadow: isOnline =>
      isOnline ? '2px 3px 12px rgba(23, 23, 23, 0.1)' : '',
    background: isOnline => (!isOnline ? 'rgba(200, 200, 200, 0.24)' : ''),
    opacity: isOnline => (!isOnline ? '0.7' : ''),
    border: isOnline => (!isOnline ? '1px solid #C8C8C8' : ''),
  },
  rootOffline: {
    boxShadow: 'unset',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    color: '#c8c8c8',
    fontSize: 20,
  },
  stats: {
    paddingTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  value: {
    fontSize: 24,
  },
  label: {
    fontSize: 10,
    color: '#c8c8c8',
  },
  [theme.breakpoints.down(370)]: {
    root: {
      width: '12rem',
    },
  },
}));

export default function LocationCard({ station, pollutant, data }) {
  const { name } = station;
  const current = getLastKnownValue(data, pollutant);
  const mean = calculateMean(data, pollutant);
  const { color: meanColor } = getLevel(mean, pollutant);
  const { value: exValue, color: exColor } = calculateExposure(data, pollutant);

  const lastKnown = getLastKnownValue(data, pollutant);

  const online = lastKnown ? getOnlineStatus(lastKnown.datetime) : false;

  const classes = useStyles(online);

  return (
    <Tooltip title={name} placement="top-end">
      <Fade cascade>
        <div className={classes.root}>
          <div className={classes.heading}>
            <span
              style={{
                width: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                color: online ? 'black' : '#c8c8c8',
              }}
            >
              {name}
            </span>
          </div>
          <div className={classes.stats}>
            <div>
              <div
                style={{
                  color:
                    online && current
                      ? getLevel(current[pollutant], pollutant).color
                      : '#c8c8c8',
                }}
                className={classes.value}
              >
                {current ? current[pollutant] : '-'}
              </div>
              <div className={classes.label}>{POLLUTANTS[pollutant]} Now</div>
            </div>
            <div>
              <div
                style={{ color: online ? meanColor : '#c8c8c8' }}
                className={classes.value}
              >
                {mean || '-'}
              </div>
              <div className={classes.label}>{POLLUTANTS[pollutant]} Mean</div>
            </div>
            <div>
              <div
                style={{ color: online ? exColor : '#c8c8c8' }}
                className={classes.value}
              >
                {exValue ? `${exValue}X` : '-'}
              </div>
              <div className={classes.label}>Exposure</div>
            </div>
          </div>
        </div>
      </Fade>
    </Tooltip>
  );
}
