import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Code from '@material-ui/icons/Code';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/MenuRounded';
import {
  ShareSocial,
  /**TranslateMenu,**/ FavoriteControl,
} from '../components';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { toggleSidebar } from 'common/store/actions';
import { ReactComponent as Breezo } from '../assets/icons/breezo-blue.svg';
import { SideBarEvent } from '../components/ga-events';
import { URLS } from 'common/config';

// TODO: Combine media queries
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
    padding: '0 calc(10rem - 16px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '4rem',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    textDecoration: 'none',
    fontSize: '1.5rem',
    color: '#140CF2',
    paddingTop: 6,
    paddingLeft: 50,
  },
  [theme.breakpoints.down('md')]: {
    root: {
      padding: '0rem calc(5rem - 16px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '0 calc(2rem - 16px)',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: '0 calc(0.5rem - 16px)',
    },
  },
}));

const ControlsDiv = ({ isStation }) => {
  return (
    <div>
      <Tooltip title="Get API Access" placement="left">
        <a
          rel="noopener noreferrer"
          href={URLS['breezo-about']}
          target="_blank"
        >
          <IconButton style={{ color: '#c1bfbf' }}>
            <Code />
          </IconButton>
        </a>
      </Tooltip>
      <ShareSocial />
      {isStation && <FavoriteControl />}
    </div>
  );
};

const Header = props => {
  const isStation = props.location.pathname.includes('chart');
  const classes = useStyles();
  const { toggleSidebar } = props;

  return (
    <div className={classes.root}>
      <div className={classes.headerLeft}>
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            toggleSidebar();
            SideBarEvent({ action: 'opened sidebar' });
          }}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Link to="/" className={classes.title}>
        <Breezo style={{ width: '100px', height: '50px' }} />
      </Link>
      <ControlsDiv isStation={isStation} />
    </div>
  );
};

export default compose(connect(null, { toggleSidebar }), withRouter)(Header);
