import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';

const styles = theme => ({
  root: {
    width: 'calc(100% - 1rem)',
    margin: '1rem 0.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      width: '100%',
      margin: '1rem 0',
    },
  },
});

const StateLoader = ({ classes }) =>
  Array.apply(null, { length: 4 }).map((e, i) => (
    <div className={classes.root} key={i}>
      {/* TODO: FIx this */}
      {false ? (
        <ContentLoader
          height={300}
          width={560}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="46" y="22" rx="0" ry="0" width="360" height="30" />

          <rect x="46" y="75" rx="0" ry="0" width="460" height="2" />

          <rect x="46" y="105" rx="0" ry="0" width="400" height="20" />
          <rect x="46" y="145" rx="0" ry="0" width="360" height="20" />
          <rect x="46" y="185" rx="0" ry="0" width="300" height="20" />
          <rect x="46" y="225" rx="0" ry="0" width="300" height="20" />
        </ContentLoader>
      ) : (
        <ContentLoader
          height={110}
          width={820}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="18" y="22" rx="0" ry="0" width="180" height="18" />

          <rect x="18" y="50" rx="0" ry="0" width="780" height="2" />

          <rect x="18" y="65" rx="0" ry="0" width="225" height="12" />
          <rect x="288" y="65" rx="0" ry="0" width="225" height="12" />
          <rect x="552" y="65" rx="0" ry="0" width="225" height="12" />
          <rect x="18" y="90" rx="0" ry="0" width="225" height="12" />
          <rect x="288" y="90" rx="0" ry="0" width="225" height="12" />
          <rect x="552" y="90" rx="0" ry="0" width="225" height="12" />
        </ContentLoader>
      )}
    </div>
  ));

export default withStyles(styles, { withTheme: true })(StateLoader);
