import React from 'react';
import { getRangeWithLabel as POLLUTANT_RANGE } from 'common/config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: '100%',
    fontSize: 10,
    padding: 5,
    color: 'white',
  },
  text: {
    fontSize: '11px',
    fontWeight: 'bold',
  },
  [theme.breakpoints.down(404)]: {
    text: {
      fontSize: '10px',
    },
  },
  [theme.breakpoints.down(371)]: {
    text: {
      fontSize: '9px',
    },
  },
  [theme.breakpoints.down(340)]: {
    text: {
      fontSize: '8px',
    },
  },
}));

export default function PollutantRanges({ pollutant }) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {POLLUTANT_RANGE.map((value, index) => {
        return (
          <div
            key={index}
            className={classes.root}
            style={{
              backgroundColor: value.color,
            }}
          >
            <div className={classes.text}>{value.label}</div>
          </div>
        );
      })}
    </div>
  );
}
