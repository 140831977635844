import ReactGA from 'react-ga';

export default function (eventData) {
  const {
    category = 'BreeZo table',
    label = 'Table in chart page',
  } = eventData;
  ReactGA.event({
    category: category,
    label: label,
    ...eventData,
  });
}
