import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  LabelList,
  YAxis,
  Cell,
  XAxis,
} from 'recharts';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
  },
});

const renderCustomizedLabel = props => {
  const { x, y, width, value } = props;
  const radius = 10;
  return (
    <text
      x={x + width / 2}
      y={y - radius}
      fill="#323232"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontSize: 20,
        fontWeight: 400,
      }}
    >
      {value}
    </text>
  );
};

export default function Chart({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const mediumSize = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ResponsiveContainer className={classes.root} width="90%" height="77%">
      <BarChart maxBarSize="800" barCategoryGap="5%" data={data}>
        <YAxis hide={true} dataKey="val" domain={[0, dataMax => dataMax * 2]} />
        <XAxis
          interval={0}
          tick={
            mobile
              ? { fontSize: 15 }
              : mediumSize
              ? { fontSize: 8 }
              : { fontSize: 11 }
          }
          minTickGap={0}
          dataKey="item"
        />
        <Bar dataKey="val" isAnimationActive={false}>
          <LabelList dataKey="label" content={renderCustomizedLabel} />
          {data.map(entry => (
            <Cell key={entry.label} fill={entry.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
