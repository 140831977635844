import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { debounce, isEqual, differenceWith, omit } from 'lodash';
import { Auth0Context } from 'auth/auth0';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { getMeasurements as getData } from 'common/api/measurements';
import { getTemporalPrediction } from 'common/api/get-temporal-prections';
import styles from '../styles/chart-styles';
import { withRouter } from 'react-router';
import calculateAQI from 'aqi-calculator';
import { Header, Footer } from '../layout';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import DialogWrapper from '../components/dialog-wrapper';
import { PollutantSelectors as DialogContent } from '../components/chart/dialog-content';
import Fade from 'react-reveal/Fade';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Fab from '@material-ui/core/Fab';
import {
  durations as DURATIONS,
  pollutantOptions as POLLUTANTS,
  getDuration,
  Embeds,
} from 'common/config';
import Paper from '@material-ui/core/Paper';
import { updateSnackbar } from 'common/store/actions';
import {
  Calendar,
  Distribution,
  DurationSelector,
  ChartComponent,
  LocationCard,
  LocationSelector,
  PollutantSelector,
  Radar,
  Settings,
  Table,
  MobilePollutantCards,
  MeanData,
  Exposure,
  ScrollColorScale,
} from '../components/chart';
import { Loader } from '../components/loaders';
import {
  getPollutant,
  exposureAndMean,
  getPollutantStatus,
  getNewStation,
  shouldDurationChange,
  getEarliestIndexBySlug,
  getEarliestIndexByData,
  isDataAvailable,
  calculateAllDataSize,
} from '../utils/chart-utils';
import withWidth from '@material-ui/core/withWidth';
import ShareEmbed from '../components/embed/share-embed';
import MenuFab from '../components/chart/mobile-menu-fab';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {
  chartTourConfigDesktop,
  chartTourConfigMobile,
} from '../utils/tour-config';
import { TimeSeriesEvent, TourEvent } from '../components/ga-events';

const AQI_DURATION = DURATIONS[0].value;
const MENU_OPEN = ".MuiSpeedDial-fab[aria-expanded='false']";
const MENU_CLOSE = ".MuiSpeedDial-fab[aria-expanded='true']";

const stationsType = {
  decode: encoded => (encoded ? encoded.split('+') : undefined),
  encode: decoded => (decoded ? decoded.join('+') : undefined),
};

const urlPropsQueryConfig = {
  stations: { type: stationsType },
  duration: { type: UrlQueryParamTypes.string, defaultValue: '1d' },
  pollutant: { type: UrlQueryParamTypes.string, defaultValue: 'pm25' },
};

class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      settings: {
        points: true,
        healthy: true,
        interpolation: true,
        grid: true,
        annotations: true,
        mean: false,
      },
      isTourOpen: false,
      tourStep: 0,
      changeStationLoader: false,
      search: false,
      settingState: false,
      pollutantInfo: false,
    };
    this.onClickChangeStation = debounce(this.onClickChangeStation, 600);
    this._arrowButtonHandle = this.arrowButtonHandle.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.setSetting = this.setSetting.bind(this);
    this.setPollutantInfo = this.setPollutantInfo.bind(this);
    this.openTour = this.openTour.bind(this);
    document.addEventListener('keydown', this._arrowButtonHandle, false);
  }

  disableBody = target => {
    disableBodyScroll(target);
    TourEvent({ action: 'start tour' });
  };

  enableBody = target => {
    TourEvent({
      action: `close tour, Step: ${this.state.tourStep}`,
    });
    enableBodyScroll(target);
  };

  closeTour = e => {
    document.querySelectorAll(MENU_CLOSE)[0] &&
      document.querySelectorAll(MENU_CLOSE)[0].click();
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true, tourStep: 0 });
  };

  //GA Event- Tour closed when cross button is clicked
  tourCloseByCross = () => {
    this.state.isTourOpen &&
      document.getElementsByClassName('sc-AxhCb')[0] &&
      TourEvent({
        action: 'tour closed by clicking cross button.',
      });
  };

  arrowButtonHandle(event) {
    if (Object.keys(this.state.data).length > 1) return null;

    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        this.onClickChangeStation('previous');

        break;
      case 'ArrowRight':
        event.preventDefault();
        this.onClickChangeStation('next');
        break;
      default:
    }
  }

  setSearch(state) {
    this.setState({
      search: state,
    });
  }

  setPollutantInfo(state) {
    this.setState({
      pollutantInfo: state,
    });
  }

  setSetting(state) {
    this.setState({
      settingState: state,
    });
  }

  componentDidMount() {
    const {
      stations,
      duration,
      onChangeStations,
      width,
      updateSnackbar,
    } = this.props;
    if (stations || stations !== undefined) {
      //used withWidth for getting screen size though it's deprecated , can't use
      //theme.breakpoints  here.
      //Another option is to use window object
      if (width === 'xs' && stations.length > 1) {
        onChangeStations([stations[0]]);
        updateSnackbar('Showing only one station in mobile');
        this.updateData(stations[0], duration, []);
      } else {
        stations.forEach(slug => {
          this.updateData(slug, duration);
        });
      }
    }

    window.addEventListener('beforeunload', this.beforeUnloadTour);
    document.addEventListener('mousedown', this.handleMouseDown);
  }

  beforeUnloadTour = () => {
    if (this.state.isTourOpen)
      TourEvent({
        action: 'tour closed by closing window',
      });
  };

  handleMouseDown = e => {
    //get id name by consoling in developer console
    if (e.target.nodeName === 'rect' && e.path[3].id === '___reactour') {
      TourEvent({
        action: 'close tour by clicking the Mask',
      });
    }
  };

  static contextType = Auth0Context;

  componentDidUpdate(prevProps) {
    const { stations, duration } = this.props;
    const { data } = this.state;

    if (!data) return;

    //run when only duration changes
    if (prevProps.duration !== duration) {
      stations.forEach(slug => {
        this.updateData(slug, duration);
      });
      return;
    }

    //for station change
    if (!isEqual(stations, prevProps.stations)) {
      const slug = differenceWith(stations, prevProps.stations)[0];

      this.updateData(slug, duration, prevProps.stations);
    }
  }

  static getDerivedStateFromProps(props) {
    const { stations, history, updateSnackbar } = props;
    // TODO: Check if the location exist in the locations array
    if (!stations) {
      updateSnackbar('No station is selected');
      history.push('/');
    }
    return null;
  }

  async getPredictions(slug) {
    const { updateSnackbar } = this.props;

    try {
      const { data: response } = await getTemporalPrediction(slug);
      if (this.state.data[slug])
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            [slug]: {
              ...prevState.data[slug],
              predictions: response.data,
            },
          },
        }));
    } catch (err) {
      updateSnackbar('Oops!! Something went wrong while getting forecasting');
      console.log(err);
    }
    this.setState({ changeStationLoader: false });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._arrowButtonHandle);
    document.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('beforeunload', this.beforeUnloadTour);
  }

  async setAQI(slug) {
    const { updateSnackbar } = this.props;
    try {
      const { data: response } = await getData(slug, AQI_DURATION);
      const aqi = calculateAQI(response.data);
      //Set AQI only if slug already present in state
      if (this.state.data[slug])
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            [slug]: {
              ...prevState.data[slug],
              aqi: aqi,
            },
          },
        }));
    } catch (err) {
      updateSnackbar('Oops!! Something went wrong while getting aqi');
    }
    this.setState({ changeStationLoader: false });
  }

  async updateData(slug, duration, prevPropStations) {
    const {
      allStations,
      stations,
      onChangeDuration,
      updateSnackbar,
    } = this.props;

    if (!slug) return null;

    const earliestIndex = getEarliestIndexBySlug(slug, allStations);

    //Change duration to earliest if selected is greater than earliest

    if (shouldDurationChange(duration, earliestIndex)) {
      onChangeDuration(DURATIONS[earliestIndex].value);
      return;
    }

    const { isAuthenticated } = this.context;
    const changeStation = stations.length > 1 ? false : true;

    this.setState(
      {
        changeStationLoader: true,
      },
      async () => {
        try {
          const { data: response } = await getData(slug, duration);

          const stationMeta = allStations.find(
            ({ slug: stationSlug }) => stationSlug === slug
          );

          this.setState(
            {
              data: changeStation
                ? {
                    [slug]: {
                      data: response.data,
                      meta: stationMeta,
                      aqi: this.state.data[slug]
                        ? this.state.data[slug].aqi
                        : null,
                      predictions: this.state.data[slug]
                        ? this.state.data[slug].predictions
                        : null,
                    },
                  }
                : {
                    ...this.state.data,
                    [slug]: {
                      data: response.data,
                      meta: stationMeta,
                      aqi: this.state.data[slug]
                        ? this.state.data[slug].aqi
                        : null,
                      predictions: this.state.data[slug]
                        ? this.state.data[slug].predictions
                        : null,
                    },
                  },
            },
            () => {
              //Pollutant gets changed if current pollutant don't have enough data
              this.changePollutant();
              if (
                !this.state.data[slug].aqi &&
                !this.state.data[slug].predictions
              ) {
                this.setAQI(slug);
                if (isAuthenticated) this.getPredictions(slug);
              } else {
                this.setState({ changeStationLoader: false });
              }
            }
          );
        } catch (err) {
          this.setState({ changeStationLoader: false });
          updateSnackbar('Oops!! Something went wrong');
          // onChangeStations(prevPropStations);
        }
      }
    );
  }

  changePollutant() {
    const { data } = this.state;
    const { onChangePollutant, updateSnackbar, pollutant } = this.props;

    if (isDataAvailable(data, pollutant)) return;

    const newPollutant = getPollutant(data);
    if (newPollutant) {
      updateSnackbar(
        `Pollutant change to ${POLLUTANTS[newPollutant]} as ${POLLUTANTS[pollutant]} don't have enough data`
      );
      onChangePollutant(newPollutant);
    }
  }

  onClickChangeStation(direction) {
    const { allStations, stations } = this.props;

    const station = getNewStation(allStations, stations, direction);
    this.addStation(station, true);
  }

  addStation = (station, changeStation = false) => {
    if (!station) return null;

    const { onChangeStations, stations } = this.props;
    if (stations.includes(station.slug)) return null;

    const stationsArray = changeStation
      ? [station.slug]
      : [...stations, station.slug];
    onChangeStations(stationsArray);
  };

  deleteStation = slug => {
    if (!slug) return null;
    const { onChangeStations, stations } = this.props;

    const index = stations.indexOf(slug);
    if (index === -1) return null;
    this.setState({
      data: omit(this.state.data, slug),
    });
    stations.splice(index, 1);
    onChangeStations(stations);
  };

  render() {
    const accentColor = '#1a61fb';

    const {
      classes,
      allStations,
      onChangeDuration,
      duration,
      pollutant,
      onChangePollutant,
      width,
      stations: selectedStations,
    } = this.props;
    const {
      data,
      changeStationLoader,
      search,
      settingState,
      pollutantInfo,
      isTourOpen,
      tourStep,
    } = this.state;
    const mobile = Boolean(width === 'xs');

    // FIXME: Temporary fix for when no station is selected
    if (!selectedStations || !data || Object.keys(data).length < 1)
      return <Loader />;

    const earliestIndex = getEarliestIndexByData(data, allStations);
    const maxAvailableEarliest = getEarliestIndexByData(
      data,
      allStations,
      true
    );
    //Index of year duration in DURATIONS year
    const yearDurationIndex = DURATIONS.findIndex(
      ({ value }) => value === '1y'
    );
    const dataSize = calculateAllDataSize(data, pollutant);

    const { exposure, mean } = exposureAndMean(data, pollutant);

    const pollutantStatus = getPollutantStatus(data);

    //get cross classname from inspect element and making a click event call for GA event
    isTourOpen &&
      document.getElementsByClassName('sc-AxhCb')[0] &&
      document
        .getElementsByClassName('sc-AxhCb')[0]
        .addEventListener('click', this.tourCloseByCross);

    return (
      <>
        <div>
          {!mobile && <Header />}
          {changeStationLoader && <Loader />}{' '}
          {changeStationLoader && <Loader />}
          {mobile && <ScrollColorScale pollutant={pollutant} />}
          <div
            style={
              changeStationLoader
                ? { pointerEvents: 'none', opacity: 0.4 }
                : { opacity: 1 }
            }
          >
            <div className={classes.root}>
              <Paper className={classes.locationSelector}>
                <LocationSelector
                  onChange={slug => this.addStation(slug, mobile)}
                  allStations={allStations}
                  mobile={mobile}
                  station={data[Object.keys(data)[0]].meta}
                  search={search}
                  setSearch={this.setSearch}
                />
              </Paper>
              <div className={classes.locationSlider}>
                <div className={classes.stationArrow}>
                  <Tooltip title="Go to previous station" placement="top-end">
                    <ArrowLeft
                      style={{ fontSize: '2rem' }}
                      cursor="pointer"
                      onClick={() => this.onClickChangeStation('previous')}
                    />
                  </Tooltip>
                </div>

                <div
                  className={`${classes.locations} `}
                  data-tut="tour_next_prev_mobile"
                >
                  <LocationCard
                    className={classes.stationCard}
                    station={data[Object.keys(data)[0]].meta}
                    pollutant={pollutant}
                    data={data[Object.keys(data)[0]].data}
                  />
                </div>
                <div className={classes.stationArrow}>
                  <Tooltip title="Go to next station" placement="top-end">
                    <ArrowRight
                      cursor="pointer"
                      style={{ fontSize: '2rem' }}
                      onClick={() => this.onClickChangeStation('next')}
                    />
                  </Tooltip>
                </div>
              </div>

              <div className={classes.table} data-tut="tour_search_desktop">
                <Table
                  goToNextStation={() => this.onClickChangeStation('next')}
                  goToPrevStation={() => this.onClickChangeStation('previous')}
                  addStation={this.addStation}
                  deleteStation={this.deleteStation}
                  allStations={allStations}
                  data={data}
                  pollutant={pollutant}
                  changePollutant={onChangePollutant}
                  exposure={exposure}
                  mean={mean}
                  search={search}
                  setSearch={this.setSearch}
                />
              </div>

              <div className={classes.topSection} id="chart">
                <div
                  className={classes.pollutant}
                  data-tut="tour_pollutant_desktop"
                >
                  <PollutantSelector
                    pollutantStatus={pollutantStatus}
                    pollutant={pollutant || 'pm25'}
                    googleEvent={TimeSeriesEvent}
                    onChange={val => {
                      onChangePollutant(val);
                      TimeSeriesEvent({
                        action: 'changed pollutant',
                        label: 'desktop',
                      });
                    }}
                  />
                </div>

                <Fade cascade>
                  <div className={classes.duration} data-tut="tour_duration">
                    <DurationSelector
                      duration={duration || '1d'}
                      earliestIndex={earliestIndex}
                      onChange={(e, val) => {
                        onChangeDuration(val);
                        TimeSeriesEvent({
                          action: 'changed duration',
                          label: 'duration',
                        });
                      }}
                    />
                  </div>
                </Fade>

                <Fade cascade>
                  <div className={classes.shareEmbed}>
                    <ShareEmbed
                      iconStyle={{ width: 15 }}
                      embedName={Object.keys(Embeds)[1]}
                    />
                  </div>
                </Fade>

                <Fade cascade>
                  <div className={classes.settings}>
                    <SettingsIcon
                      className={classes.settingIcon}
                      onClick={() => {
                        this.setSetting(true);
                        TimeSeriesEvent({
                          action: 'clicked on settings',
                          label: 'settings',
                        });
                      }}
                    />
                    <Settings
                      settingState={settingState}
                      setSetting={this.setSetting}
                      onChange={settingObj => {
                        this.setState({ settings: settingObj });
                      }}
                    />
                  </div>
                </Fade>

                <Fade cascade>
                  <div className={classes.lineChart}>
                    <ChartComponent
                      pollutant={pollutant}
                      data={data}
                      mean={mean}
                      duration={duration}
                      settings={this.state.settings}
                    />
                  </div>
                </Fade>
              </div>

              <div
                className={classes.mobilePollutantCards}
                data-tut="tour_pollutant_mobile"
              >
                <MobilePollutantCards
                  data={data[Object.keys(data)[0]]}
                  changePollutant={onChangePollutant}
                  pollutant={pollutant}
                  googleEvent={TimeSeriesEvent}
                />
              </div>
              {Boolean(dataSize) && (
                <>
                  <div
                    className={classes.charts}
                    style={
                      Boolean(
                        Object.keys(data).length === 1 &&
                          maxAvailableEarliest >= yearDurationIndex
                      )
                        ? { gridTemplateColumns: '3fr 3fr 3fr 3fr' }
                        : { gridTemplateColumns: '2fr 3fr 3fr' }
                    }
                  >
                    {Boolean(
                      maxAvailableEarliest >= yearDurationIndex ||
                        Object.keys(data).length > 1
                    ) && (
                      <Radar
                        earliestDuration={DURATIONS[earliestIndex].value}
                        pollutant={pollutant}
                        selectedStations={selectedStations}
                      />
                    )}
                    {Object.keys(data).length === 1 &&
                      Object.keys(data).map(slug => {
                        return (
                          <Distribution
                            key={slug}
                            pollutant={pollutant}
                            label={`Last ${getDuration(duration).title}`}
                            data={data[slug].data}
                          />
                        );
                      })}

                    <Exposure exposure={exposure} duration={duration} />

                    <MeanData
                      pollutant={pollutant}
                      duration={duration}
                      mean={mean}
                    />
                  </div>
                </>
              )}
              {!mobile && (
                <div className={classes.heatmapContainer}>
                  <Calendar
                    earliestDuration={DURATIONS[earliestIndex].value}
                    data={data}
                    pollutant={pollutant}
                    selectedStations={selectedStations}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: '2rem' }}>
            <Footer />
          </div>
          <DialogWrapper
            open={pollutantInfo}
            onClose={() => this.setPollutantInfo(false)}
            title={'Pollutant'}
          >
            <DialogContent pollutant={pollutant} />
          </DialogWrapper>
          {mobile && (
            <MenuFab
              station={selectedStations[0]}
              setSearch={this.setSearch}
              setSetting={this.setSetting}
              openTour={this.openTour}
            />
          )}
        </div>
        {!mobile && (
          <Tooltip title="Explore Tour" placement="left">
            <Fab
              size="large"
              className={`${classes.fabDisplay} ${classes.fabStyle}`}
              color="primary"
              aria-label="Search location"
              onClick={() => {
                this.openTour();
                TourEvent({
                  action: 'start tour from fab',
                });
              }}
            >
              <InfoRoundedIcon />
            </Fab>
          </Tooltip>
        )}
        <Tour
          onRequestClose={this.closeTour}
          steps={mobile ? chartTourConfigMobile : chartTourConfigDesktop}
          isOpen={isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={accentColor}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          closeWithMask={true}
          lastStepNextButton={
            <b
              onClick={() => TourEvent({ action: 'finished tour' })}
              style={{ color: '#3363F2' }}
            >
              Let me play...
            </b>
          }
          startAt={0}
          getCurrentStep={curr => {
            mobile && curr >= 3
              ? document.querySelectorAll(MENU_OPEN)[0] &&
                document.querySelectorAll(MENU_OPEN)[0].click()
              : document.querySelectorAll(MENU_CLOSE)[0] &&
                document.querySelectorAll(MENU_CLOSE)[0].click();
            if (tourStep !== curr + 1) this.setState({ tourStep: curr + 1 });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ locations }) => ({
  allStations: locations,
});

export default compose(
  addUrlProps({ urlPropsQueryConfig }),
  connect(mapStateToProps, {
    updateSnackbar,
  }),
  withStyles(styles),
  withTranslation(),
  withWidth(),
  withRouter
)(Chart);
