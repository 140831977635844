import React from 'react';
import EmbedHoc from '../hoc/search-bar-hoc';
import Radar from '../chart/radar-chart';
import Dropdown from '../chart/year-selector';
import { makeStyles } from '@material-ui/core/styles';
import { PollutantSelector } from '../chart';
import { Loader } from '../loaders';
import StationList from './station-list';
import { generatePollutantStatus, getStationMeta } from './embed-utils';
import { YEARS } from 'common/config';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    opacity: loader => (loader ? 0.3 : 1),
    pointerEvents: loader => (loader ? 'none' : ''),
  },
  controllers: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  stations: {
    marginTop: '12px',
    width: '70%',
  },
  embed: {
    display: 'grid',
    gridColumnGap: '15%',
    gridTemplateColumns: '3fr 3fr',
  },
  [theme.breakpoints.down('md')]: {
    embed: {
      display: 'grid',
      gridColumnGap: '5%',
      gridTemplateColumns: '3fr 3fr',
    },
  },
  [theme.breakpoints.down('sm')]: {
    embed: {
      display: 'block',
      height: '100%',
      '& > div': {
        margin: '12px 0',
      },
    },
    stations: {
      marginTop: '12px',
      width: '100%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      marginBottom: 220,
    },
  },
}));

const Embed = props => {
  const {
    pollutant,
    allStations,
    selectedStations,
    deleteStation,
    onChangePollutant,
  } = props;

  const [year, changeYear] = React.useState(YEARS[0]);
  const [loader, setLoader] = React.useState(true);

  const classes = useStyles(loader);

  const data = getStationMeta(selectedStations, allStations);

  const years = YEARS.map(key => ({ label: key, key }));

  const stations = Object.keys(data).map(slug => {
    return data[slug].meta;
  });

  const pollutantStatus = generatePollutantStatus();
  return (
    <div className={classes.root}>
      {loader && <Loader />}
      <div className={classes.controllers}>
        <div className={classes.pollutantSelector}>
          <PollutantSelector
            pollutant={pollutant || 'pm25'}
            onChange={val => onChangePollutant(val)}
            pollutantStatus={pollutantStatus}
          />
        </div>
        <div className={classes.durationSelector}>
          <Dropdown selected={year} options={years} onChange={changeYear} />
        </div>
      </div>
      <div className={classes.embed}>
        <div style={{ width: '100%', height: '50vh' }}>
          <Radar
            year={year}
            stations={stations || []}
            pollutant={pollutant}
            loaderStatus={status => setLoader(status)}
            changeYear={changeYear}
          />
        </div>
        <div className={classes.stations}>
          <p style={{ fontSize: 14 }}>Stations</p>
          {stations && (
            <StationList
              stations={stations}
              selectedStations={selectedStations}
              deleteStation={deleteStation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmbedHoc(Embed, 'Radar graph');
