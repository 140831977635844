import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  iconStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

// TODO: Just combine them into one
function SimpleDialog(props) {
  const { onClose, open, title } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open} overlaySt>
      <MuiDialogTitle>
        <Typography className={classes.iconStyle} variant="h6">
          {title}{' '}
          <CloseIcon
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          ></CloseIcon>
        </Typography>
      </MuiDialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}

export default function InfoDialog(props) {
  const { children, googleEvent } = props;
  const [open, setOpen] = React.useState(false);
  const { title, size } = props;
  return (
    <div>
      <InfoIcon
        cursor="pointer"
        style={{ color: '#c8c8c8', fontSize: size }}
        onClick={() => {
          setOpen(true);
          if (googleEvent) googleEvent({ action: 'clicked on info modal' });
        }}
      />

      <SimpleDialog
        open={open}
        onClose={() => setOpen(false)}
        title={title}
        icon={<InfoIcon />}
      >
        {children}
      </SimpleDialog>
    </div>
  );
}
