import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ResponsiveCalendar } from '@nivo/calendar';
import dayjs from 'dayjs';
import { getMeasurements as getData } from 'common/api/measurements';
import {
  pollutantLabelColorReference as COLORS,
  getCommonYearSelector,
} from 'common/config';
import { calculateMean, calculateExposure, getLevel } from 'common/utils';
import { isEqual, differenceWith } from 'lodash';
import usePrevious from '../custom-hooks/previous-hook';
import NoData from '../../assets/images/no-data.svg';
import { durations as DURATIONS } from 'common/config';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateSnackbar } from 'common/store/actions';
import Delete from '@material-ui/icons/DeleteOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';
import { Auth0Context } from '../../auth/auth0';

const colors = COLORS.map(({ color }) => color);

const useStyles = makeStyles(theme => ({
  chart: {
    margin: 'auto',
    height: '10rem',
    width: '90%',
  },
  header: {
    fontSize: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  exposure: {
    marginRight: '1rem',
  },
  noData: {
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '10rem',
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      display: 'block',
      '& > div': {
        marginTop: '6px',
      },
    },
    chart: {
      margin: 'auto',
      height: '50rem',
      width: '90%',
    },
    delete: {
      float: 'right',
    },
    noData: {
      width: 190,
      margin: '0 20px',
      height: '100%',
      position: 'relative',
      left: -20,
      top: 10,
    },
  },
}));

const DURATION = '1y';

const Heatmap = React.memo(
  ({
    stations,
    pollutant,
    year,
    updateSnackbar,
    isEmbed,
    deleteStation,
    multi,
    loaderStatus,
    stationsList,
    changeYear,
  }) => {
    const { isAuthenticated } = useContext(Auth0Context);
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const startDate = new Date(year, 1);
    const endDate = new Date(year, 11, 31);
    const prevStations = usePrevious(stations);
    const prevAuthState = usePrevious(isAuthenticated);
    const prevStationsList = usePrevious(stationsList);
    const earliestIndex = DURATIONS.findIndex(
      ({ value }) => value === stations.earliest
    );

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
      const added = differenceWith(stationsList, prevStationsList);
      const deleted = differenceWith(prevStationsList, stationsList);

      let tempDuration = DURATION;
      if (
        (!isEqual(prevStations, stations) ||
          isAuthenticated !== prevAuthState) &&
        earliestIndex > 5
      ) {
        setLoader(true);
        try {
          (async () => {
            const config = {};
            if (isAuthenticated) {
              if (stations.earliest === 'fe') {
                tempDuration = 'fe';
              } else if (stations.earliest === '3y') {
                tempDuration = '3y';
              }

              config.headers = {
                device: 'authenticated',
              };
            }

            const response = await getData(stations.slug, tempDuration, config);
            setData(response.data.data);
            setLoader(false);
          })();
        } catch (e) {
          updateSnackbar('Oops!!! error while getting calender data');
          setLoader(false);
        }
      }

      if (added.length > 0)
        changeYear(getCommonYearSelector(stationsList, year));

      if (deleted.length > 0)
        changeYear(getCommonYearSelector(stationsList, year));
    }, [
      prevStations,
      stations,
      earliestIndex,
      updateSnackbar,
      isAuthenticated,
      prevAuthState,
      stationsList,
      prevStationsList,
      year,
      changeYear,
    ]);

    const { name } = stations;
    if (loaderStatus) loaderStatus(loader);

    const filteredData = data.filter(
      ({ datetime }) =>
        new Date(datetime) >= startDate && new Date(datetime) <= endDate
    );

    const exposure = calculateExposure(filteredData, pollutant).value || 0;
    const mean = calculateMean(filteredData, pollutant) || 'N/A';

    // TODO: This is hack to fix the issue mentioned below
    // Using a a custom package to develop the heatmap
    const colorScale = val => getLevel(val, pollutant).color;
    colorScale.ticks = () => [];

    // Wait for https://github.com/plouc/nivo/issues/693 to fix.
    // Or fix it yourself
    // or find a new library
    // console.log(
    //   data
    //     .map(({ [pollutant]: value, datetime }) => ({
    //       day: dayjs(datetime).format('YYYY-MM-DD'),
    //       value,
    //     }))
    //     .filter(({ value }) => value)
    // );

    //Index of 1y year duration
    const yearDurationIndex = 6;
    if (earliestIndex < yearDurationIndex || !data.length) {
      return (
        <Fade cascade>
          <div className={classes.chart}>
            <div className={classes.header}>
              <div>{name}</div>
              {isEmbed && (
                <div className={classes.delete}>
                  <Delete
                    style={
                      !multi
                        ? { opacity: 0.1, fontSize: 20 }
                        : { cursor: 'pointer', fontSize: 20 }
                    }
                    onClick={() =>
                      !multi ? null : deleteStation(stations.slug)
                    }
                  />
                </div>
              )}
            </div>
            <div className={classes.noData}>
              <img
                src={NoData}
                width="40%"
                height="40%"
                alt="No data available"
              />
              Not enough data!!!
            </div>
          </div>
        </Fade>
      );
    }

    let chartSizeProps;
    if (matches)
      chartSizeProps = {
        width: 250,
      };

    return (
      <Fade cascade>
        <div className={classes.chart}>
          <div className={classes.header}>
            <div>{name}</div>
            <div>
              <span className={classes.exposure}>Exposure: {exposure}x</span>
              <span>Mean: {mean}</span>
            </div>
            {isEmbed && (
              <div className={classes.delete}>
                <Delete
                  style={
                    !multi
                      ? { opacity: 0.1, fontSize: 20 }
                      : { cursor: 'pointer', fontSize: 20 }
                  }
                  onClick={() => (!multi ? null : deleteStation(stations.slug))}
                />
              </div>
            )}
          </div>
          <ResponsiveCalendar
            data={data
              .map(({ [pollutant]: value, datetime }) => ({
                day: dayjs(datetime).format('YYYY-MM-DD'),
                value,
              }))
              .filter(({ value }) => value)}
            from={new Date(year, 1)}
            to={new Date(year, 11, 31)}
            emptyColor="#eeeeee"
            colors={colors}
            margin={{ top: 28, right: matches ? 100 : 0 }}
            colorScale={colorScale}
            {...chartSizeProps}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            direction={matches ? 'vertical' : 'horizontal'}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: 'right-to-left',
              },
            ]}
          />
        </div>
      </Fade>
    );
  }
);

const matchStateToProps = ({ snackbar }) => {
  return {
    snackbar,
  };
};

export default compose(
  connect(matchStateToProps, {
    updateSnackbar,
  })
)(Heatmap);
