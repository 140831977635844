import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import InfoDialog from '../chart/info-dialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    minWidth: '14rem',
    paddingTop: '0.5rem',
  },
  selector: {
    display: 'flex',
  },
  title: {
    fontSize: 20,
    color: '#323232',
  },
  menuUnit: {
    fontSize: 10,
    display: 'block',
    color: '#c8c8c8',
    position: 'relative',
    top: -20,
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
  menuTitle: {
    fontSize: 14,
    color: '#323232',
  },
  unit: {
    fontSize: 10,
    color: '#c8c8c8',
  },
  iconContainer: {
    position: 'relative',
  },
  infoIcon: {
    position: 'relative',
    top: '0.3rem',
  },
  icons: {
    color: '#C8C8C8',
  },
  menuRoot: {
    height: '42px',
    lineHeight: 'auto',
  },
  arrowIcon: {
    fontSize: 30,
  },
  popper: {
    zIndex: 1,
    width: 'auto',
    minWidth: 224,
  },
  [theme.breakpoints.down('xs')]: {
    selector: {
      justifyContent: 'flex-start',
    },
  },
}));

export default function Selector({
  param,
  onChange,
  dialog,
  allParameters,
  title,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const changePollutant = key => () => {
    onChange(key);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div ref={anchorRef} className={classes.selector}>
        <div
          onClick={handleToggle}
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'flex-end',
          }}
        >
          <span>
            <div className={classes.title}>{allParameters[param].label}</div>
          </span>
          <span className={classes.iconContainer}>
            <ArrowDown className={`${classes.icons} ${classes.arrowIcon}`} />
          </span>
        </div>
        {dialog && (
          <div className={classes.infoIcon}>
            <InfoDialog
              title={title}
              style={{ position: 'absolute', top: '0.4rem', left: '-2rem' }}
            >
              {dialog}
            </InfoDialog>
          </div>
        )}
      </div>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {Object.keys(allParameters).map(key => (
                    <MenuItem
                      key={key}
                      onClick={changePollutant(key)}
                      className={classes.menuRoot}
                    >
                      <div>
                        <p className={classes.menuTitle}>
                          {allParameters[key].label}
                        </p>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
