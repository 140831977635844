import React from 'react';
import ContentLoader from 'react-content-loader';
export default function MonitorCountLoader() {
  return (
    <div style={{ height: 35, width: 85 }}>
      <ContentLoader
        height={35}
        width={85}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="1" y="1" rx="4" ry="4" width="85" height="35" />
      </ContentLoader>
    </div>
  );
}
