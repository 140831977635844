import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  RenderPopularComparison,
  RenderPollutedLocations,
  RenderFavComparison,
  TermsPrivacyComponent,
} from './index';
import { getStations } from 'common/api/geolocation';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getTopPollutedStations } from 'common/api/top';
import MapBox from '../mapbox/map';
import { Link } from 'react-router-dom';
import { ReactComponent as Monitors } from '../../assets/icons/monitors.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { MonitorCountLoader } from '../loaders';
import store from 'store2';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from 'react-reveal/Fade';
import { updateSnackbar } from 'common/store/actions';
import { pollutantOptions as POLLUTANTS } from 'common/config';
import { UserEvent, MapEvent, MonitorPageEvent } from '../ga-events';

const POLLUTANT = Object.keys(POLLUTANTS)[0];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  block: {
    marginBottom: '2.5rem',
  },
  loader: {
    display: 'flex',
    margin: '3.4rem 2.2rem 3rem 2.2rem',
  },
  termsAndPolicyMob: {
    paddingBottom: '20px',
    display: 'none',
  },
  monitorsWrap: { display: 'flex', flex: '1 1' },
  monitors: {
    margin: 10,
  },
  title: {
    fontSize: '1.1rem',
  },
  subTitle: {
    fontSize: '0.75rem',
    color: '#C8C8C8',
  },
  more: {
    fontSize: '0.75rem',
    color: '#C8C8C8',
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
      transition: 'all ease-in 0.3s',
    },
    display: 'block',
    textAlign: 'end',
  },
  link: {
    color: '#C8C8C8',
    textDecoration: 'none',
    '&:hover': {
      color: 'black',
    },
  },
  header: {
    marginLeft: '2.2rem',
    marginBottom: '0.2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '2.2rem',
  },
  subHeader: {
    margin: '0 0 1rem 2.2rem',
  },
  comparisonCard: {
    margin: '0 1.6rem 0 1.6rem',
  },
  stationsCard: {
    margin: '0 1.6rem 0 1.6rem',
  },
  mobileView: {
    display: 'none',
  },
  topPollutedHide: {
    '&: > .topGrid:nth-child(1)': {
      display: 'none',
    },
    '&: > .topGrid:nth-child(2)': {
      display: 'none',
    },
    '&: > .topGrid:nth-child(3)': {
      display: 'none',
    },
  },
  [theme.breakpoints.between('xs', 'md')]: {
    comparisonCard: {
      marginRight: '1.6rem',
    },
    loader: {
      justifyContent: 'center',
      display: 'flex',
      margin: '3rem 0rem',
    },
    termsAndPolicyHideMob: { display: 'none' },
    termsAndPolicyMob: { display: 'block' },
    monitorsWrap: { flex: 'none', textAlign: 'center' },
  },
  [theme.breakpoints.down('xs')]: {
    mobileView: {
      display: 'flex',
      justifyContent: 'center',
    },
    desktopView: {
      display: 'none',
    },
    comparisonCard: {
      marginRight: 0,
    },
    title: {
      fontSize: '0.9rem',
    },
    stationsCard: {
      paddingBottom: 5,
    },
    loader: {
      justifyContent: 'center',
      display: 'flex',
      margin: '2.8rem 0rem',
    },
    termsAndPolicyHideMob: { display: 'none' },
    termsAndPolicyMob: { display: 'block' },
    monitorsWrap: { flex: 'none', textAlign: 'center' },
  },
}));

const FAVOURITE_LOCATIONS = 'favourite_locations';
const COMPARE_LOCATIONS = 'compare_locations';
const DEFAULT_CARD_NUMBER = 3;
const MAX_CARD_NUMBER = 9;

function showMore(show, setShow, classes, mobile, max, isTopPolluted) {
  return (
    <Typography
      className={`${classes.more} ${
        mobile ? classes.mobileView : classes.desktopView
      }`}
      onClick={() => {
        if (show !== DEFAULT_CARD_NUMBER) {
          setShow(DEFAULT_CARD_NUMBER);
          return true;
        }

        setShow(max);
        UserEvent({
          action: 'clicked on more location',
          label: isTopPolluted ? 'top pollution cards' : 'favorite cards',
        });
        return true;
      }}
    >
      {show === DEFAULT_CARD_NUMBER ? 'More Locations' : 'Show Less'}
    </Typography>
  );
}

function getFavourites(arr) {
  if (!arr) return [];
  return arr.map(val => {
    return {
      slug: val,
      pm25: null,
    };
  });
}

export default function Right() {
  // TODO: NO functional component will have this big state
  // Refactor and simplify
  const [online, setOnline] = useState(null);
  const [showPolluted, setShowPolluted] = useState(DEFAULT_CARD_NUMBER);
  const [favourites, setFavourites] = useState([]);
  const [favouritesComp, setFavouritesComp] = useState([]);
  const [showFavourite, setShowFavourite] = useState(DEFAULT_CARD_NUMBER);
  const locations = useSelector(state => state.locations);
  const [topPollution, setTopPollution] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    function getData() {
      getStations()
        .then(({ data }) => {
          setOnline(data.length);
        })
        .catch(e => {
          dispatch(
            updateSnackbar(
              'Oops something went wrong while getting number of online monitors!!!'
            )
          );
        });

      getTopPollutedStations(POLLUTANT)
        .then(({ data }) => {
          setTopPollution(data);
        })
        .catch(e => {
          dispatch(
            updateSnackbar(
              'Oops something went wrong while getting top polluted stations!!!'
            )
          );
        });
    }
    getData();

    const favouriteLocations = getFavourites(store(FAVOURITE_LOCATIONS));
    setFavourites(favouriteLocations);

    const compareLocations = getFavourites(store(COMPARE_LOCATIONS));
    setFavouritesComp(compareLocations);
  }, [dispatch]);

  const classes = useStyles();
  const theme = useTheme();
  const matchMax = useMediaQuery(theme.breakpoints.down('xs'));
  const mapWidth = '100%';
  const mapHeight = matchMax ? '70vh' : '100vh';

  return (
    <div>
      {locations !== undefined && (
        <Fade cascade>
          <div className={classes.loader}>
            <div className={classes.monitorsWrap}>
              <Monitors
                height="70px"
                width="70px"
                onClick={() => {
                  UserEvent({
                    action: 'clicked on monitor icon',
                    label: 'home page',
                  });
                }}
              />
              <div className={classes.monitors}>
                {!online ? (
                  <MonitorCountLoader />
                ) : (
                  <div>
                    <span
                      style={{
                        color: '#140CF2',
                        fontSize: '1.5rem',
                        fontWeight: 600,
                      }}
                    >
                      {online}
                    </span>
                    <Tooltip placement="top-start" title="Monitors list">
                      <Link to="/monitors" style={{ textDecoration: 'none' }}>
                        <span style={{ color: '#C8C8C8', fontSize: '1.5rem' }}>
                          /{locations.length}
                        </span>
                      </Link>
                    </Tooltip>
                  </div>
                )}
                Monitors Online
              </div>
            </div>
            <div className={classes.termsAndPolicyHideMob}>
              <TermsPrivacyComponent />
            </div>
          </div>
        </Fade>
      )}
      <div className={classes.block}>
        <div className={classes.header}>
          <Fade cascade>
            <Typography className={classes.title}>
              Top Polluted Locations Today
            </Typography>
          </Fade>

          {/* render this show more button only in desktop view */}
          <Fade>
            {showMore(
              showPolluted,
              setShowPolluted,
              classes,
              false,
              MAX_CARD_NUMBER,
              true
            )}
          </Fade>
        </div>
        <div className={classes.subHeader}>
          <Fade cascade>
            <Typography className={classes.subTitle}>
              Based on live PM2.5 levels
            </Typography>
          </Fade>
        </div>
        <div className={classes.stationsCard}>
          <RenderPollutedLocations
            showPolluted={showPolluted}
            topPollution={topPollution}
            googleEvent={({ action }) => {
              MonitorPageEvent({ action: action, label: 'popular stations' });
            }}
          />

          {/* render this show more button only in mobile view */}
          <Fade>
            {showMore(
              showPolluted,
              setShowPolluted,
              classes,
              true,
              MAX_CARD_NUMBER,
              true
            )}
          </Fade>
        </div>
      </div>
      {!matchMax && (
        <div className={classes.block}>
          <div className={classes.header}>
            <Typography className={classes.title}>
              Top Station Comparison
            </Typography>
          </div>
          <div className={classes.subHeader}>
            <Typography className={classes.subTitle}>
              Based on live PM2.5 levels
            </Typography>
          </div>
          <div className={classes.stationsCard}>
            <RenderPopularComparison
              favourites={favourites}
              googleEvent={({ action }) => {
                MonitorPageEvent({
                  action: action,
                  label: 'popular stations comparisons',
                });
              }}
            />
          </div>
        </div>
      )}
      {(favourites.length > 0 || (!matchMax && favouritesComp.length > 0)) && (
        <div className={classes.block}>
          <div className={classes.header}>
            <Fade cascade>
              <Typography className={classes.title}>
                Your Favourite Locations
              </Typography>
            </Fade>

            {/* render this show more button only in desktop view */}
            <Fade>
              {favourites.length + favouritesComp.length >
                DEFAULT_CARD_NUMBER &&
                showMore(
                  showFavourite,
                  setShowFavourite,
                  classes,
                  false,
                  favourites.length + favouritesComp.length,
                  false
                )}
            </Fade>
          </div>
          <div className={classes.subHeader}>
            <Fade cascade>
              <Typography className={classes.subTitle}>
                Based on PM2.5 levels for last 24 hours
              </Typography>
            </Fade>
          </div>
          <div
            className={`${classes.stationsCard} ${
              showFavourite === DEFAULT_CARD_NUMBER &&
              matchMax &&
              'topPollutedHide'
            }`}
          >
            {matchMax && favourites.length > 0 && (
              <RenderPollutedLocations
                showPolluted={showFavourite}
                topPollution={favourites}
                googleEvent={({ action }) => {
                  MonitorPageEvent({
                    action: action,
                    label: 'favorite stations',
                  });
                }}
                fav={true}
              />
            )}
            <div>
              <Grid
                spacing={1}
                container
                className={
                  showFavourite === DEFAULT_CARD_NUMBER && 'topPollutedHide'
                }
              >
                {!matchMax && favourites.length > 0 && (
                  <RenderPollutedLocations
                    showPolluted={showFavourite}
                    topPollution={favourites}
                    googleEvent={({ action }) => {
                      MonitorPageEvent({
                        action: action,
                        label: 'favorite stations',
                      });
                    }}
                    fav={true}
                  />
                )}

                {!matchMax && favouritesComp.length > 0 && (
                  <RenderFavComparison
                    showPolluted={showFavourite}
                    favourites={favouritesComp}
                    googleEvent={({ action }) => {
                      MonitorPageEvent({
                        action: action,
                        label: 'favorite stations comparisons',
                      });
                    }}
                    singleFavCount={favourites.length}
                    defaultCardCount={DEFAULT_CARD_NUMBER}
                  />
                )}
              </Grid>
            </div>

            {/* render this show more button only in mobile view */}
            <Fade cascade>
              {favourites.length > DEFAULT_CARD_NUMBER &&
                showMore(
                  showFavourite,
                  setShowFavourite,
                  classes,
                  true,
                  favourites.length,
                  true
                )}
            </Fade>
          </div>
        </div>
      )}
      <div className={classes.termsAndPolicyMob}>
        <TermsPrivacyComponent />
      </div>
      <div onMouseEnter={() => MapEvent({ action: 'Enter into map area' })}>
        <MapBox height={mapHeight} width={mapWidth} />
      </div>
    </div>
  );
}
