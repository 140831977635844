import React from 'react';
import EmbedCommon from './embed-data-hoc-component';
function EmbedHoc(Component, embedName, onlyOne = false) {
  return class extends React.Component {
    render() {
      return (
        <EmbedCommon
          embedName={embedName}
          EmbedComponent={Component}
          onlyOne={onlyOne}
        />
      );
    }
  };
}

export default EmbedHoc;
