import React from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import { ReactComponent as Locate } from '../../assets/icons/locateme.svg';
import { updateSnackbar } from 'common/store/actions';
import { connect } from 'react-redux';
import { currentLocation } from '../../utils';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

async function goToNearestStation(redirect, updateSnackbar) {
  try {
    const result = await currentLocation(message => {
      //error returning msg
      updateSnackbar(message);

      return true;
    });
    redirect(true, result);
  } catch (e) {
    redirect(false, e);
  }
}

function renderInput(inputProps) {
  const {
    updateSnackbar,
    InputProps,
    classes,
    ref,
    allStations,
    history,
    redirect,
    search,
    setSearch,
    matchMax,
    googleEvent,
    ...other
  } = inputProps;

  const locationButtonClicked = () => {
    googleEvent({
      action: 'Near me button clicked',
    });
    goToNearestStation(redirect, updateSnackbar);
  };

  return (
    <div className={classes.searchBar}>
      <SearchIcon className={classes.icons} />

      <TextField
        onClick={() => matchMax && setSearch(true)}
        style={{ marginLeft: '8px' }}
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },

          disableUnderline: true,
          ...InputProps,
        }}
        {...other}
      />
      <button
        type="button"
        title="Go to Your Nearest Station"
        className={classes.targetButton}
        onClick={locationButtonClicked}
      >
        <Locate width="20px" height="20px" style={{ marginTop: '5px' }} />
        <span className={classes.redPinger}></span>
      </button>
    </div>
  );
}

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        fontSize: '14px',
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

function getSuggestions(suggestions = [], value, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < suggestions.length &&
          suggestion.label.toLowerCase().includes(inputValue);

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: 10,
  },
  searchBar: {
    display: 'flex',
    backgroundColor: '#F2F2F2',
    borderRadius: '4px',
  },
  targetButton: {
    backgroundColor: '#F2F2F2',
    border: '1px solid #F2F2F2',
    width: 40,
    height: 40,
    color: '#000',
    cursor: 'pointer',
    fillOpacity: '0.5',
    borderRadius: '4px',
    '&:hover': {
      color: '#000',
      transition: 'all ease-in 0.3s',

      fillOpacity: '1',
    },
  },

  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    fontSize: 14,
    padding: '0.7rem 0',
  },
  icons: {
    color: '#C8C8C8',
    fontSize: '1.4rem',
    padding: '0.5rem 0.5rem',
  },
  redPinger: {
    position: 'absolute',
    right: 3,
    top: 2,
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: '#ff5722',
    animationName: '$blinker',
    animationDuration: '0.8s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
}));

function IntegrationDownshift(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchMax = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    allStations,
    onChange,
    history,
    redirect,
    updateSnackbar,
    search,
    setSearch,
    googleEvent,
  } = props;
  const suggestions = allStations.map(station => ({
    ...station,
    label: `${station.name} - ${station?.metadata?.city}`,
  }));
  return (
    <div className={classes.root}>
      <Downshift
        onChange={item =>
          onChange(suggestions.find(({ label }) => item === label))
        }
        id="downshift-simple"
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: 'Search Monitor',
          });

          return (
            <div className={classes.container}>
              {renderInput({
                updateSnackbar,
                fullWidth: true,
                classes,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus },
                inputProps,
                allStations,
                history,
                redirect,
                search,
                setSearch,
                matchMax,
                googleEvent,
              })}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper
                    className={classes.paper}
                    square
                    style={{ overflowY: 'auto', maxHeight: '40vh' }}
                  >
                    {getSuggestions(suggestions, inputValue).map(
                      (suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion.label,
                          }),
                          highlightedIndex,
                          selectedItem,
                        })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}

const matchStateToProps = ({ snackbar }) => {
  return {
    snackbar,
  };
};

export default compose(
  connect(matchStateToProps, {
    updateSnackbar,
  }),
  withRouter
)(IntegrationDownshift);
