import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  iconStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function SimpleDialog(props) {
  const { onClose, open, title } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open}>
      <MuiDialogTitle>
        <Typography className={classes.iconStyle} variant="h6">
          {title}{' '}
          <CloseIcon
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          ></CloseIcon>
        </Typography>
      </MuiDialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}
