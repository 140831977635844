import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import { PollutedLoader } from '../loaders';
import {
  durations as DURATIONS,
  pollutantOptions as POLLUTANTS,
} from 'common/config';

const DURATION = DURATIONS[0].value;
const POLLUTANT = Object.keys(POLLUTANTS)[0];

const useStyles = makeStyles(theme => ({
  card: {
    marginLeft: '0.6rem',
    marginRight: '0.6rem',
    height: '12.5rem',
    marginTop: '0.6rem',
  },
  depthEffect: {
    borderRadius: '8px',

    boxShadow: ' 1px 2px 6px rgba(0, 0, 0, 0.1)',

    '&:hover': {
      boxShadow: ' 3px 4px 14px rgba(0, 0, 0, 0.2)',
      border: '0px solid #A6A6A6',
      transition: 'all ease-in 0.3s',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pollutant: {
    fontSize: 12,
    marginTop: 0,
    position: 'relative',
  },
  pos: {
    marginBottom: 12,
  },
  title: {
    fontSize: '1.1rem',
    color: '#E68A00',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontSize: '12px',
    color: '#C8C8C8',
  },
  title2: {
    fontSize: '1.1rem',
    color: '#4AAAEF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  subtitle2: {
    fontSize: '12px',
    color: '#C8C8C8',
  },
  versus: {
    marginTop: '1.7rem',
    marginBottom: '1.4rem',
  },
}));

export default function PopularComparisonCard({ comparisonData, googleEvent }) {
  const classes = useStyles();

  if (comparisonData === null) {
    return (
      <Card className={`${classes.card} ${classes.depthEffect} `}>
        <PollutedLoader />
      </Card>
    );
  }
  const { stationA, stationB } = comparisonData;

  const link = `/chart?duration=${DURATION}&pollutant=${POLLUTANT}&stations=${stationA.slug}%2B${stationB.slug}`;

  return (
    <Link
      to={link}
      style={{ textDecoration: 'none' }}
      onClick={() =>
        googleEvent({
          action: 'Navigate to compare stations',
        })
      }
    >
      <Tooltip
        title={`${stationA.name} and ${stationB.name}`}
        placement="top-end"
      >
        <Card className={`${classes.card} ${classes.depthEffect}`}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  {stationA.name}
                </Typography>
                <Typography className={classes.subtitle}>
                  {stationA.city}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.versus}>
              <Grid item xs={12}>
                vs.
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.title2}>
                  {stationB.name}
                </Typography>
                <Typography className={classes.subtitle2}>
                  {stationB.city}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Tooltip>
    </Link>
  );
}
