import React from 'react';
import { makeStyles } from '@material-ui/styles';
import InfoDialog from './info-dialog';
import Tooltip from '@material-ui/core/Tooltip';
import ShareEmbed from '../embed/share-embed';
import { useLocation } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles({
  root: {
    border: '1px solid #F4F4F4',
    borderRadius: 8,
    height: '20rem',
    padding: '1rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    marginBottom: '1rem',
  },
  info: {
    display: 'flex',
    float: 'right',
    justifyContent: 'space-between',
    width: '45px',
  },
  infoIcon: {
    color: '#c8c8c8',
    fontSize: '1rem',
  },
  label: {
    marginTop: '0.5rem',
    width: 'max-content',
    fontSize: 12,
    color: '#c8c8c8',
  },
});

export default function VisualizationCard(props) {
  const classes = useStyles();
  const {
    title,
    label,
    footer,
    chart,
    tooltip,
    dialog,
    embedName,
    dialogEvent,
  } = props;
  const { pathname } = useLocation();
  const isEmbed = pathname.includes('embed');

  return (
    <Tooltip title={tooltip !== undefined ? tooltip : ''} placement="top-end">
      <div className={classes.root}>
        <div className={classes.header}>
          <Fade>
            <span> {title}</span>
          </Fade>

          <Fade cascade>
            <span className={classes.info}>
              {!isEmbed && (
                <span style={{ cursor: 'pointer' }}>
                  <ShareEmbed
                    iconStyle={{ fill: '#808080', width: '15px' }}
                    embedName={embedName}
                  />
                </span>
              )}
              {dialog && (
                <InfoDialog title={title} size={20} googleEvent={dialogEvent}>
                  {dialog}
                </InfoDialog>
              )}
            </span>
          </Fade>
          <div className={classes.label}>{label}</div>
        </div>
        {chart}
        <Fade>{footer}</Fade>
      </div>
    </Tooltip>
  );
}
