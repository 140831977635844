import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';
import Fade from 'react-reveal/Fade';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  root: {
    background: theme.palette.background.paper,
    borderRadius: '8px',
  },
  card: {
    margin: '0.6rem 0rem',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  cardContent: {
    paddingBottom: '16px',
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  heading: {
    fontWeight: '400',
    color: theme.palette.text.primary,
    opacity: 0.8,
  },
  largeAlphabet: {
    fontSize: '1.8rem',
    fontWeight: '600',
    color: theme.palette.text.primary,
    opacity: 1,
  },
  divider: {
    borderColor: '#b6b6b6',
    marginBottom: '14px',
    borderWidth: '0.5px',
  },
  depthEffect: {
    borderRadius: '8px',

    boxShadow: ' 1px 2px 6px rgba(0, 0, 0, 0.1)',

    '&:hover': {
      boxShadow: ' 3px 4px 14px rgba(0, 0, 0, 0.2)',
      border: '0px solid #A6A6A6',
      transition: 'all ease-in 0.3s',
    },
  },
  title: {
    fontSize: '1.1rem',
    color: '#4d4d4d',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontSize: '13px',
    color: '#C8C8C8',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '1rem',
      margin: '1rem',
    },
    heading: {
      margin: 0,
    },
    largeAlphabet: {
      fontSize: '1.2rem',
    },
  },
});

const StateCard = ({ state, data, classes }) => {
  const stations = orderBy(
    data,
    [station => station.name.toLowerCase()],
    ['asc']
  );

  return (
    <div className={classes.root}>
      <Fade cascade>
        <h3 className={classes.heading}>
          <span className={classes.largeAlphabet}>{state.substring(0, 1)}</span>
          {state.substring(1, state.length)} ({stations.length})
        </h3>
      </Fade>

      <hr className={classes.divider} />

      <Fade cascade>
        <Grid spacing={1} container>
          {stations.map(({ name, city, slug }) => (
            <Grid item sm={4} xs={12}>
              <div className={classes.divMargin}>
                <Link to={`/chart?stations=${slug}`} className={classes.link}>
                  <Tooltip title={name} placement="top-end">
                    <Card className={`${classes.card} ${classes.depthEffect}`}>
                      <CardContent className={classes.cardContent}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography className={classes.title}>
                              {name}
                            </Typography>
                            <Typography className={classes.subtitle}>
                              {city}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Tooltip>
                </Link>
              </div>
            </Grid>
          ))}
        </Grid>
      </Fade>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(StateCard);
