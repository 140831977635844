import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dropdown from './year-selector';
import PolltantRanges from './pollutant-range-blocks';
import { Embeds, YEARS, getAvailabilityYear } from 'common/config';
import InfoDialog from './info-dialog';
import { CalendarHeatmap } from './dialog-content';
import ShareEmbed from '../embed/share-embed';
import Heatmap from './heatmap';
import Fade from 'react-reveal/Fade';
import { Auth0Context } from '../../auth/auth0';
import { useSelector } from 'react-redux';
import { SupportingChartsEvent } from '../ga-events';

const EVENT_LABEL = 'calender heatmap';

const containerStyles = makeStyles({
  root: {
    border: '1px solid #F4F4F4',
    borderRadius: 8,
    padding: '1rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    float: 'right',
    justifyContent: 'space-between',
    width: '45px',
    marginTop: 4,
  },
  infoIcon: {
    color: '#c8c8c8',
    fontSize: '1rem',
  },
  label: {
    width: 'max-content',
    fontSize: 12,
    color: '#c8c8c8',
  },
});

function Calendar({ data, pollutant, earliestDuration, selectedStations }) {
  const [year, changeYear] = React.useState(YEARS[0]);

  const classes = containerStyles();
  const { isAuthenticated } = React.useContext(Auth0Context);

  const duration = isAuthenticated
    ? earliestDuration === 'fe' || earliestDuration === '3y'
      ? earliestDuration
      : '1y'
    : '1y';

  const years = getAvailabilityYear(duration).map(key => ({
    label: key,
    key,
  }));

  const locations = useSelector(state => state.locations);
  const stationsList = selectedStations.map(slug => {
    return locations.find(({ slug: stationSlug }) => stationSlug === slug);
  });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Fade>
          <div>Calendar Heatmaps </div>
        </Fade>
        <div style={{ width: '50%', marginTop: '4px' }}>
          <PolltantRanges pollutant={pollutant} />
        </div>
        <span className={classes.info}>
          <Fade>
            <span style={{ cursor: 'pointer' }}>
              <ShareEmbed
                iconStyle={{ fill: '#808080', width: '15px' }}
                embedName={Object.keys(Embeds)[2]}
              />
            </span>
          </Fade>
          <Fade>
            <InfoDialog
              title={'Calendar Heatmaps'}
              size={20}
              googleEvent={({ action }) =>
                SupportingChartsEvent({
                  action: action,
                  label: EVENT_LABEL,
                })
              }
            >
              <CalendarHeatmap />
            </InfoDialog>
          </Fade>
        </span>
      </div>
      <div className={classes.label}>
        <Fade>
          <Dropdown
            selected={year}
            options={years}
            onChange={changeYear}
            googleEvent={({ action }) => {
              SupportingChartsEvent({
                action: action,
                label: EVENT_LABEL,
              });
            }}
          />
        </Fade>
      </div>
      {Object.keys(data).map(slug => {
        return (
          <div key={slug} style={{ margin: '3rem auto' }}>
            <Heatmap
              year={year}
              stations={data[slug].meta}
              pollutant={pollutant}
              stationsList={stationsList}
              changeYear={changeYear}
            />
          </div>
        );
      })}
    </div>
  );
}

export default Calendar;
