import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { ReactComponent as BreeZO } from '../../assets/icons/breezo-logo.svg';
import { ReactComponent as BlueSky } from '../../assets/icons/bluesky-logo.svg';
import { makeStyles } from '@material-ui/styles';

const footerStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#DFEFFF',
    height: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  breezoIcon: {
    width: 80,
    height: 30,
  },
  blueSkyIcon: {
    width: 100,
    height: 30,
  },
  logoText: {
    marginRight: 5,
    fontSize: '0.6rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('xs')]: {
    breezoIcon: {
      width: 75,
    },
    blueSkyIcon: {
      width: 100,
    },
    logoText: {
      fontSize: 8,
    },
  },
}));

const EmbedFooter = ({ history }) => {
  const {
    location: { pathname, search },
  } = history;

  const breezoLink =
    pathname === '/embed/map'
      ? 'http://aqi.breezo.io/'
      : `http://aqi.breezo.io/chart${search}`;
  const blueSkyLink = 'https://blueskyhq.io/';

  const classes = footerStyles();

  return (
    <div className={classes.root}>
      <div>
        <a href={breezoLink} target="_target">
          <BreeZO className={classes.breezoIcon} />
        </a>
      </div>
      <div style={{ marginRight: 10, display: 'flex' }}>
        <p className={classes.logoText}>Powered by:</p>
        <a href={blueSkyLink} target="_target">
          <BlueSky className={classes.blueSkyIcon} />
        </a>
      </div>
    </div>
  );
};

export default compose(withRouter)(EmbedFooter);
