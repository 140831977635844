import { toast } from 'react-toastify';

import {
  SET_LOCATIONS,
  TOGGLE_SIDEBAR,
  TOGGLE_SETTINGS,
  UPDATE_SNACKBAR,
  APP_CRASH,
} from '../actions/types';

import initial_state from './initialState';
import { getLocations } from './helper';

export default (state = initial_state, action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return {
        ...state,
        locations: getLocations(action.payload.data),
      };

    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };

    case TOGGLE_SETTINGS:
      return {
        ...state,
        settingsOpen: !state.settingsOpen,
      };

    case UPDATE_SNACKBAR:
      const { message, timeout } = action.payload;
      toast.info(message);
      return {
        ...state,
        snackbar: {
          message,
          timeout: timeout,
        },
      };

    case APP_CRASH:
      const appCrash = action.payload;
      return {
        ...state,
        appCrash: appCrash,
      };

    default:
      return state;
  }
};
