import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import EmbedEvent from './ga-events/embed';

function copyToClipboard() {
  const code = document.getElementById('code');
  code.select();
  document.execCommand('copy');
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1.5rem 10rem 0 10rem',
  },
  textArea: {
    marginTop: 20,
    backgroundColor: '#9abce86b',
    fontSize: 13,
    width: 450,
    height: 180,
    overflowY: 'hidden',
    borderColor: '#a1b9e4',
    borderRadius: 8,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    color: '#1E4793',
    outline: 'none',
    resize: 'none',
  },
  [theme.breakpoints.down('xs')]: {
    textArea: {
      width: 300,
    },
  },
}));

export default function GetCode({ url }) {
  const classes = useStyles();
  const code = `
  <iframe
        src="${url}"
        width="100%"
        height="500px"
        id="embed"
        style="border: 1px solid #C9C9C9;border-radius: 8px"
  ></iframe>
`;
  return (
    <div>
      <textarea
        className={classes.textArea}
        id="code"
        value={code}
        contentEditable={false}
      >
        {code}
      </textarea>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={() => {
            copyToClipboard();
            EmbedEvent({ action: 'clicked on copy code', label: url });
          }}
          disableElevation={true}
          style={{ fontSize: 12, textTransform: 'none' }}
          color="primary"
        >
          Copy
        </Button>
      </div>
    </div>
  );
}
