export default {
  // UI State, will be consolidated in one object
  sidebarOpen: false,
  settingsOpen: false,
  appCrash: false,
  snackbar: {
    message: null,
    timeout: 3000,
  },
};
