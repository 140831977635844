import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';
import Fade from 'react-reveal/Fade';

const styles = () => ({
  root: {
    width: '95%',
    margin: '1rem 0.5rem',
    height: '13rem',
  },
});

const NewsLoader = ({ classes }) => {
  return (
    <Fade cascade>
      <div className={classes.root}>
        <ContentLoader
          height={300}
          width={330}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="330" height="170" />
          <rect x="10" y="175" rx="0" ry="0" width="310" height="16" />
          <rect x="10" y="195" rx="0" ry="0" width="280" height="16" />
          <rect x="10" y="245" rx="0" ry="0" width="100" height="8" />
          <rect x="220" y="245" rx="0" ry="0" width="100" height="8" />
        </ContentLoader>
      </div>
    </Fade>
  );
};

export default withStyles(styles, { withTheme: true })(NewsLoader);
