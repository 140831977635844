import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: '50%',
  right: '50%',
  transform: 'translate(50%, -50%)',
};

const Loader = ({ classes }) => {
  return (
    <div style={styles} className={classes}>
      <CircularProgress color="secondary" size={50} />
    </div>
  );
};

export default Loader;
