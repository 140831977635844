import React, { useState, useEffect } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { setAxiosHeaderToken } from 'common/api/axios';
import config from './config.json';
import history from '../history';

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const authConfiguration = {
  domain: config.domain,
  client_id: config.clientId,
  responseType: config.responseType,
  scope: config.scope,
  audience: config.audience,
  redirect_uri: `${window.location.origin}/callback`,
};

export const Auth0Context = React.createContext();

export const Auth0Provider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [authToken, setAuthToken] = useState();
  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(authConfiguration);
      setAuth0(auth0FromHook);

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      if (isAuthenticated) {
        const { __raw } = await auth0FromHook.getIdTokenClaims();
        setAxiosHeaderToken(__raw);
        setAuthToken(__raw);
      }

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }
      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };
    initAuth0();
  }, []);

  //Login with popup
  const login = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
      const { __raw } = await auth0Client.getIdTokenClaims();
      const user = await auth0Client.getUser();

      setLoading(true);
      setAxiosHeaderToken(__raw);
      setAuthToken(__raw);
      setUser(user);
      setIsAuthenticated(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
  };

  //handle callback redirect
  const handleRedirectCallback = async () => {
    if (!auth0Client) return;
    setLoading(true);
    const { appState } = await auth0Client.handleRedirectCallback();
    onRedirectCallback(appState);
    const { __raw } = await auth0Client.getIdTokenClaims();
    const user = await auth0Client.getUser();

    setAxiosHeaderToken(__raw);
    setAuthToken(__raw);
    setUser(user);
    setIsAuthenticated(true);
    setLoading(false);
  };

  //Logout and redirect to '/' after logging out
  const logout = () => {
    if (auth0Client) {
      auth0Client.logout({
        returnTo: window.location.origin,
        clientID: config.clientID,
      });
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        authToken,
        login,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => {
          if (auth0Client) auth0Client.getIdTokenClaims(...p);
        },
        loginWithRedirect: (...p) => {
          if (auth0Client) auth0Client.loginWithRedirect(...p);
        },
        getTokenSilently: (...p) => {
          if (auth0Client) auth0Client.getTokenSilently(...p);
        },
        getTokenWithPopup: (...p) => {
          if (auth0Client) auth0Client.getTokenWithPopup(...p);
        },
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
