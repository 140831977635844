import getLevel from './get-level';
import { pollutantLabelColorReference as allLevels } from '../config';

// TODO: Put more comments here
export default function calculate(arr, pollutant) {
  const values = arr.map(({ [pollutant]: val }) => val).filter(v => v);

  const levels = values
    .map(val => getLevel(val, pollutant))
    .reduce(
      (acc, val) =>
        Object.assign(acc, { [val.color]: (acc[val.color] || 0) + 1 }),
      {}
    );

  return allLevels.map(({ label, color }) => ({
    label,
    color,
    value: levels[color]
      ? +((levels[color] / values.length) * 100).toFixed(0)
      : 0,
  }));
}
