import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { debounce, isEqual, differenceWith, omit } from 'lodash';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { getMeasurements as getData } from 'common/api/measurements';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  durations as DURATIONS,
  pollutantOptions as POLLUTANTS,
} from 'common/config';
import { updateSnackbar } from 'common/store/actions';
import {
  DurationSelector,
  PollutantSelector,
  LocationSelector,
  Settings,
} from '../chart';
import { Loader } from '../loaders';
import {
  getPollutant,
  getPollutantStatus,
  getNewStation,
  shouldDurationChange,
  getEarliestIndexBySlug,
  getEarliestIndexByData,
  isDataAvailable,
  calculateAllDataSize,
} from '../../utils/chart-utils';
import withWidth from '@material-ui/core/withWidth';
import { Footer } from '../embed';
import PollutantRanges from '../chart/pollutant-range-blocks';
import NoData from '../../assets/images/no-data.svg';
import calculateAQI from 'aqi-calculator';
import { UserEvent } from '../ga-events';

const AQI_DURATION = DURATIONS[0].value;
const stationsType = {
  decode: encoded => (encoded ? encoded.split('+') : undefined),
  encode: decoded => (decoded ? decoded.join('+') : undefined),
};

const urlPropsQueryConfig = {
  stations: { type: stationsType },
  duration: { type: UrlQueryParamTypes.string, defaultValue: '1d' },
  pollutant: { type: UrlQueryParamTypes.string, defaultValue: 'pm25' },
};

const styles = theme => ({
  root: {
    padding: '1rem',
  },
  noData: {
    width: '100%',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
  },
  metaInfo: {
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  controllers: {
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  ranges: {
    width: '40%',
  },
  durationSelector: {
    maxWidth: '70%',
  },
  settings: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  locationSelector: {
    margin: '12px 0',
    border: '1px solid #c9c9c9',
    borderRadius: 8,
  },
  embed: {
    margin: '12px 0',
  },
  footer: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 10,
  },
  [theme.breakpoints.down('md')]: {
    metaInfo: {
      margin: '12px 0',
    },
    ranges: {
      width: '50%',
      marginLeft: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '10px',
    },
    metaInfo: {
      margin: '12px 0',
      display: 'block',
    },
    title: {
      margin: '12px 0',
    },
    ranges: {
      width: '60%',
      margin: '12px 0',
    },
    controllers: {
      display: 'block',
    },
    durationSelector: {
      marginLeft: -10,
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    ranges: {
      width: '100%',
    },
    durationSelector: {
      marginLeft: -10,
      maxWidth: '100%',
    },
    locationSelector: {
      border: 0,
    },
  },
});

class EmbedCommon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      settings: {
        points: true,
        healthy: true,
        interpolation: true,
        grid: true,
        annotations: true,
        mean: false,
        search: false,
      },
      changeStationLoader: false,
    };
    this.setSearch = this.setSearch.bind(this);

    this.onClickChangeStation = debounce(this.onClickChangeStation, 600);
    document.addEventListener(
      'keydown',
      event => this.arrowButtonHandle(event),
      false
    );
  }

  setSearch(state) {
    this.setState({
      search: state,
    });
  }

  arrowButtonHandle(event) {
    if (Object.keys(this.state.data).length > 1) return null;

    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        this.onClickChangeStation('previous');

        break;
      case 'ArrowRight':
        event.preventDefault();
        this.onClickChangeStation('next');
        break;
      default:
    }
  }

  componentDidMount() {
    const { stations, duration } = this.props;
    if (stations || stations !== undefined) {
      stations.forEach(slug => {
        this.updateData(slug, duration);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { stations, duration } = this.props;
    const { data } = this.state;

    if (!data) return;

    //run when only duration changes
    if (prevProps.duration !== duration) {
      stations.forEach(slug => {
        this.updateData(slug, duration);
      });
      return;
    }

    //for station change
    if (!isEqual(stations, prevProps.stations)) {
      const slug = differenceWith(stations, prevProps.stations)[0];
      this.updateData(slug, duration, prevProps.stations);
    }
  }

  static getDerivedStateFromProps(props) {
    const { stations, updateSnackbar, onChangeStations, allStations } = props;
    //Provide a default station if not exist,  Default station is ito cause it has all the data
    // When slug does not exist pick the first slug from array of stations
    let defaultStation = 'ito-delhi-cpcb-9f8';
    let name = '';
    if (!stations && allStations.length > 0) {
      const isStationExist = allStations.find(
        ({ slug }) => slug === defaultStation
      );
      if (isStationExist) {
        name = isStationExist.name;
      } else {
        defaultStation = allStations[0].slug;
        name = allStations[0].name;
      }
      updateSnackbar(`Default station is ${name}`);
      onChangeStations([defaultStation]);
    }
    return null;
  }

  async setAQI(slug) {
    try {
      const { data: response } = await getData(slug, AQI_DURATION);
      const aqi = calculateAQI(response.data);
      //Set AQI only if slug already present in state
      if (this.state.data[slug])
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            [slug]: {
              ...prevState.data[slug],
              aqi: aqi,
            },
          },
        }));
    } catch (err) {
      updateSnackbar('Oops!! Something went wrong while getting aqi');
    }
    this.setState({ changeStationLoader: false });
  }

  async updateData(slug, duration, prevPropStations) {
    const {
      allStations,
      onChangeStations,
      stations,
      onChangeDuration,
      updateSnackbar,
      embedName,
    } = this.props;

    if (!slug) return null;

    const earliestIndex = getEarliestIndexBySlug(slug, allStations);

    //Change duration to earliest if selected is greater than earliest

    if (shouldDurationChange(duration, earliestIndex)) {
      onChangeDuration(DURATIONS[earliestIndex].value);
      return;
    }

    const changeStation = stations.length > 1 ? false : true;

    this.setState(
      {
        changeStationLoader: true,
      },
      async () => {
        try {
          const { data: response } = await getData(slug, duration);

          const stationMeta = allStations.find(
            ({ slug: stationSlug }) => stationSlug === slug
          );

          this.setState(
            {
              data: changeStation
                ? {
                    [response.meta.slug]: {
                      data: response.data,
                      meta: stationMeta,
                      aqi: null,
                    },
                  }
                : {
                    ...this.state.data,
                    [response.meta.slug]: {
                      data: response.data,
                      meta: stationMeta,
                      aqi: this.state.data[slug]
                        ? this.state.data[slug].aqi
                        : null,
                    },
                  },
            },
            () => {
              //Pollutant gets changed if current pollutant don't have enough data
              this.changePollutant();
              if (!this.state.data[slug].aqi && embedName === 'Table') {
                this.setAQI(slug);
              } else {
                this.setState({ changeStationLoader: false });
              }
            }
          );
        } catch (err) {
          this.setState({ changeStationLoader: false });
          updateSnackbar('Oops!! Something went wrong');
          onChangeStations(prevPropStations);
        }
      }
    );
  }

  changePollutant() {
    const { data } = this.state;
    const { onChangePollutant, updateSnackbar, pollutant } = this.props;

    if (isDataAvailable(data, pollutant)) return;

    const newPollutant = getPollutant(data);
    if (newPollutant) {
      updateSnackbar(
        `Pollutant change to ${POLLUTANTS[newPollutant]} as ${POLLUTANTS[pollutant]} don't have enough data`
      );
      onChangePollutant(newPollutant);
    }
  }

  onClickChangeStation(direction) {
    const { allStations, stations } = this.props;

    const station = getNewStation(allStations, stations, direction);
    this.addStation(station, true);
  }

  addStation = (station, changeStation = false) => {
    if (!station) return null;
    const { onChangeStations, stations, onlyOne } = this.props;
    if (stations.includes(station.slug)) return null;

    const stationsArray =
      changeStation || onlyOne ? [station.slug] : [...stations, station.slug];
    onChangeStations(stationsArray);
  };

  deleteStation = slug => {
    if (!slug) return null;
    const { onChangeStations, stations } = this.props;

    const index = stations.indexOf(slug);
    if (index === -1) return null;
    this.setState({
      data: omit(this.state.data, slug),
    });
    stations.splice(index, 1);
    onChangeStations(stations);
  };

  render() {
    const {
      classes,
      allStations,
      onChangeDuration,
      duration,
      pollutant,
      onChangePollutant,
      width,
      stations: selectedStations = [],
      embedName,
      EmbedComponent,
      onlyOne,
    } = this.props;
    const { data, changeStationLoader, settings, search } = this.state;
    const mobile = Boolean(width === 'xs');

    const earliestIndex = getEarliestIndexByData(data, allStations);

    const dataSize = calculateAllDataSize(data, pollutant);

    const TITLE = `BreeZo ${embedName}`;

    const pollutantStatus = getPollutantStatus(data);

    const embedProps = {
      data: data,
      allStations: allStations,
      selectedStations: selectedStations,
      pollutant: pollutant,
      duration: duration,
      mobile: mobile,
      chartSettings: settings,
      deleteStation: this.deleteStation,
      onChangePollutant: onChangePollutant,
    };
    return (
      <div>
        {changeStationLoader && <Loader />}
        <div
          className={classes.root}
          style={
            changeStationLoader
              ? { pointerEvents: 'none', opacity: 0.4 }
              : { opacity: 1 }
          }
        >
          <div className={classes.metaInfo}>
            <div className={classes.title}>{TITLE}</div>
            <div className={classes.ranges}>
              <PollutantRanges pollutant={pollutant} />
            </div>
          </div>
          {selectedStations.length < 3 && (
            <div className={classes.locationSelector}>
              <LocationSelector
                onChange={this.addStation}
                station={Object.keys(data)}
                mobile={onlyOne || false}
                allStations={allStations}
                goToPrevStation={() => this.onClickChangeStation('prev')}
                goToNextStation={() => this.onClickChangeStation('next')}
                search={search}
                googleEvent={({ action }) => {
                  UserEvent({ action: action, label: 'embed search' });
                }}
                setSearch={this.setSearch}
              />
            </div>
          )}
          <div className={classes.controllers}>
            <div>
              <PollutantSelector
                pollutantStatus={pollutantStatus}
                pollutant={pollutant || 'pm25'}
                onChange={val => onChangePollutant(val)}
              />
            </div>
            <div className={classes.durationSelector}>
              <DurationSelector
                mobile={mobile}
                duration={duration || '1d'}
                earliestIndex={earliestIndex}
                onChange={(e, val) => onChangeDuration(val)}
              />
            </div>
            {embedName === 'Time series graph' && (
              <div className={classes.settings}>
                <Settings
                  onChange={settingObj => {
                    this.setState({ settings: settingObj });
                  }}
                />
              </div>
            )}
          </div>

          <div className={classes.embed}>
            {Boolean(dataSize) ? (
              <EmbedComponent {...embedProps} />
            ) : (
              <div className={classes.noData}>
                <img
                  src={NoData}
                  width="30%"
                  height="30%"
                  alt="No data available"
                />
                Not enough data!!!
              </div>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ locations }) => ({
  allStations: locations,
});

export default compose(
  addUrlProps({ urlPropsQueryConfig }),
  connect(mapStateToProps, {
    updateSnackbar,
  }),
  withStyles(styles),
  withTranslation(),
  withWidth(),
  withRouter
)(EmbedCommon);
