import { pollutantOptions as POLLUTANTS } from 'common/config';
function generatePollutantStatus() {
  return Object.keys(POLLUTANTS).map(key => {
    return {
      key: true,
    };
  });
}

function getStationMeta(selectedStations, allStations) {
  return selectedStations.reduce((acc, slug) => {
    const stationMeta = allStations.find(
      ({ slug: stationSlug }) => stationSlug === slug
    );
    return Object.assign(acc, { [slug]: { meta: stationMeta } });
  }, {});
}

export { generatePollutantStatus, getStationMeta };
