import { uniqBy } from 'lodash';
import { getStations } from './geolocation';
// import { envOptions } from '../config';
// import Axios from 'axios';
// import queryString from 'query-string';
// const { root_URL } = envOptions;

const getTopPollutedStations = async pollutant => {
  const res = await getStations();
  return { data: uniqBy(res.data, 'slug').slice(0, 9) };

  // TODO: Enable once api is ready
  // const qs = queryString.stringify({ filter: 'top' });
  // return Axios.get(`${root_URL}/api/insight/list/${pollutant}?${qs}`);
};

export { getTopPollutedStations };
