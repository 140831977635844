import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import { durations as DURATIONS } from 'common/config';
import { Auth0Context } from 'auth/auth0';

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: 'unset',
  },
  tabRoot: {
    minWidth: 'unset',
    minHeight: 'unset',
    padding: '1rem',
    color: 'rgba(0,0,0,0.7)',
    '&.tabRootSelected': {
      color: '#000',
    },
  },
  disable: {
    opacity: 0.5,
  },
  flexContainer: {
    justifyContent: 'space-between',
  },
  tabsIndicator: {
    backgroundColor: '#000',
    transition: 'all 600ms cubic-bezier(0.4,0,0.2,1) 0ms',
  },
  regDashboardTabRoot: {
    minWidth: 'unset',
    minHeight: 'unset',
    color: 'rgba(0,0,0,0.7)',
    '&.tabRootSelected': {
      color: '#000',
    },
  },
  regDashboardFlexContainer: {
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: '-5px',
  },
  [theme.breakpoints.down('lg')]: {
    regDashboardTabRoot: {
      padding: '6px 5px',
      fontSize: '0.8rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    regDashboardTabRoot: {
      padding: 10,
      fontSize: 12,
    },
  },
}));

export default function DisabledTabs({
  duration,
  onChange,
  earliestIndex,
  enableRegDashboardClass,
}) {
  const classes = useStyles();
  const { isAuthenticated } = useContext(Auth0Context);

  return (
    <Fade cascade>
      <Tabs
        value={duration}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChange}
        classes={{
          root: classes.tabsRoot,
          flexContainer: enableRegDashboardClass
            ? classes.regDashboardFlexContainer
            : classes.flexContainer,
          indicator: classes.tabsIndicator,
        }}
        centered={true}
      >
        {DURATIONS.map(({ label, value, title, isPrivate }, index) => {
          const notAllowedPrivateDurations = !isAuthenticated && isPrivate;
          const isDisabledDuration =
            index > earliestIndex || notAllowedPrivateDurations;
          return (
            <Tab
              classes={{
                root: enableRegDashboardClass
                  ? classes.regDashboardTabRoot
                  : classes.tabRoot,
                selected: 'tabRootSelected',
                disabled: classes.disable,
              }}
              disableRipple
              disableFocusRipple
              key={index}
              label={duration === value ? title : label}
              value={value}
              disabled={isDisabledDuration}
            />
          );
        })}
      </Tabs>
    </Fade>
  );
}
