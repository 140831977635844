import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';

// import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import { UserEvent } from './ga-events';
import { updateSnackbar } from 'common/store/actions';

const styles = theme => ({
  shareIcon: {
    height: 40,
    width: 40,
  },
  shareButton: {
    margin: 8,
    cursor: 'pointer',
  },
  icon: {
    color: '#C8C8C8',
  },
  copyIcon: {
    fontSize: 19,
    margin: '0 8px',
    padding: 6,
    background: '#d4d4d4',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  dropdown: {
    marginTop: 5,
  },
  [theme.breakpoints.down('sm')]: {
    shareIcon: {
      height: 20,
      width: 20,
      margin: '0 8px',
    },
    dropdown: {
      marginTop: 20,
    },
  },
  [theme.breakpoints.up('md')]: {
    paddingFix: {
      padding: '12px',
    },
  },
});

// TODO: Refactor to hooks and simplify
class ShareSocial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    // this.showCopyClipboardAlert = this.showCopyClipboardAlert.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  // showCopyClipboardAlert() {
  //   const message = `Copied to clipboard - ${getIframeEmbed()
  //     .toString()
  //     .substring(0, 15)}...`;
  //   this.props.updateSnackbar(message);
  //   this.setState({
  //     open: false,
  //   });
  // }

  handleToggle() {
    if (!this.state.open) {
      UserEvent({
        action: 'Clicked on share button',
      });
    }
    this.setState(state => ({ open: !state.open }));
  }

  handleClose(event) {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  }

  render() {
    const shareUrl = document.location.href;
    const { classes } = this.props;
    const { open } = this.state;
    // const embedURL = getIframeEmbed();
    const title = document.title;

    return (
      <>
        <Tooltip title="Share" placement="left">
          <IconButton
            data-tut="tour_share"
            buttonRef={node => {
              this.anchorEl = node;
            }}
            onClick={this.handleToggle}
            className={(classes.shareIcon, classes.paddingFix)}
          >
            <ShareIcon className={classes.icon} />
          </IconButton>
        </Tooltip>

        <Popper open={open} anchorEl={this.anchorEl} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              className={classes.dropdown}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper elevation={2}>
                <ClickAwayListener
                  onClick={this.handleClose}
                  onClickAway={this.handleClose}
                >
                  <MenuList>
                    <FacebookShareButton
                      className={classes.shareButton}
                      url={shareUrl}
                      quote={title}
                      onClick={() => {
                        UserEvent({
                          action: 'shared to facebook',
                        });
                      }}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <LinkedinShareButton
                      className={classes.shareButton}
                      url={shareUrl}
                      quote={title}
                      onClick={() => {
                        UserEvent({
                          action: 'shared to linkdein',
                        });
                      }}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>

                    <TwitterShareButton
                      className={classes.shareButton}
                      url={shareUrl}
                      quote={title}
                      onClick={() => {
                        UserEvent({
                          action: 'shared to twitter',
                        });
                      }}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>

                    <WhatsappShareButton
                      className={classes.shareButton}
                      url={shareUrl}
                      title={title}
                      separator=":: "
                      onClick={() => {
                        UserEvent({
                          action: 'shared to whatsapp',
                        });
                      }}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>

                    {/* {showEmbed && (
                      <CopyToClipboard
                        text={embedURL}
                        onCopy={this.showCopyClipboardAlert}
                      >
                        <FileCopyIcon className={classes.copyIcon} />
                      </CopyToClipboard>
                    )} */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}

export default compose(
  connect(null, { updateSnackbar }),
  withStyles(styles, { withTheme: true })
)(ShareSocial);
