import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { getLastKnownValue } from '../../utils/chart-utils';
import { pollutantOptions as Pollutants } from 'common/config';
import { getLevel } from 'common/utils';
import Fade from 'react-reveal/Fade';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DialogWrapper from '../dialog-wrapper';
import { PollutantSelectors } from './dialog-content';

const tableStyles = makeStyles(theme => ({
  cards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: 16,
    gridRowGap: 16,
  },
  pollutantCard: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '10px',
    },
  },
  cardHeading: {
    fontSize: 12,
    fontWeight: 500,
  },
  cardValue: {
    fontSize: 40,
  },
  cardUnit: {
    color: '#C8C8C8',
    fontSize: 10,
  },
  activePollutant: {
    background: '#ffffff',
    boxShadow: 'none',
    overflow: 'inherit',
    position: 'relative',
  },
  pollutant: {
    background: '#f4f4f4',
    boxShadow: 'none',
    overflow: 'inherit',
    position: 'relative',
    border: '2px solid #f4f4f4',
  },
  infoIcon: {
    position: 'absolute',
    top: '-10px',
    right: '3px',
  },
}));

export default function MobilePollutantCards(props) {
  const { data, changePollutant, pollutant, googleEvent } = props;
  const [pollutantInfo, setPollutantInfo] = useState(false);

  const pollutantData = Object.keys(Pollutants).map(key => {
    const lastValue = getLastKnownValue(data.data, key);
    return {
      key,
      label: Pollutants[key],
      val: lastValue ? lastValue[key] : '-',
    };
  });

  const classes = tableStyles();

  return (
    <>
      <div className={classes.cards}>
        {pollutantData.map(({ key, val, label }) => (
          <Fade cascade>
            <Card
              onClick={() => {
                changePollutant(key);
                googleEvent({
                  action: 'changed pollutant',
                  label: 'mobile',
                });
              }}
              key={key}
              className={
                pollutant === key ? classes.activePollutant : classes.pollutant
              }
              style={
                pollutant === key
                  ? {
                      background: fade(getLevel(val, key).color, 0.2),
                      border: `2px solid ${getLevel(val, key).color}`,
                    }
                  : { opacity: 0.4 }
              }
            >
              <CardContent
                className={classes.pollutantCard}
                style={pollutant !== key ? { opacity: 0.5 } : { opacity: 1 }}
              >
                <div
                  className={classes.cardHeading}
                  style={{ color: getLevel(val, key).color }}
                >
                  {label}
                </div>
                <div
                  style={{
                    color: getLevel(val, key).color,
                  }}
                  className={classes.cardValue}
                  color="textSecondary"
                >
                  {val}
                </div>
                <div
                  className={classes.cardUnit}
                  style={{ color: getLevel(val, key).color }}
                >
                  ug/m3
                </div>
              </CardContent>
              <InfoRoundedIcon
                style={
                  pollutant !== key
                    ? { opacity: 0.5, color: getLevel(val, key).color }
                    : { opacity: 1, color: getLevel(val, key).color }
                }
                className={classes.infoIcon}
                onClick={() => setPollutantInfo(true)}
              />
            </Card>
          </Fade>
        ))}
      </div>
      <DialogWrapper
        open={pollutantInfo}
        onClose={() => setPollutantInfo(false)}
        title={'Pollutant'}
      >
        <PollutantSelectors pollutant={pollutant} />
      </DialogWrapper>
    </>
  );
}
