import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { LocationSelector, LocationSelectorMobile } from './index';
import { ReactComponent as PlayStore } from '../../assets/icons/playstore.svg';
import TextLoop from 'react-text-loop';
import { ReactComponent as Breezo } from '../../assets/icons/breezo-blue.svg';
import { withRouter } from 'react-router';
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { useTheme } from '@material-ui/core/styles';
import Fade from 'react-reveal/Fade';
import { UserEvent } from '../ga-events';
import { Button } from '@material-ui/core';
import Code from '@material-ui/icons/Code';
import { URLS } from 'common/config';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: '#fff no-repeat center',
    color: 'black',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    margin: '0 10% 0 10%',
    width: '100%',
    marginTop: '-30%',
  },
  title: {
    fontWeight: 600,
    marginBottom: '0.3rem',
    color: '#140CF2',
    textDecoration: 'none',
  },
  title2: {
    fontSize: '0.95rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '1rem',
  },
  socialLinks: {
    display: 'flex',
    width: '6rem',
    justifyContent: 'space-between',
  },
  breezoIcon: {
    width: 'auto',
    height: 150,
    position: 'relative',
    left: -28,
    top: 20,
  },
  subtitle: {
    fontSize: '0.8rem',
    fontWeight: 400,
    textAlign: 'right',
  },
  search: {
    position: 'relative',
    color: '#C8C8C8',
    height: 40,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    marginTop: 20,
    width: '100%',
    display: 'flex',
  },
  iconDiv: {
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    position: 'absolute',
    bottom: '4%',
    fontSize: '0.8rem',
    fontWeight: 400,
  },
  iconText: {
    display: 'none',
    padding: '3px 10px 0 10px',
    fontSize: '12px',
  },
  belowSearch: {
    display: 'flex',
    marginTop: '1.5rem',
    justifyContent: 'space-between',
  },
  playIcon: {
    marginRight: '0.35rem',
    cursor: 'pointer',
    fillOpacity: '0.5',
    '&:hover': {
      fillOpacity: '1',
      transition: 'all ease-in 0.3s',
    },
  },
  appleIcon: {
    marginRight: '0.35rem',
    cursor: 'pointer',
    fillOpacity: '0.5',
    '&:hover': {
      fillOpacity: '1',
      transition: 'all ease-in 0.3s',
    },
  },
  linkedinIcon: {
    color: 'black',
    '&:hover': {
      color: '#0077B5',
      transition: 'all ease-in 0.3s',
    },
  },
  twitterIcon: {
    color: 'black',
    '&:hover': {
      color: '#1DA1F2',
      transition: 'all ease-in 0.3s',
    },
  },
  instagramIcon: {
    color: 'black',
    '&:hover': {
      color: '#F16F21',
      transition: 'all ease-in 0.3s',
    },
  },

  blueSkyIcon: {
    fontSize: 'inherit',
    color: 'black',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
      transition: 'all ease-in 0.3s',
    },
  },
  locationSelectorMobile: {
    display: 'none',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    container: {
      margin: '0 10% 0 10%',
      marginTop: '-30%',
    },
    footer: {
      bottom: '20px',
      top: XSmallHeight => (XSmallHeight ? '530px' : ''),
      width: '80%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    locationSelectorMobile: {
      display: 'flex',
    },
    aboveSearchBar: {
      textAlign: 'center',
    },
    root: {
      height: '94vh',
      paddingTop: '0vh',
    },
    subtitle: {
      fontSize: 12,
    },
    container: {
      margin: '0 5% 0 5%',
      marginTop: '-30%',
    },
    title: {
      fontWeight: 400,
    },
    title2: {
      fontSize: '0.8rem',
      textAlign: 'left',
    },
    breezoIcon: {
      width: 'auto',
      height: 120,
      left: -24,
    },
    footer: {
      width: '90%',
      position: 'absolute',
      fontSize: '0.8rem',
      fontWeight: 400,
    },
  },
  [theme.breakpoints.down(350)]: {
    title2: {
      fontSize: '0.7rem',
    },
  },
}));

function addStation(e, props) {
  const path = `/chart?duration=1d&pollutant=pm25&stations=${e.slug}`;
  props.history.push(path);
}

function Redirect(goto, result, props) {
  props.redirect(goto, result);
}

function NewLeftHome(props) {
  const locations = useSelector(state => state.locations);
  const XSmallHeight = useMediaQuery('(max-height:400px)');
  const classes = useStyles(XSmallHeight);
  const movingText = [
    'Parents',
    'Doctors',
    'Teachers',
    'Researchers',
    'Journalists',
    'Policy experts',
  ];
  const [search, setSearch] = useState(false);
  const theme = useTheme();
  const matchMax = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <div
        className={classes.root}
        style={search ? { overflowY: 'hidden' } : { overflowY: 'auto' }}
      >
        <div className={classes.container}>
          {locations !== undefined && (
            <LocationSelectorMobile
              className={classes.locationSelectorMobile}
              onChange={e => addStation(e, props)}
              allStations={locations}
              redirect={(goto, result) => Redirect(goto, result, props)}
              search={search}
              googleEvent={({ action }) => {
                UserEvent({ action: action, label: 'home page search' });
              }}
              setSearch={setSearch}
              mobile={matchMax}
            />
          )}

          <Fade cascade>
            <div>
              <a href="/" style={{ textDecoration: 'none' }}>
                <Breezo className={classes.breezoIcon} />
              </a>

              <div className={classes.title2}>
                <span> India’s GO-TO air quality app for &nbsp;</span>
                <TextLoop speed="250" style={{ top: '-2px' }}>
                  {movingText.map((data, index) => (
                    <span key={index}>
                      <strong>{data}</strong>
                    </span>
                  ))}
                </TextLoop>
              </div>
            </div>
          </Fade>

          <Fade cascade>
            <div className={classes.search}>
              {locations !== undefined && (
                <LocationSelector
                  onChange={e => addStation(e, props)}
                  allStations={locations}
                  redirect={(goto, result) => Redirect(goto, result, props)}
                  search={search}
                  googleEvent={({ action }) => {
                    UserEvent({ action: action, label: 'home page search' });
                  }}
                  setSearch={setSearch}
                />
              )}
            </div>
            <div className={classes.belowSearch}>
              <Typography className={classes.subtitle}>
                Powered by{' '}
                <a
                  href="https://blueskyhq.io/"
                  target="_blank"
                  onClick={() => {
                    UserEvent({
                      action: 'clicked on bluesky link',
                    });
                  }}
                  rel="noopener noreferrer"
                  className={classes.blueSkyIcon}
                >
                  Blue Sky Analytics
                </a>
              </Typography>
              <div className={classes.iconDiv}>
                <a
                  href="https://play.google.com/store/apps/details?id=dev.breezo.in"
                  target="_blank"
                  onClick={() => {
                    UserEvent({
                      action: 'clicked on mobile app link',
                      label: 'in home page',
                    });
                  }}
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <PlayStore
                    width="22px"
                    height="22px"
                    className={classes.playIcon}
                  />
                </a>
              </div>
            </div>
            <br />
            <a
              rel="noopener noreferrer"
              href={URLS['breezo-about']}
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Button
                style={{ textTransform: 'capitalize' }}
                variant="contained"
                disableElevation
                color="primary"
                startIcon={<Code />}
              >
                Get API Access
              </Button>
            </a>
          </Fade>

          <Fade cascade>
            <div className={classes.footer}>
              <div>
                <p className={classes.header}>Write to us</p>
                <a
                  href="mailto:support@blueskyhq.io"
                  target="_blank"
                  onClick={() => {
                    UserEvent({
                      action: 'click at write to use',
                      label: 'home page',
                    });
                  }}
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  support@blueskyhq.io
                </a>
              </div>
              <div>
                <p className={`${classes.header} ${classes.connect}`}>
                  Connect
                </p>
                <div className={classes.socialLinks}>
                  <a
                    href="https://www.linkedin.com/company/blueskyhq/"
                    target="_blank"
                    onClick={() => {
                      UserEvent({
                        action: 'clicked on linkedin link',
                        label: 'social icons',
                      });
                    }}
                    rel="noopener noreferrer"
                  >
                    <Linkedin
                      width="18px"
                      height="18px"
                      className={classes.linkedinIcon}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/blue.sky.revolution/"
                    target="_blank"
                    onClick={() => {
                      UserEvent({
                        action: 'clicked on Instagram link',
                        label: 'social icons',
                      });
                    }}
                    rel="noopener noreferrer"
                  >
                    <Instagram
                      width="18px"
                      height="18px"
                      className={classes.instagramIcon}
                    />
                  </a>
                  <a
                    href="https://twitter.com/blueskylab"
                    target="_blank"
                    onClick={() => {
                      UserEvent({
                        action: 'clicked on twitter link',
                        label: 'social icons',
                      });
                    }}
                    rel="noopener noreferrer"
                  >
                    <Twitter
                      width="18px"
                      height="18px"
                      className={classes.twitterIcon}
                    />
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
export default withRouter(NewLeftHome);
