import React from 'react';
import PopularComparisonCard from './comparison-card';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { compareList } from 'common/config';

const COMPARELIST = process.env.REACT_APP_API_URL.includes('.dev')
  ? compareList.development
  : compareList.production;

const useStyles = makeStyles(theme => ({
  divMargin: {
    marginBottom: '1.5rem',
  },
}));

export default function RenderPopularComparison({ favourites, googleEvent }) {
  const classes = useStyles();
  const locations = useSelector(state => state.locations);
  let comparisonData = null;
  let uniqueFav = [];

  if (favourites.length)
    uniqueFav = favourites.filter(
      fav => COMPARELIST[0].indexOf(fav.slug) === -1
    );

  const cards = COMPARELIST[0].map((station, index) => {
    const stationVal = uniqueFav.length
      ? uniqueFav[0].slug
      : COMPARELIST[1][index];

    comparisonData = {
      stationA: locations.filter(location => location.slug === station)[0],
      stationB: locations.filter(location => location.slug === stationVal)[0],
    };

    return (
      <Grid item key={station} sm={4} xs={12}>
        <div className={classes.divMargin}>
          <PopularComparisonCard
            comparisonData={comparisonData}
            googleEvent={googleEvent}
          />
        </div>
      </Grid>
    );
  });
  return (
    <div>
      <Grid spacing={1} container>
        {cards}
      </Grid>
    </div>
  );
}
