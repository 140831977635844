import {
  pollutantRangeReference,
  pollutantLabelColorReference,
} from '../config';

/**
 *
 * So if the references are not provided then it uses it's default on,
 * but we do have option to provide ours, thus partially
 * black boxing the functions & keeping it dependency free
 */
const getLevel = (
  value,
  parameter,
  pollutantRange = pollutantRangeReference,
  pollutantColorLabel = pollutantLabelColorReference
) => {
  if (
    !Object.keys(pollutantRange).includes(parameter) ||
    isNaN(parseFloat(value))
  ) {
    return {
      label: 'NA',
      color: '#000',
    };
  }

  //TODO: Comment the working of function here
  for (const index in pollutantRange[parameter]) {
    const val = pollutantRange[parameter][index];
    if (val >= value) {
      return +index === 0
        ? pollutantColorLabel[0]
        : pollutantColorLabel[index - 1];
    }
  }

  return pollutantColorLabel.slice(-1)[0];
};

export default getLevel;
