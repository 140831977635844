import React from 'react';
import EmbedHoc from '../hoc/search-bar-hoc';
import HeatMap from '../chart/heatmap';
import Dropdown from '../chart/year-selector';
import { Loader } from '../loaders';
import { makeStyles } from '@material-ui/core/styles';
import { PollutantSelector } from '../chart';
import { generatePollutantStatus, getStationMeta } from './embed-utils';
import { YEARS } from 'common/config';

const useStyles = makeStyles(theme => ({
  controllers: {
    margin: '1rem 0',
    marginBottom: '-1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heatmaps: {
    width: '100%',
    marginBottom: 60,
  },
  [theme.breakpoints.down('xs')]: {
    heatmaps: {
      display: 'flex',
      width: '100%',
      overflowX: 'auto',
      minHeight: 1000,
    },
  },
}));

const Embed = props => {
  const {
    pollutant,
    allStations,
    selectedStations,
    deleteStation,
    onChangePollutant,
  } = props;

  const classes = useStyles();

  const data = getStationMeta(selectedStations, allStations);
  const [loader, setLoader] = React.useState(false);

  const [year, changeYear] = React.useState(YEARS[0]);
  const years = YEARS.map(key => ({ label: key, key }));
  const pollutantStatus = generatePollutantStatus();
  return (
    <>
      {loader && <Loader />}

      <div
        style={{
          height: '100%',
          width: '100%',
          opacity: loader ? 0.3 : 1,
          pointerEvents: loader ? 'none' : '',
        }}
      >
        <div className={classes.controllers}>
          <div className={classes.pollutantSelector}>
            <PollutantSelector
              pollutant={pollutant || 'pm25'}
              onChange={val => onChangePollutant(val)}
              pollutantStatus={pollutantStatus}
            />
          </div>
          <div className={classes.durationSelector}>
            <Dropdown selected={year} options={years} onChange={changeYear} />
          </div>
        </div>
        <div className={classes.heatmaps}>
          {Object.keys(data).map(slug => {
            return (
              <div>
                <div key={slug} style={{ margin: '4rem 0' }}>
                  <HeatMap
                    isEmbed={true}
                    multi={Object.keys(data).length > 1}
                    deleteStation={deleteStation}
                    year={year}
                    loaderStatus={status => setLoader(status)}
                    stations={data[slug].meta}
                    pollutant={pollutant}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EmbedHoc(Embed, 'Calender Heatmaps');
