import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../components/loaders';
import { Header, Footer } from '../layout';
import Selector from '../components/temporal-accuracy/selector';
import JsonHuman from 'json.human';
import { envOptions } from 'common/config';
import Axios from 'axios';
import { Auth0Context } from '../auth/auth0';

const { root_URL } = envOptions;

const APIs = {
  cache: {
    label: 'Redis Info',
    url: `${root_URL}/info/cache`,
  },
  'hit-count': { label: 'API Hit count', url: `${root_URL}/v1/count` },
  database: {
    label: 'Database size & rows of various tables',
    url: `${root_URL}/info/db`,
  },
  'continuous-aggregate': {
    label: 'Stats about Continous Aggregates ',
    url: `${root_URL}/info/cont-agg`,
  },
};

const DEFAULT_API = Object.keys(APIs)[0];

const useStyles = makeStyles(theme => ({
  root: {
    margin: '2rem 10rem 2rem',
  },
  jsonDiv: {
    background: '#EFF0F1',
    marginTop: '1rem',
    padding: '20px',
    width: '100%',
    minHeight: 500,
  },
}));

export default function ViewStats() {
  const classes = useStyles();
  const [response, setResponse] = useState({});
  const [api, setApi] = useState(DEFAULT_API);
  const [loader, setLoader] = useState(true);
  const { authToken } = useContext(Auth0Context);

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        const { data } = await Axios.get(APIs[api].url, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        setResponse(data);
        setLoader(false);
      } catch (err) {
        setResponse(err);
        setLoader(false);
      }
    })();
  }, [api, authToken]);

  useEffect(() => {
    //Get html version of json data
    const htmlVersion = JsonHuman.format(response);
    const ele = document.getElementById('json'); //json of is the id of container div
    if (ele) {
      //get all child nodes
      const child = ele.childNodes;
      //remove child if already exist
      if (child && typeof child[0] === 'object') ele.removeChild(child[0]);
      //append to direct child
      ele.appendChild(htmlVersion);
    }
  }, [response]);

  return (
    <>
      <Header />
      <div
        className={classes.root}
        style={
          loader ? { pointerEvents: 'none', opacity: 0.4 } : { opacity: 1 }
        }
      >
        {loader && <Loader />}
        <Selector
          allParameters={APIs}
          param={api}
          onChange={key => setApi(key)}
        />
        <div id="json" className={classes.jsonDiv}></div>
      </div>
      <Footer />
    </>
  );
}
