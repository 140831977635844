import { NewLeftHome } from '../components/home/index';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { ReactComponent as UnderConstruction } from '../assets/images/under-construction.svg';

// TODO: Reduce the CSS and combine the media queries

const useStyles = makeStyles(theme => ({
  redirectScreen: {
    background: 'white',
    fontSize: '2rem',
    fontColor: 'black',
    fontWeight: 300,
    textAlign: 'center',
    paddingTop: '30vh',
    verticalAlign: 'center',
  },
  right: {
    width: '65vw',

    textAlign: 'center',

    marginLeft: '35vw',
    marginTop: '20vh',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      marginLeft: 0,
    },
  },
  left: {
    width: '35vw',
    position: 'fixed',
    zIndex: 5,
    top: 0,
    boxShadow: ' 0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1)',

    [theme.breakpoints.between('xs', 'md')]: {
      width: '100vw',
      position: 'relative',
      zIndex: 0,
      paddinBottom: 0,
      height: '100%',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      position: 'relative',
      zIndex: 0,
      paddinBottom: 0,
      minHeight: '600px',
      height: '100vh',
      boxShadow: 'none',
    },
  },
  container: {
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  footer: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    marginLeft: 0,
  },
}));

function Redirect(path, station, props) {
  setTimeout(() => {
    props.history.push(path);
  }, 1500);
}

function ComingSoon(props) {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  // TODO: Why k?
  const [nearStation, setStation] = useState('k');
  return (
    <div>
      {redirect === true ? (
        <div className={classes.redirectScreen}>
          <p> You are being redirected to {nearStation} station</p>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.left}>
            <NewLeftHome
              redirect={(path, station) => {
                setRedirect(true);
                setStation(station.name);
                Redirect(path, station, props);
              }}
            />
            <hr />
          </div>

          <div className={classes.right}>
            <div>
              <p style={{ fontSize: '2rem' }}>
                {' '}
                Awesome visualizations under construction
              </p>
              <UnderConstruction heigt="200px" width="200px" />
              <p style={{ fontSize: '1rem', color: '#818181' }}>
                Please forgive the inconvenience and check out the rest of the
                App.
              </p>
              <p style={{ fontSize: '1rem', color: '#818181' }}>
                It's okay, we're excited too!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(ComingSoon);
