import ReactGA from 'react-ga';

const analytics = ({ getState, dispatch }) => next => action => {
  if (action.meta && action.meta.analytics) {
    ReactGA.event({ category: 'UI Change', action: action.meta.analytics });
  }

  next(action);
};

export default analytics;
