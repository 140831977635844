import { cloneDeep } from 'lodash';

const colors = ['#7EB4D2', '#FC9AF9', '#3363F2', '#F2CC2D', '#976CF9'];

const mapbox_token =
  'pk.eyJ1Ijoia3NoaXRpai1icmVlem8iLCJhIjoiY2syNGd1bGxwMGR1bzNxcDl1ZXQ5eGJ2eCJ9.Sfgg_VTeiRS4qCwDEPyYkA';

const Embeds = {
  table: 'Table',
  'timeseries-graph': 'Time series graph',
  heatmap: 'Calender Heatmaps',
  distribution: 'Distribution Pie-chart',
  bar: 'Mean & EXposure',
  radar: 'Radar graph',
  map: 'Map',
};

const visibleForecastings = {
  '1d': {
    visible: ['1h', '3h'],
    difference: {
      value: 1,
      unit: 'hour',
    },
  },
  '3d': {
    visible: ['3h', '6h', '1d'],
    difference: {
      value: 3,
      unit: 'hour',
    },
  },
  '1w': {
    visible: ['3h', '6h', '1d'],
    difference: {
      value: 3,
      unit: 'hour',
    },
  },
};

const pollutantOptions = {
  // pm1: 'PM 1',
  pm25: 'PM 2.5',
  pm10: 'PM 10',
  so2: 'SO2',
  // no: "Nitrogen Monoxide",
  nox: 'NOX',
  // no2: "Nitrogen Dioxide",
  o3: 'O3',
  // co2: 'Carbon Dioxide',
  co: 'CO',
  // nh3: 'Ammonia',
  // benzene: 'Benzene',
  // xylene: 'Xylene',
  // toluene: 'Toluene',
};

const models = {
  '1h': {
    label: '1 hour model',
  },
  '3h': {
    label: '3 hour model',
  },
  '6h': {
    label: '6 hour model',
  },
  '1d': {
    label: '1 day model',
  },
};

const pollutantFullNames = {
  // pm1: 'PM 1',
  pm25: 'Particulate matter 2.5',
  pm10: 'Particulate matter 10',
  so2: 'Sulpher dioxide',
  // no: "Nitrogen Monoxide",
  nox: 'Nitrogen oxides',
  // no2: "Nitrogen Dioxide",
  o3: 'Ozone',
  // co2: 'Carbon Dioxide',
  co: 'Carbon monoxide',
  // nh3: 'Ammonia',
  // benzene: 'Benzene',
  // xylene: 'Xylene',
  // toluene: 'Toluene',
};
const availablePollutants = (() => {
  const extraPollutants = [
    'so2',
    'nox',
    'benzene',
    'co2',
    'no',
    'temperature',
    'wind_direction',
    'nh3',
  ];
  return Object.keys(pollutantOptions)
    .filter(key => !extraPollutants.includes(key))
    .reduce((obj, key) => {
      obj[key] = pollutantOptions[key];
      return obj;
    }, {});
})();

const units = {
  pm25: 'µg/m3',
  pm10: 'µg/m3',
  so2: 'µg/m3',
  no2: 'µg/m3',
  nox: 'µg/m3',
  benzene: 'µg/m3',
  o3: 'µg/m3',
  nh3: 'µg/m3',
  co: ' mg/m3',
  co2: 'µg/m3',
  no: ' µg/m3',
  temperature: '°C',
};

const BANDS = [
  {
    label: 'Good',
    color: '#009900',
    range: {
      pm25: 30,
      pm10: 50,
      so2: 40,
      no: 40,
      nox: 40,
      no2: 40,
      o3: 50,
      co: 1,
      aqi: 50,
    },
  },
  {
    label: 'Satisfactory',
    color: '#59B300',
    range: {
      pm25: 60,
      pm10: 100,
      so2: 80,
      no: 80,
      nox: 80,
      no2: 80,
      o3: 100,
      co: 2,
      aqi: 100,
    },
  },
  {
    label: 'Moderate',
    color: '#DFDF31',
    range: {
      pm25: 90,
      pm10: 250,
      so2: 380,
      no: 180,
      nox: 180,
      no2: 180,
      o3: 168,
      co: 10,
      aqi: 200,
    },
  },
  {
    label: 'Poor',
    color: '#E68A00',
    range: {
      pm25: 120,
      pm10: 350,
      so2: 800,
      no: 280,
      nox: 280,
      no2: 280,
      o3: 280,
      co: 17,
      aqi: 300,
    },
  },
  {
    label: 'Very Poor',
    color: '#E82C0C',
    range: {
      pm25: 250,
      pm10: 430,
      so2: 1600,
      no: 400,
      nox: 400,
      no2: 400,
      o3: 400,
      co: 34,
      aqi: 400,
    },
  },
  {
    label: 'Severe',
    color: '#A52A2A',
    range: {
      pm25: 1000,
      pm10: 1000,
      so2: 2000,
      no: 1000,
      nox: 1000,
      no2: 1000,
      o3: 1000,
      co: 50,
      aqi: 500,
    },
  },
];

const getRangeWithLabel = (() => {
  const bandsArray = cloneDeep(BANDS);

  return bandsArray.map((value, i) => {
    for (const prop in value['range']) {
      value['range'][prop] = [
        i === 0 ? 0 : bandsArray.slice()[i - 1]['range'][prop][1],
        value['range'][prop],
      ];
    }
    return value;
  });
})();

// TODO: Remove these from everywhere
const pollutantLabelColorReference = [
  { label: 'Good', color: '#009900' },
  { label: 'Satisfactory', color: '#59B300' },
  { label: 'Moderate', color: '#DFDF31' },
  { label: 'Poor', color: '#E68A00' },
  { label: 'Very Poor', color: '#E82C0C' },
  { label: 'Severe', color: '#A52A2A' },
];

// TODO: Verify the max value for each pollutant
const pollutantRangeReference = {
  pm25: [0, 30, 60, 90, 120, 250, 1000],
  pm10: [0, 50, 100, 250, 350, 430, 1000],
  so2: [0, 40, 80, 380, 800, 1600, 2000],
  no: [0, 40, 80, 180, 280, 400, 1000],
  nox: [0, 40, 80, 180, 280, 400, 1000],
  no2: [0, 40, 80, 180, 280, 400, 1000],
  o3: [0, 50, 100, 168, 280, 400, 1000],
  co: [0, 1, 2, 10, 17, 34, 50],
  aqi: [0, 50, 100, 200, 300, 400, 500],
};

const durations = [
  {
    label: '1D',
    title: '24 Hours',
    value: '1d',
    unit: 'hour',
    format: 'h:mm A dddd',
    timeBucket: '1h',
    ticks: {
      desktop: {
        count: 5,
        format: 'h A, ddd',
      },
      mobile: {
        count: 4,
        format: 'h A ddd',
      },
    },
  },
  {
    label: '3D',
    title: '3 Days',
    value: '3d',
    unit: 'hour',
    format: 'h:mm A dddd',
    timeBucket: '12h',
    ticks: {
      desktop: {
        count: 5,
        format: 'ddd - h A',
      },
      mobile: {
        count: 3,
        format: 'DD, ddd',
      },
    },
  },
  {
    label: '1W',
    title: '1 Week',
    value: '1w',
    unit: 'day',
    format: 'DD, ddd',
    timeBucket: '1d',
    ticks: {
      desktop: {
        count: 6,
        format: 'ddd - h A',
      },
      mobile: {
        count: 6,
        format: 'ddd',
      },
    },
  },
  {
    label: '1M',
    title: '1 Month',
    value: '1m',
    unit: 'day',
    format: 'DD, MMMM',
    timeBucket: '1d',
    ticks: {
      desktop: {
        count: 5,
        format: 'DD, MMMM',
      },
      mobile: {
        count: 5,
        format: 'DD, MMM',
      },
    },
  },
  {
    label: '3M',
    title: '3 Months',
    value: '3m',
    unit: 'week',
    format: 'DD, MMMM',
    timeBucket: '1d',
    ticks: {
      desktop: {
        count: 5,
        format: 'DD MMM, YY',
      },
      mobile: {
        count: 4,
        format: 'MMM, YY',
      },
    },
  },
  {
    label: '6M',
    title: '6 Months',
    value: '6m',
    unit: 'week',
    format: "D, MMMM 'YY",
    timeBucket: '1m',
    ticks: {
      desktop: {
        count: 5,
        format: 'MMMM, YY',
      },
      mobile: {
        count: 5,
        format: 'MMM, YY',
      },
    },
  },
  {
    label: '1Y',
    title: '1 Year',
    value: '1y',
    unit: 'month',
    format: "D, MMMM 'YY",
    timeBucket: '1m',
    ticks: {
      desktop: {
        count: 11,
        format: 'DD MMM, YY',
      },
      mobile: {
        count: 5,
        format: 'MMM, YY',
      },
    },
  },
  {
    label: '3Y',
    title: '3 Years',
    value: '3y',
    unit: 'month',
    format: 'MMMM YYYY',
    timeBucket: '1m',
    ticks: {
      desktop: {
        count: 5,
        format: 'MMMM YYYY',
      },
      mobile: {
        count: 5,
        format: 'MMM, YY',
      },
    },
    isPrivate: true,
  },
  {
    label: 'FE',
    title: 'Forever',
    value: 'fe',
    unit: 'month',
    format: 'MMMM YYYY',
    timeBucket: '1m',
    ticks: {
      desktop: {
        count: 5,
        format: 'MMMM YYYY',
      },
      mobile: {
        count: 6,
        format: 'MMM YY',
      },
    },
    isPrivate: true,
  },
];

// To be corrected according to 8 hr mean etc
const whoLimits = {
  pm25: 25,
  pm10: 50,
  no2: 40,
  o3: 100,
  co: 40,
  nh3: 10,
  so2: 20,
};

const getDuration = val => {
  return durations.find(({ value }) => value === val);
};

//Arrays of last 4 years
const YEARS = (function () {
  return new Array(4).fill(0).map((val, i) => {
    return new Date().getFullYear() - i;
  });
})();

const getAvailabilityYear = avail => {
  let yrs = 0;

  if (avail === 'fe') yrs = 4;
  else if (avail === '3y') yrs = 3;
  else if (avail === '1y') yrs = 1;

  return new Array(yrs + 1).fill(0).map((val, i) => {
    return new Date().getFullYear() - i;
  });
};

const getCommonYearSelector = (stations, year) => {
  const finalYrs = stations
    .map(station => station.earliest)
    .map(avail => getAvailabilityYear(avail).slice(-1)[0])
    .filter(years => years <= year)
    .sort();

  return stations.length === finalYrs.length ? year : YEARS[0];
};

const envOptions = {
  root_URL: process.env.REACT_APP_API_URL || 'https://gateway.blueskyhq.in',
  sampling_factor: 3,
  default_duration: '1d',
  // The index up to which durations are allowed in the duration array
  duration_limit_index: 6,
  NEWS_LIMIT: 12,
  MAP_KEY: 'AIzaSyBfJNAO3pdoi4m0_P3qAU2j0mzarPaAciM',
  BRANCH: process.env.REACT_APP_BRANCH || 'no branch',
};

const compareList = {
  development: [
    [
      '7f766e9a-aa6a-491a-be99-2f6437682285',
      '890cf32e-13b9-41bb-b45d-4b238aa55851',
      '64e5c2c0-c9a1-46ab-b117-b0fad5a81aee',
    ],
    [
      '46426421-496e-4124-977d-50b0db0f92e7',
      '926d59f6-ce5e-4361-b39c-dcbc465c201f',
      'ed177d2a-2d85-4f39-b610-8848c584c05f',
    ],
  ],
  production: [
    [
      '2bb5851e-5251-42ba-aec8-27ca4649e8a3',
      'e38b7358-0016-4a12-9182-9da0572ebfb4',
      '9054c230-ab53-4e23-9542-541fb1bf73c0',
    ],
    [
      'fa18221c-8f3c-49cc-8435-a2f8b4d1de38',
      'f552bc3f-7cbf-4b8b-9032-a0709d549812',
      'ed8bfc5c-e1ce-4e4f-9c07-174ae5b1e9e1',
    ],
  ],
};

const URLS = {
  'breezo-about': 'https://blueskyhq.io/breezo/air-quality-datasets',
  'bsa-about': 'https://blueskyhq.io/about-us',
  faqs:
    'https://www.notion.so/breezo/BreeZo-FAQs-ea5f31f7ff5a49449da38072ad3c06b6',
  privacy: 'https://blueskyhq.io/legal#privacy',
  terms: 'https://blueskyhq.io/legal#terms',
};

export {
  mapbox_token,
  pollutantRangeReference,
  pollutantLabelColorReference,
  units,
  colors,
  BANDS,
  getRangeWithLabel,
  pollutantOptions,
  pollutantFullNames,
  models,
  getDuration,
  durations,
  whoLimits,
  availablePollutants,
  envOptions,
  Embeds,
  YEARS,
  visibleForecastings,
  compareList,
  getAvailabilityYear,
  getCommonYearSelector,
  URLS,
};
