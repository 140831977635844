import store from '../store';
import { updateAppCrash } from '../store/actions';
import Axios from 'axios';

const errorChecking = error => {
  store.dispatch(updateAppCrash(true));
};

const setConfig = config => {
  config.timeout = 30000;
  return config;
};

const setErrorHandler = error => {
  errorChecking(error);
  return Promise.reject(error);
};

const apiErrorCheck = () => {
  Axios.interceptors.response.use(
    config => config,
    error => setErrorHandler(error)
  );

  Axios.interceptors.request.use(
    config => setConfig(config),
    error => setErrorHandler(error)
  );
};

export default apiErrorCheck;
