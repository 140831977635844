import Axios from 'axios';
import { envOptions } from '../config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import queryString from 'query-string';
const { root_URL } = envOptions;
dayjs.extend(utc);

const getStations = async () => {
  let data = [];
  const qs = queryString.stringify({ duration: '1d', time_bucket: '1d' });
  const res = await Axios.get(`${root_URL}/api/bam-air-quality?${qs}`);

  res.data.forEach(i => data.push({ ...i, slug: i.monitor_id }));

  return { data: data.filter(i => i.pm25) };
};

export { getStations };
