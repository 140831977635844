import React, { useEffect, useContext } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Auth0Context } from './auth0';
import { useLocation } from 'react-router-dom';

const SecuredRoute = ({ component: Component, path, ...rest }) => {
  const location = useLocation();

  const { loading, isAuthenticated, loginWithRedirect, user } = useContext(
    Auth0Context
  );

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: `${location.pathname}${location.search}` },
      });
    };

    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path, location]);

  const render = props =>
    isAuthenticated && user ? <Component {...props} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

export default withRouter(SecuredRoute);
