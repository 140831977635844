import React from 'react';
export default function ActiveMonitorCount({ activeMonitors, total }) {
  return (
    <div>
      <span
        style={{
          color: '#140CF2',
          fontSize: '1.4rem',
          fontWeight: 600,
        }}
      >
        {activeMonitors}
      </span>
      <span style={{ color: '#C8C8C8', fontSize: '1.4rem' }}>/{total}</span>
    </div>
  );
}
