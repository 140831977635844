import React, { useState, useEffect, useRef } from 'react';
import { deburr, groupBy } from 'lodash';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { StationWrap } from '../';

function renderInput(inputProps) {
  const {
    InputProps,
    classes,
    ref,
    station,
    mobile,
    goToNextStation,
    goToPrevStation,
    inputRef,
    googleEvent,
    ...other
  } = inputProps;
  return (
    <div style={{ display: 'flex' }} className={classes.inputContainer}>
      <TextField
        InputProps={{
          inputRef: inputRef,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          startAdornment: (
            <>
              {!mobile ? (
                <AddIcon className={classes.icons} />
              ) : (
                <SearchIcon className={classes.icons} />
              )}
            </>
          ),
          disableUnderline: true,
          ...InputProps,
        }}
        {...other}
      />
      {station.length === 1 && (
        <div style={{ display: 'flex' }}>
          <div
            className={classes.changeStationArrows}
            data-tut="tour_next_prev_desktop"
          >
            <div
              style={{ marginRight: '0.5rem' }}
              className={classes.iconCenter}
            >
              <Tooltip title="Go to previous station" placement="top-end">
                <ArrowLeft
                  style={{ fontSize: '1.5rem' }}
                  cursor="pointer"
                  onClick={() => {
                    goToPrevStation();
                    googleEvent({
                      action: 'clicked on previous station',
                    });
                  }}
                />
              </Tooltip>
            </div>
            <div className={classes.iconCenter}>
              <Tooltip title="Go to next station" placement="top-start">
                <ArrowRight
                  cursor="pointer"
                  style={{ fontSize: '1.5rem' }}
                  onClick={() => {
                    goToNextStation();
                    googleEvent({
                      action: 'clicked on next station',
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        fontSize: 16,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

function getSuggestions(suggestions = [], value, { showEmpty = false } = {}) {
  // TODO: If using some unique function, comment properly
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < suggestions.length &&
          suggestion.label.toLowerCase().includes(inputValue);

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

// TODO: Rather than using `useStyles` let's do proper naming from now on.
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    overflowY: 'auto',
    maxHeight: '40vh',
  },
  iconCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputContainer: {
    backgroundColor: '#F2F2F2',
    height: '2.4rem',
    borderRadius: 8,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  changeStationArrows: {
    display: 'flex',
    margin: 'auto',
    paddingRight: '16px',
    paddingLeft: 16,
  },
  fabStyle: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 40,
    left: 'auto',
    position: 'fixed',
    zIndex: '999',
  },
  inputInput: {
    width: '10rem',
    flexGrow: 1,
    fontSize: 16,
    borderTopRightRadius: isMultiStation => (isMultiStation ? '8px' : '0'),
    borderBottomRightRadius: isMultiStation => (isMultiStation ? '8px' : '0'),
    backgroundColor: '#fff',
    '&:hover': {
      transition: 'all ease-in 0.3s',
      backgroundColor: '#F2F2F2',
    },
    padding: '0.6rem 0',
    paddingLeft: 16,
  },
  icons: {
    fontSize: 22,
    padding: '0 1rem',
  },
  fabDisplay: {
    display: 'none',
  },
  mobileDisplay: {
    display: 'none',
  },
  stationWrap: {
    padding: '0.3rem',
    width: '100%',
  },
  stationText: {
    fontSize: '1rem',
    color: 'white',
    margin: '0px',
    marginBottom: '-4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '95%',
  },
  stateText: {
    fontSize: '1rem',
    color: 'white',
    margin: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '95%',
    display: 'flex',
  },
  stateIcon: { margin: '0px 7px' },
  lineDivider: {
    width: '96%',
    height: '1.7px',
    background: 'white',
    margin: '12px 0px',
  },
  stateWrapper: {
    marginTop: '25px',
    marginBottom: '10px',
    '&:first-child': {
      marginTop: '0px',
    },
  },
  stationSpan: {
    fontSize: '0.8rem',
    opacity: '0.5',
  },
  [theme.breakpoints.down('xs')]: {
    mobileDisplay: {
      display: 'flex',
    },
    desktopDisplay: {
      display: 'none',
    },
    fabDisplay: {
      display: 'flex',
    },
    container: {
      margin: '0 auto',
      width: '90%',
      padding: '0px',
      background: 'inherit',
      backgroundAttachment: 'fixed',
    },
    locationSelectorCard: {
      width: '100%',
      height: '100%',
      background: '#0037af',
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: '111',
      overflowY: 'auto',
    },
    locationSelectorCardNoDisplay: {
      display: 'none',
    },
    inputInput: {
      padding: '15px 60px',
      color: 'white',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    changeStationArrows: {
      display: 'none',
    },
    inputContainer: {
      backgroundColor: '#ffffff40',
      borderRadius: '50px',
      height: 'auto',
    },
    icons: {
      position: 'absolute',
      fontSize: 22,
      color: 'white',
      padding: '0 2rem 0rem 1.5rem',
    },
    stationGroup: {
      display: 'block',
      color: 'white',
      alignItems: 'center',
      marginBottom: '14px',
      width: '100%',
    },
    noStation: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '50vh',
      color: 'white',
      textAlign: 'center',
    },
    noStationIcon: {
      fontSize: '7rem',
    },
    inputSticky: {
      padding: '20px 0px',
      position: 'sticky',
      top: 0,
      background: 'inherit',
      backgroundAttachment: 'fixed',
      zIndex: '999999',
    },
  },
}));

export default function IntegrationDownshift(props) {
  const {
    allStations,
    onChange,
    goToNextStation,
    goToPrevStation,
    station,
    mobile,
    search,
    setSearch,
    googleEvent,
  } = props;
  const isMultiStation = station.length > 1;
  const classes = useStyles(isMultiStation);

  const [text, setText] = useState('');
  const suggestions = allStations.map(station => ({
    ...station,
    label: `${station.name} - ${station.city} - ${station.state}`,
  }));

  const inputRef = useRef(null);

  //Reset input value inside station selector to '' after props change example station change
  useEffect(() => {
    if (search) inputRef.current.focus();

    setText('');
  }, [station, search]);

  const changeStationMobile = station => {
    onChange(station);
    setText('');
    setSearch(false);
  };

  return (
    <div
      className={`${classes.root} ${
        search ? null : classes.locationSelectorCardNoDisplay
      }`}
    >
      <Fab
        size="medium"
        className={`${classes.fabDisplay} ${classes.fabStyle}`}
        color="primary"
        aria-label="Search location"
      >
        {search ? (
          <CloseRoundedIcon onClick={() => setSearch(false)} />
        ) : (
          <SearchRoundedIcon onClick={() => setSearch()} />
        )}
      </Fab>
      <div className={classes.locationSelectorCard}>
        <Downshift
          onChange={item => {
            onChange(suggestions.find(({ label }) => item === label));
          }}
          inputValue={text}
          selectedItem={text}
          onInputValueChange={inputValue => setText(inputValue)}
          onSelect={() => {
            if (mobile) {
              googleEvent({ action: 'searched station', label: text });
            } else {
              googleEvent({ action: 'added station', label: text });
            }
            setText('');
            setSearch(false);
          }}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => {
            const { onBlur, onFocus, ...inputProps } = getInputProps({
              placeholder: mobile ? 'Search Station' : 'Add Station',
            });

            const filteredSuggestion = groupBy(
              suggestions.filter(val =>
                val.label.toLowerCase().includes(inputValue.toLowerCase())
              ),
              'state'
            );

            return (
              <div className={classes.container}>
                <div className={classes.inputSticky}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    InputLabelProps: getLabelProps({ shrink: true }),
                    InputProps: { onBlur, onFocus },
                    inputProps,
                    station,
                    mobile,
                    googleEvent,
                    goToNextStation,
                    inputRef,
                    goToPrevStation,
                  })}
                </div>

                <div className={classes.mobileDisplay}>
                  <div className={classes.stationWrap}>
                    {Object.keys(filteredSuggestion).map(state => {
                      return (
                        <StationWrap
                          stations={filteredSuggestion[state]}
                          state={state}
                          classes={classes}
                          changeStationMobile={changeStationMobile}
                        />
                      );
                    })}
                  </div>
                </div>

                {Object.keys(filteredSuggestion).length === 0 && (
                  <div
                    className={`${classes.mobileDisplay} ${classes.noStation}`}
                  >
                    <div>
                      <SentimentDissatisfiedRoundedIcon
                        className={classes.noStationIcon}
                      />
                      <p>NO STATIONS FOUND</p>
                    </div>
                  </div>
                )}

                <div {...getMenuProps()} className={classes.desktopDisplay}>
                  {isOpen && (
                    <Paper className={classes.paper} square>
                      {getSuggestions(suggestions, inputValue).map(
                        (suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion.label,
                            }),
                            highlightedIndex,
                            selectedItem,
                          })
                      )}
                    </Paper>
                  )}
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    </div>
  );
}
