import React, { useContext } from 'react';
import PlaystoreImage from '../assets/images/Playstore.jpeg';
import { ReactComponent as Linkedin } from '../assets/icons/linkedin.svg';
import { ReactComponent as Instagram } from '../assets/icons/instagram.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import withWidth from '@material-ui/core/withWidth';
import { Auth0Context } from '../auth/auth0';
import { Link } from 'react-router-dom';
import { UserEvent } from '../components/ga-events';
import { useLocation } from 'react-router-dom';
import { URLS } from 'common/config';

// TODO: Combine the media queries and reduce CSS
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    color: '#171717',
    fontSize: '1rem',
  },
  subHeader: {
    fontWeight: 400,
    color: '#000',
    fontSize: '0.9rem',
  },
  subHeaderEmail: {
    color: '#140CF2',
  },
  playstore: {
    width: '8rem',
    height: 'auto',
    boxShadow: ' 1px 2px 6px rgba(0, 0, 0, 0.1)',
  },
  socialLinks: {
    display: 'flex',
    width: '6rem',
    justifyContent: 'space-between',
  },
  linkedin: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: '#0077B5',
      transition: 'all ease-in 0.3s',
    },
  },
  twitter: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: '#1DA1F2',
      transition: 'all ease-in 0.3s',
    },
  },
  instagram: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: '#F16F21',
      transition: 'all ease-in 0.3s',
    },
  },
  stats: {
    fontSize: '0.9rem',
  },
  downloadLink: {
    textAlign: 'center',
    width: '12rem',
    verticalAlign: 'center',
    backgroundColor: '#EFEFEF',
    paddingBottom: '0.002rem',
    paddingTop: '0.8rem',
    marginBlockStart: '1rem',
    // marginBlockEnd: '1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d6d2d2',
      transition: 'all ease-in 0.3s',
    },
  },
  download: {
    display: 'block',
  },
  container: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  footer: {
    backgroundColor: '#F7F7F7',
    color: '#000',
    padding: '0 10rem',
  },
  span: {
    display: 'block',
    color: 'black',
    textDecoration: 'none',
  },
  icon: {
    fill: '#000',
    width: '22px',
    height: '22px',
  },
  itemStyle: {
    order: 0,
  },
  onlyMacro: {
    display: 'block',
  },
  copyright: {
    fontSize: 12,
    color: '#171717',
    fontWeight: 300,
    marginTop: '1rem',
  },
  connect: {
    marginTop: '5.5rem',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    // marginLeft: '6.9rem',
    // marginRight: '6.9rem',
  },

  downloadTitle: {
    fontSize: '14px',
    marginBlockStart: '0.5rem',
    marginBlockEnd: '0.5rem',
  },
  [theme.breakpoints.down('md')]: {
    footer: {
      paddingLeft: '5rem',
      paddingRight: '5rem',
    },
    connect: {
      marginTop: '3rem',
    },
    textMargin: {
      margin: '0px',
    },
  },
  [theme.breakpoints.between(`xs`, 'md')]: {
    center: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  [theme.breakpoints.down('sm')]: {
    footer: {
      paddingLeft: '5rem',
      paddingRight: '5rem',
    },
  },
  [theme.breakpoints.down(`xs`)]: {
    playstore: {
      width: '8rem',
    },
    socialLinks: {
      textAlign: 'center',
      width: 'auto',
      marginLeft: '30%',
      marginRight: '30%',
    },
    downloadLink: {
      width: '30vw',
    },
    download: {
      display: 'none',
      justifyContent: 'center',
      width: '75vw',
    },
    container: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    footer: {
      textAlign: 'center',
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
    icon: {
      width: '12px',
      height: '12px',
    },
    itemStyle: {
      order: 1,
      textAlign: 'center',
    },
    onlyMacro: {
      display: 'none',
    },

    downloadTitle: {
      fontSize: '6px',
    },
  },
}));

const Footer = props => {
  const isStation = props.location.pathname.includes('chart');
  const classes = useStyles(isStation);
  const mobile = Boolean(props.width === 'xs');
  const { isAuthenticated } = useContext(Auth0Context);
  const location = useLocation();
  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <Grid spacing={2} container>
          <Grid item sm={4} xs={12} className={classes.itemStyle}>
            <p className={`${classes.header} ${classes.textMargin}`}>
              Write to us
            </p>
            <a
              href="mailto:support@blueskyhq.io"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                UserEvent({
                  action: 'click at write to us',
                  label: `from ${location.pathname}`,
                });
              }}
              style={{ textDecoration: 'none' }}
            >
              <p
                className={`${classes.subHeader} ${classes.textMargin} ${classes.subHeaderEmail}`}
              >
                <Fade>support@blueskyhq.io</Fade>
              </p>
            </a>
            {mobile && isAuthenticated && (
              <Fade cascade>
                <br />
                <p className={`${classes.stats} ${classes.subHeaderEmail}`}>
                  <Link to="/view-stats">
                    <span className={classes.span}>Internal System Stats</span>
                  </Link>
                  <Link to="/monitor-stats">
                    <span className={classes.span}>Monitor Stats</span>
                  </Link>
                  <Link to="/temporal-accuracy">
                    <span className={classes.span}>Temporal Accuracy</span>
                  </Link>
                </p>
              </Fade>
            )}
            <p className={`${classes.header} ${classes.connect}`}>Connect</p>
            <div className={classes.socialLinks}>
              <a
                href="https://www.linkedin.com/company/blueskyhq/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  UserEvent({
                    action: 'clicked on linkedin link',
                    label: 'social icons',
                  });
                }}
              >
                <Fade>
                  <Linkedin
                    width="22px"
                    height="22px"
                    className={classes.linkedin}
                  />
                </Fade>
              </a>
              <a
                href="https://www.instagram.com/blue.sky.revolution/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  UserEvent({
                    action: 'clicked on Instagram link',
                    label: 'social icons',
                  });
                }}
              >
                <Fade>
                  <Instagram
                    width="22px"
                    height="22px"
                    className={classes.instagram}
                  />
                </Fade>
              </a>
              <a
                href="https://twitter.com/blueskylab"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  UserEvent({
                    action: 'clicked on twitter link',
                    label: 'social icons',
                  });
                }}
              >
                <Fade>
                  <Twitter
                    width="22px"
                    height="22px"
                    className={classes.twitter}
                  />
                </Fade>
              </a>
            </div>
          </Grid>
          <Grid
            item
            sm={4}
            xs={12}
            className={`${classes.onlyMacro} ${classes.center}`}
          >
            <Fade cascade>
              <div>
                <a
                  href="https://www.linkedin.com/company/blueskyhq/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <p className={classes.header}>Company </p>
                </a>
                <p className={classes.subHeader}>
                  <a
                    href={URLS['bsa-about']}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    onClick={() => {
                      UserEvent({
                        action: 'clicked on about us',
                        label: 'inside footer',
                      });
                    }}
                  >
                    About Us
                  </a>
                </p>
                <p className={classes.subHeader}>
                  <a
                    href="https://medium.com/blue-sky-thinking"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    onClick={() => {
                      UserEvent({
                        action: 'clicked on blog',
                        label: 'inside footer',
                      });
                    }}
                  >
                    Blog
                  </a>
                </p>
                <p className={classes.subHeader}>
                  <a
                    href={URLS['faqs']}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    onClick={() => {
                      UserEvent({
                        action: 'clicked FAQs',
                        label: 'inside footer',
                      });
                    }}
                  >
                    FAQs
                  </a>
                </p>
                <p className={classes.subHeader}>
                  <a
                    href={URLS['privacy']}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    onClick={() => {
                      UserEvent({
                        action: 'clicked Privacy Policy',
                        label: 'inside footer',
                      });
                    }}
                  >
                    Privacy Policy
                  </a>
                </p>
                <p className={classes.subHeader}>
                  <a
                    href={URLS['terms']}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    onClick={() => {
                      UserEvent({
                        action: 'clicked Privacy Policy',
                        label: 'inside footer',
                      });
                    }}
                  >
                    Terms of use
                  </a>
                </p>
              </div>
            </Fade>
          </Grid>
          <Grid item sm={4} xs={12}>
            <div style={{ float: 'right' }}>
              <div className={classes.onlyMacro}>
                <Fade>
                  <p className={classes.header}>Download Now </p>
                </Fade>
              </div>
              <div className={classes.download}>
                <a
                  href="https://blueskyhq.io/app"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    UserEvent({
                      action: 'clicked on mobile app link',
                      label: 'inside footer',
                    });
                  }}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Fade>
                    <img
                      src={PlaystoreImage}
                      alt="got to playstore"
                      className={classes.playstore}
                    />
                  </Fade>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
        <p className={classes.copyright}>
          Blue Sky Analytics copyright {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default compose(withRouter, withWidth())(Footer);
