import Axios from 'axios';
import queryString from 'query-string';
import { durations, envOptions } from '../config';
const { root_URL } = envOptions;
const url = `${root_URL}/api/bam-air-quality`;

const getTimeBucketBasedOnDuration = duration => {
  const durationObj = durations.find(el => el.value === duration);
  return durationObj?.timeBucket;
};

const getMeasurements = async (station, duration, config = {}) => {
  const timeBucket = getTimeBucketBasedOnDuration(duration);
  const qs = queryString.stringify({ duration, time_bucket: timeBucket });
  const res = await Axios.get(`${url}/${station}?${qs}`, config);

  let data = res.data;
  data.meta.slug = data.meta.id;
  return { data: data };
};

export { getMeasurements };
