import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { debounce, isEqual } from 'lodash';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { updateSnackbar } from 'common/store/actions';
import { LocationSelector } from '../chart';
import { getNewStation } from '../../utils/chart-utils';
import withWidth from '@material-ui/core/withWidth';
import { Footer } from '../embed';
import PollutantRanges from '../chart/pollutant-range-blocks';

const stationsType = {
  decode: encoded => (encoded ? encoded.split('+') : undefined),
  encode: decoded => (decoded ? decoded.join('+') : undefined),
};

const urlPropsQueryConfig = {
  stations: { type: stationsType },
  duration: { type: UrlQueryParamTypes.string, defaultValue: '1d' },
  pollutant: { type: UrlQueryParamTypes.string, defaultValue: 'pm25' },
  zoom: { type: UrlQueryParamTypes.number },
  latitude: { type: UrlQueryParamTypes.number },
  longitude: { type: UrlQueryParamTypes.number },
};

const styles = theme => ({
  root: {
    padding: '0 1rem',
    height: '100vh',
  },
  noData: {
    marginLeft: '4rem',
    width: '100%',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  metaInfo: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  controllers: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
  },
  ranges: {
    width: '40%',
  },
  durationSelector: {
    maxWidth: '70%',
  },
  locationSelector: {
    margin: '1rem 0',
    border: '1px solid #c9c9c9',
    borderRadius: 8,
  },
  embed: {
    margin: '1rem 0',
    height: '60vh',
  },
  footer: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 10,
  },
  [theme.breakpoints.down('xs')]: {
    metaInfo: {
      margin: '1rem 0',
      display: 'block',
    },
    ranges: {
      width: '100%',
      marginLeft: 0,
    },
    locationSelector: {
      border: '1px solid #c9c9c933',
    },
  },
});

class EmbedCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
    };
    this.setSearch = this.setSearch.bind(this);

    this.onClickChangeStation = debounce(this.onClickChangeStation, 600);
    document.addEventListener(
      'keydown',
      event => this.arrowButtonHandle(event),
      false
    );
  }

  setSearch(state) {
    this.setState({
      search: state,
    });
  }

  arrowButtonHandle(event) {
    const { stations = [] } = this.props;
    if (stations.length > 1) return null;

    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault();
        this.onClickChangeStation('previous');

        break;
      case 'ArrowRight':
        event.preventDefault();
        this.onClickChangeStation('next');
        break;
      default:
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      return true;
    }

    return false;
  }

  static getDerivedStateFromProps(props) {
    const {
      stations,
      updateSnackbar,
      history: {
        location: { pathname },
      },
      onChangeStations,
      allStations,
    } = props;

    //Provide a default station if not exist. Keep it empty in case of map embed
    //Default station is ito cause it has all the data
    // When slug does not exist pick the first slug from array of stations
    let defaultStation = 'ito-delhi-cpcb-9f8';
    let name = '';
    if (!stations && allStations.length > 0 && pathname !== '/embed/map') {
      const isStationExist = allStations.find(
        ({ slug }) => slug === defaultStation
      );
      if (isStationExist) {
        name = isStationExist.name;
      } else {
        defaultStation = allStations[0].slug;
        name = allStations[0].name;
      }
      updateSnackbar(`Default station is ${name}`);
      onChangeStations([defaultStation]);
    }
    return null;
  }

  onClickChangeStation(direction) {
    const { allStations, stations } = this.props;

    const station = getNewStation(allStations, stations, direction);
    this.addStation(station, true);
  }

  addStation = (station, changeStation = false) => {
    if (!station) return null;
    const { onChangeStations, stations = [], onlyOne } = this.props;
    if (stations.includes(station.slug)) return null;

    const stationsArray =
      changeStation || onlyOne ? [station.slug] : [...stations, station.slug];
    onChangeStations(stationsArray);
  };

  deleteStation = slug => {
    if (!slug) return null;
    const { onChangeStations, stations = [] } = this.props;

    const index = stations.indexOf(slug);
    if (index === -1) return null;
    stations.splice(index, 1);
    onChangeStations(stations);
  };

  render() {
    const {
      classes,
      allStations,
      duration,
      pollutant,
      zoom,
      latitude,
      longitude,
      onChangeZoom,
      onChangeLatitude,
      onChangeLongitude,
      onChangePollutant,
      width,
      stations: selectedStations = [],
      embedName,
      EmbedComponent,
      onlyOne,
    } = this.props;
    const mobile = Boolean(width === 'xs');
    const { search } = this.state;

    const TITLE = `BreeZo ${embedName}`;

    const embedProps = {
      allStations: allStations,
      selectedStations: selectedStations,
      pollutant: pollutant,
      duration: duration,
      mobile: mobile,
      zoom,
      latitude,
      longitude,
      onChangeZoom,
      onChangeLatitude,
      onChangeLongitude,
      deleteStation: this.deleteStation,
      onChangePollutant: onChangePollutant,
    };
    return (
      <div>
        <div className={classes.root}>
          <div className={classes.metaInfo}>
            <div className={classes.title}>{TITLE}</div>
            <div className={classes.ranges}>
              <PollutantRanges pollutant={pollutant} />
            </div>
          </div>
          {selectedStations.length < 3 && (
            <div className={classes.locationSelector}>
              <LocationSelector
                onChange={this.addStation}
                station={selectedStations}
                mobile={false || onlyOne}
                allStations={allStations}
                goToPrevStation={() => this.onClickChangeStation('prev')}
                goToNextStation={() => this.onClickChangeStation('next')}
                search={search}
                setSearch={this.setSearch}
              />
            </div>
          )}
          <div className={classes.embed}>
            <EmbedComponent {...embedProps} />
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ locations }) => ({
  allStations: locations,
});

export default compose(
  addUrlProps({ urlPropsQueryConfig }),
  connect(mapStateToProps, {
    updateSnackbar,
  }),
  withStyles(styles),
  withTranslation(),
  withWidth(),
  withRouter
)(EmbedCommon);
