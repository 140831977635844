import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import Paper from '@material-ui/core/Paper';

const RADIAN = Math.PI / 180;

const CustomDataAvailabilityTooltip = ({ active, payload }) => {
  // TODO: Handle edge cases in if condition.

  if (!active) return null;

  return (
    <Paper
      style={{
        minWidth: '6rem',
        textAlign: 'center',
        padding: '0.4rem',
        position: 'relative',
        top: -60,
        // TODO: Check if these properties actually change anything
        // TODO:: Don't use `white` or `black` if needed. Use hex code `#fff` or `#000`.
        // Reason being compatibility across browsers.
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      {payload[0].payload.label}: {payload[0].value}%
    </Paper>
  );
};

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ fontSize: 14 }}
    >
      {percent * 100 > 4 ? `${(percent * 100).toFixed(0)}%` : ''}
    </text>
  );
};

export default function Distribution({ data }) {
  return (
    <ResponsiveContainer width="100%" height="85%">
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          outerRadius="80%"
          dataKey="value"
          nameKey="label"
          stroke="0"
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>

        <Tooltip
          content={<CustomDataAvailabilityTooltip data={data} />}
          offset={0}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
