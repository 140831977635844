import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import history from './history';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactGA from 'react-ga';
import './assets/styles/App.sass';
import './assets/styles/Snackbar.sass';
import { Auth0Context } from './auth/auth0';
import { Meta } from './components';
import {
  setLocations,
  toggleSidebar,
  toggleSettings,
  updateSnackbar,
} from 'common/store/actions';
import getTheme from './theme';
import Router from './router';

import ErrorBoundary from './layout/error-boundary';
import { apiErrorCheck } from 'common/utils';
import { envOptions } from 'common/config';
import { PrivacyPolicyConsent } from './layout';

const { BRANCH } = envOptions;

// TODO: Sort out the imports in a better way.
// History object for react router
// Required to track pages in google-analytics

// Initialize Google analytics if and only if it's traffic from aqi.breezo.io
if (window.location.origin === 'https://aqi.breezo.io') {
  ReactGA.initialize('UA-77566947-5');
  ReactGA.set({ dimension1: BRANCH });

  // for tracking all the pages
  history.listen(({ pathname, search }) => {
    ReactGA.set({ page: pathname }); // Update the user's current page
    ReactGA.pageview(pathname + search); // Record a page-view for the given page
  });
}

// When the duration is updated, slice the dataset arrays

class App extends Component {
  static contextType = Auth0Context;

  async componentDidMount() {
    apiErrorCheck();
    this.props.setLocations();
  }

  toggleDrawer = anchor => {
    anchor === 'left'
      ? this.props.toggleSidebar()
      : this.props.toggleSettings();
  };

  render() {
    const { sidebarOpen, snackbar, locations } = this.props;
    const theme = getTheme();
    return (
      <ErrorBoundary>
        <MuiThemeProvider theme={theme}>
          <Meta
            title="BreeZo Visualizer: Data Against Air Pollution"
            description="Solution to any problem starts with awareness, Check the Air Pollution Data and Trends for more than 125 locations in India"
          />
          <PrivacyPolicyConsent />
          <div
            style={{ backgroundColor: theme.palette.background.paper }}
            className="app"
          >
            <Router
              locations={locations}
              history={history}
              toggleDrawer={this.toggleDrawer}
              sidebarOpen={sidebarOpen}
            />
          </div>
          <ToastContainer
            autoClose={snackbar.timeout}
            position="top-right"
            draggable
            pauseOnHover={false}
            className="toast-container"
          />
        </MuiThemeProvider>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({ location, locations, sidebarOpen, snackbar }) => {
  return {
    location,
    locations,
    sidebarOpen,
    snackbar,
  };
};

export default compose(
  connect(mapStateToProps, {
    setLocations,
    toggleSidebar,
    toggleSettings,
    updateSnackbar,
  })
)(App);
