const SET_LOCATIONS = 'set_locations';

const TOGGLE_SIDEBAR = 'toggle_sidebar';
const TOGGLE_SETTINGS = 'toggle_settings';
const UPDATE_SNACKBAR = 'update_snackbar';
const APP_CRASH = 'app_crash';

export {
  SET_LOCATIONS,
  TOGGLE_SIDEBAR,
  TOGGLE_SETTINGS,
  UPDATE_SNACKBAR,
  APP_CRASH,
};
