import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
  },

  selected: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 12,
    color: '#323232',
  },
  arrowIcon: {
    fontSize: 20,
  },
  popper: {
    zIndex: 1,
    width: '100%',
  },
  menuItem: {
    fontSize: 12,
    justifyContent: 'center',
  },
}));

export default function YearSelector({
  selected,
  options,
  onChange,
  googleEvent,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    const optionsArray = options.map(val => val.key);
    if (!optionsArray.includes(selected)) onChange(optionsArray.slice(-1)[0]);
  }, [selected, options, onChange]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const changeValue = key => () => {
    onChange(key);
    setOpen(false);
    googleEvent({ action: 'changes year' });
  };
  return (
    <div className={classes.root}>
      <div ref={anchorRef} className={classes.selected} onClick={handleToggle}>
        <div>
          <div className={classes.title}>{selected}</div>
        </div>

        <ArrowDown className={classes.arrowIcon} />
      </div>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement={'bottom'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map(({ label, key }) => (
                    <MenuItem
                      className={classes.menuItem}
                      key={key}
                      onClick={changeValue(key)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
