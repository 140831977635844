import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import store from 'store2';
import { URLS } from 'common/config';

const PRIVACY_POLICY_CONSENT = 'PRIVACY_POLICY_CONSENT';

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '6px 20px',
    border: '0px',
    lineHeight: 1.5,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#fefefe',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fefefe',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    background: '#1a61fb',
    padding: '0px 25px',
    color: 'white',
    position: 'sticky',
    zIndex: 99,
    top: 0,
  },
  link: {
    color: 'white',
    opacity: '0.8',
    fontSize: '15px',
  },
  gridStyle: {
    display: 'flex',
  },
  button: {
    background: '#fff',
  },
}));

const accept = setAccepted => {
  store(PRIVACY_POLICY_CONSENT, true);
  setAccepted(true);
};

const PrivacyPolicyConsent = () => {
  const classes = useStyles();
  const [accepted, setAccepted] = useState(store(PRIVACY_POLICY_CONSENT));
  if (accepted) return null;

  return (
    <Fade>
      <div className={classes.root}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={8}>
            <p>
              This site uses cookies to provide improve your user experience. By
              using{' '}
              <a className={classes.link} href="/">
                aqi.breezo.io
              </a>
              , you accept our{' '}
              <a
                className={classes.link}
                href={URLS['terms']}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of use
              </a>{' '}
              &{' '}
              <a
                className={classes.link}
                href={URLS['privacy']}
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
              .
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className={classes.gridStyle}
            justify="flex-end"
          >
            <p>
              <BootstrapButton onClick={() => accept(setAccepted)}>
                Accept Policy
              </BootstrapButton>
            </p>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default PrivacyPolicyConsent;
