import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getMeasurements } from 'common/api/measurements';
import { PollutedLoader } from '../loaders';
import TopPollutionDom from './top-pollution-dom';
import Fade from 'react-reveal/Fade';
import {
  getLastKnownValue,
  getOnlineStatus,
  hasMoreThanHalfData,
} from '../../utils/chart-utils';
import Tooltip from '@material-ui/core/Tooltip';
import { calculateExposure, calculateMean, getLevel } from 'common/utils';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {
  durations as DURATIONS,
  pollutantOptions as POLLUTANTS,
} from 'common/config';

const DURATION = DURATIONS[0].value;
const POLLUTANT = Object.keys(POLLUTANTS)[0];

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0.6rem 0.6rem 0 0.6rem',
    height: '12.5rem',
  },
  depthEffect: {
    borderRadius: '8px',

    boxShadow: ' 1px 2px 6px rgba(0, 0, 0, 0.1)',

    '&:hover': {
      boxShadow: ' 3px 4px 14px rgba(0, 0, 0, 0.2)',
      border: '0px solid #A6A6A6',
      transition: 'all ease-in 0.3s',
    },
  },
  cardOffline: {
    margin: '0.6rem 0.6rem 0 0.6rem',
    backgroundColor: '#f7f7f7',
    opacity: '0.8',
    height: '12.5rem',
    color: '#a3a2a0',
  },
  title: {
    fontSize: '10px',
    color: '#C8C8C8',
  },
  text: {
    fontSize: '1.4rem',
  },
  header: {
    fontSize: '1.1rem',
    padding: '16px 16px 0',
  },
  subHeader: {
    color: '#C8C8C8',
    paddingBottom: 16,
    paddingLeft: 16,
    fontSize: '12px',
  },
  gridStyle: {
    textAlign: 'center',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    header: {
      fontSize: '16px',
    },
    subHeader: {
      fontSize: '11px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    bottomCard: {
      padding: '0px 16px',
    },
    title: {
      fontSize: '11px',
    },
    card: {
      marginBottom: '0.6rem',
      height: '9rem',
    },
    cardOffline: {
      height: '9rem',
      marginBottom: '0.6rem',
    },
    header: {
      fontSize: '16px',
    },
    subHeader: {
      fontSize: '11px',
      paddingBottom: '0px',
    },
  },
}));

export default function TopPollution({
  slug,
  effect,
  disableClickEvent,
  isTopPollutant,
  isFavPollutant,
  matchMax,
  googleEvent,
  index,
  show,
}) {
  const locations = useSelector(state => state.locations);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    async function getData(slug) {
      if (!slug) return null;
      let { data } = await getMeasurements(slug.slug, DURATION);
      const lastKnownData = getLastKnownValue(data.data, POLLUTANT);

      const online = lastKnownData
        ? getOnlineStatus(lastKnownData.datetime) &&
          hasMoreThanHalfData(data.data, POLLUTANT)
        : false;

      if (slug[POLLUTANT]) data[POLLUTANT] = Math.round(slug[POLLUTANT]);
      else data[POLLUTANT] = lastKnownData ? lastKnownData[POLLUTANT] : null;

      locations.forEach(element => {
        if (slug.slug === element.slug) {
          data['city'] = element.name;
          data['state'] = element.state;
          data['status'] = element.status;
        }
      });

      online ? setData(data) : isTopPollutant ? setData(null) : setData(data);
      //if station is active set data and if it is not active setting top null and showing no station detail on it.
    }

    getData(slug);
  }, [slug, locations, isTopPollutant]);

  const classes = useStyles();
  let contentDom = null;

  if (data === null) return null;
  else if (!data) {
    contentDom = (
      <Fade cascade>
        <Link
          to={''}
          exact="true"
          style={{
            textDecoration: 'none',
            pointerEvents: 'none',
          }}
        >
          <Tooltip title={'Loading...'} placement="top-end">
            <Card className={`${classes.card} ${classes.depthEffect} `}>
              <PollutedLoader />;
            </Card>
          </Tooltip>
        </Link>
      </Fade>
    );
  } else {
    const link = `/chart?duration=${DURATION}&pollutant=${POLLUTANT}&stations=${slug.slug}`;
    const mean = calculateMean(data.data, POLLUTANT);
    const meanLabel = getLevel(mean, POLLUTANT);

    const exposure = calculateExposure(data.data, POLLUTANT);
    const label = getLevel(data[POLLUTANT], POLLUTANT);

    const subData = {
      Mean: {
        value: mean,
        label: meanLabel.label,
        color: meanLabel.color,
      },
      Exposure: {
        value: exposure ? exposure.value : null,
        label: exposure ? exposure.label : null,
        color: exposure ? exposure.color : null,
      },
    };
    //status 0 if all values are null
    const status = data.data.filter(val => val[POLLUTANT]).length < 1 ? 0 : 1;

    contentDom = (
      <TopPollutionDom
        googleEvent={googleEvent}
        link={link}
        data={data}
        disableClickEvent={disableClickEvent}
        effect={effect}
        status={status}
        classes={classes}
        label={label}
        subData={subData}
      />
    );
  }

  if (isTopPollutant || isFavPollutant) {
    return (
      <Grid
        item
        key={slug.slug}
        sm={4}
        xs={12}
        // style={{ display: index + 1 > show ? 'none' : 'block' }}
        className="topGrid"
      >
        <div
          style={
            matchMax
              ? { height: '9rem', marginBottom: '1.5rem' }
              : { height: '13rem', marginBottom: '1.5rem' }
          }
        >
          {contentDom}
        </div>
      </Grid>
    );
  } else return <>{contentDom}</>;
}
