import React, { useState } from 'react';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import DialogWrapper from '../dialog-wrapper';
import GetCode from '../get-code';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import EmbedEvent from '../ga-events/embed';

export default function ShareEmbed({ embedName, iconStyle }) {
  const [open, setOpen] = useState(false);
  const [embedUrl, setEmbedUrl] = useState('');

  const getEmbed = () => {
    const url = `${window.origin}/embed/${embedName}${window.location.search}`;
    setOpen(true);
    setEmbedUrl(url);
  };
  const theme = useTheme();
  const matchMax = useMediaQuery(theme.breakpoints.down('xs'));

  if (matchMax) {
    return null;
  }
  return (
    <>
      <DialogWrapper
        open={open}
        onClose={() => setOpen(false)}
        title={'Embed iframe code'}
        icon={<AssessmentRoundedIcon />}
      >
        <GetCode url={embedUrl} />
      </DialogWrapper>
      <Share
        style={iconStyle}
        onClick={() => {
          getEmbed();
          EmbedEvent({ action: 'clicked on share', label: embedName });
        }}
      ></Share>
    </>
  );
}
