import React from 'react';
import PopularComparisonCard from './comparison-card';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divMargin: {
    marginBottom: '1.5rem',
  },
}));

export default function RenderPopularComparison({ favourites, googleEvent }) {
  const classes = useStyles();
  const locations = useSelector(state => state.locations);
  let comparisonData = null;
  let compareList = [[], []];

  const total_cards = favourites.length;

  favourites.map(compare => {
    const compareSplit = compare.slug.split('+');
    compareList[0].push(compareSplit[0]);
    compareList[1].push(compareSplit[1]);
    return true;
  });

  const cards = compareList[0].slice(0, total_cards).map((station, index) => {
    const stationVal = compareList[1][index];

    comparisonData = {
      stationA: locations.filter(location => location.slug === station)[0],
      stationB: locations.filter(location => location.slug === stationVal)[0],
    };

    return (
      <Grid item key={station} sm={4} xs={12} className="topGrid">
        <div className={classes.divMargin}>
          <PopularComparisonCard
            comparisonData={comparisonData}
            googleEvent={googleEvent}
          />
        </div>
      </Grid>
    );
  });
  return cards;
}
