// import { envOptions } from '../../config';
// import axios from 'axios';
import { getStations } from '../../api/get-stations';

import {
  SET_LOCATIONS,
  TOGGLE_SIDEBAR,
  TOGGLE_SETTINGS,
  UPDATE_SNACKBAR,
  APP_CRASH,
} from './types';

// const { default_duration, root_URL } = envOptions;

// For retaining the cancel token
// let source;

// Abstract the common functionality of duration and location update
// Better reusability
// NOT GETTING USED ANYWHERE
// function getRequest(location, duration = default_duration) {
//   const url = `${root_URL}/measurements/${location}?duration=${duration}`;
//   return axios
//     .get(
//       url,
//       // This is for cancelling the request later on, in `RESET`
//       // https://github.com/axios/axios#cancellation
//       {
//         cancelToken: source.token,
//       }
//     )
//     .catch(err => {
//       if (axios.isCancel(err)) {
//         // Request was cancelled, log `err.message` to find out why?
//       } else {
//         throw err;
//       }
//     });
// }

/**
 * Set the locations on initial loading of the whole App
 */
export function setLocations() {
  const request = getStations();
  return {
    type: SET_LOCATIONS,
    payload: request,
  };
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
    meta: { analytics: `Sidebar toggled` },
  };
}

// The top settings bar display
export function toggleSettings() {
  return { type: TOGGLE_SETTINGS, meta: { analytics: `Settings-Bar toggled` } };
}

export function updateSnackbar(message, timeout = 3000) {
  return {
    type: UPDATE_SNACKBAR,
    payload: { message, timeout },
  };
}

export function updateAppCrash(state) {
  return {
    type: APP_CRASH,
    payload: state,
  };
}
