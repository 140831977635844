// This file works and the favourite works but how?
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import store from 'store2';
import { addUrlProps } from 'react-url-query';

import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Favorite from '@material-ui/icons/Favorite';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router';
import { UserEvent } from './ga-events';

const FAVORITE_LOCATIONS_STORE = 'favourite_locations';
const FAVORITE_COMPARE_STORE = 'compare_locations';
const styles = theme => ({
  root: {
    height: 40,
    width: 40,
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      height: 20,
      width: 20,
      margin: '0 8px',
    },
  },
});

export const syncStore = stations => {
  let STORE =
    stations.split('+').length > 1
      ? store(FAVORITE_COMPARE_STORE) || []
      : store(FAVORITE_LOCATIONS_STORE) || [];

  const STORE_NAME =
    stations.split('+').length > 1
      ? FAVORITE_COMPARE_STORE
      : FAVORITE_LOCATIONS_STORE;

  if (STORE.length > 0 && STORE.includes(stations)) {
    // Remove from local storage
    STORE.splice(STORE.indexOf(stations), 1);
  } else {
    // add to local storage
    STORE.push(stations);
  }
  store(STORE_NAME, STORE);
};

function captureEvent(state, stations) {
  UserEvent({
    action: state ? 'remove from favorite' : 'added to favorite',
    label:
      stations.split('+').length > 1 ? 'compare station' : 'single station',
  });
}

class FavoriteControl extends Component {
  state = { active: false };

  // This will trigger update of state each time
  // This is running twice and thus rendered twice
  // make it run only once
  static getDerivedStateFromProps(props, state) {
    const { stations } = props;
    if (!stations) return null;
    const STORE =
      stations.split('+').length > 1
        ? store(FAVORITE_COMPARE_STORE)
        : store(FAVORITE_LOCATIONS_STORE);

    if (!STORE) {
      return {
        active: false,
      };
    }

    return {
      active: STORE.includes(stations),
    };
  }

  // checkStatus(){

  //   const { stations } = this.props;

  //   const local_favorite_locations = store(FAVORITE_LOCATIONS_STORE);

  // }
  //  This component doesn't get mounted each time so need to update the
  // state on the fly
  render() {
    const { classes, stations } = this.props;
    // if (!location) return null;
    const style = {
      color: this.state.active ? '#ff738b' : '#c8c8c8',
    };
    return (
      <Tooltip
        title={`${
          this.state.active ? 'Unfavourite ' : 'Favourite'
        } this location`}
        placement="left"
      >
        <IconButton
          data-tut="tour_fav"
          style={style}
          onClick={() => {
            this.setState({ active: !this.state.active });
            syncStore(stations);
            captureEvent(this.state.active, stations);
          }}
          className={(classes.root, classes.paddingFix)}
        >
          <Favorite />
        </IconButton>
      </Tooltip>
    );
  }
}

const mapStateToProps = ({ location }) => ({ location });

export default compose(
  addUrlProps(),
  connect(mapStateToProps, null),
  withRouter,
  withStyles(styles)
)(FavoriteControl);

export { FAVORITE_LOCATIONS_STORE };
