import {
  pollutantOptions as POLLUTANTS,
  durations as DURATIONS,
  visibleForecastings,
} from 'common/config';
import dayjs from 'dayjs';
import { calculateMean, calculateExposure } from 'common/utils';
import { cloneDeep } from 'lodash';
/**
 *
 * @param {*} arr array of data
 * @param {*} pollutant pollutant to check data availabilty
 */
const getLastKnownValue = (arr, pollutant) => {
  if (!arr || !Array.isArray(arr)) return null;
  for (const val of arr.slice().reverse()) {
    if (val[pollutant]) {
      return val;
    }
  }
  return null;
};

const hasMoreThanHalfData = (data, pollutant) => {
  const filteredData = data.filter(val => val[pollutant] !== null);
  return (filteredData.length * 100) / data.length > 50;
};

/**
 *
 * @param {*} data array of data to find last update
 */
const getLastUpdate = data => {
  if (!data || !Array.isArray(data)) return null;
  for (const ele of data) {
    for (const key in POLLUTANTS) {
      if (POLLUTANTS.hasOwnProperty(key) && ele[key]) {
        return ele.datetime;
      }
    }
  }
  return null;
};

/**
 * function to get pollutant which has data
 * @param {*} data array of data
 */
const getPollutant = data => {
  if (!data) return null;
  for (const key in POLLUTANTS) {
    if (key) {
      if (isDataAvailable(data, key)) {
        return key;
      }
    }
  }
  return null;
};

/**
 * This method return peak of value of the day of festival if available otherwise object with
 * pollutant concentration as null and datetime as the valid datetime of the that day
 * That's how annotation will always be there even if all data of that day is null
 * so that user can at least see after effects of festival
 *
 * @param {*} data array of data containing data of all stations in one big array
 * @param {*} festiveDate datetime of festival;
 * @param {*} pollutant selected pollutant by user
 */
const getFestiveData = (data, festiveDate, pollutant) => {
  //variable to store a valid datetime of festival day,  will used as initial value of reduce function
  if (!data) return null;

  //Add data of all slus into one array then find datetime with max value in them
  const combinedData = Object.keys(data).reduce((acc, slug) => {
    return acc.concat(data[slug].data);
  }, []);

  let time;
  const maxValueDateTime = combinedData
    .slice()
    .filter(val => {
      if (
        new Date(val.datetime).toLocaleDateString() ===
        new Date(festiveDate).toLocaleDateString()
      ) {
        time = val.datetime;
        return true;
      }
      return false;
    })
    .reduce(
      (acc, curr) => {
        //null check
        if (!curr[pollutant]) return acc;

        // TODO: use the time of this value
        if (curr[pollutant] > acc[pollutant]) acc = curr;
        return acc;
      },
      { datetime: time, [pollutant]: 0 }
    );

  //all the value that fall in obtained datetime(this is datetime where pollution was max among all
  // the stations)  are returned
  //In payload each value represents data of one station

  const payload = combinedData.filter(val => {
    return val.datetime === maxValueDateTime.datetime;
  });
  return {
    payload: payload,
    datetime: maxValueDateTime.datetime,
  };
};

const getChartData = data => {
  if (!data || typeof data !== 'object') return null;

  const result = Object.keys(data).reduce((acc, slug) => {
    if (!data[slug].data) return acc;
    return Object.assign(acc, { [slug]: data[slug].data });
  }, {});

  const chartData = addSlugPrefixToData(result);

  return chartData;
};

//Add prefix to data key
const addSlugPrefixToData = (data, prefix = '') => {
  const result = [];

  Object.keys(data).forEach(slug => {
    data[slug].slice().forEach((value, index) => {
      let temp = {};

      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (key === 'datetime') {
            temp['datetime'] = new Date(value.datetime).toISOString();
          } else {
            temp[`${prefix}${slug}-${key}`] =
              value[key] > 1000 ? 1000 : value[key];
            if (index === 0 && prefix.includes('forecasting')) {
              temp[`${slug}-${key}`] = value[key] > 1000 ? 1000 : value[key];
            }
          }
        }
      }

      result[index] =
        result[index] === undefined ? temp : Object.assign(result[index], temp);
    });
  });

  return result;
};

const getForecastingData = (data, duration) => {
  if (!data || typeof data !== 'object') return null;
  const forecastingConfig = visibleForecastings[duration];
  if (!forecastingConfig) return [];

  const result = Object.keys(data).reduce((acc, slug) => {
    if (!data[slug].predictions) return acc;

    const visibleForecastingsData = Object.keys(data[slug].predictions)
      .map(key => {
        if (forecastingConfig.visible.includes(key))
          return data[slug].predictions[key];
        return null;
      })
      .filter(ele => ele);

    visibleForecastingsData.sort(function (a, b) {
      return new Date(a.datetime) - new Date(b.datetime);
    });

    return Object.assign(acc, { [slug]: visibleForecastingsData });
  }, {});

  const prefixedData = addSlugPrefixToData(result, 'forecasting-');

  if (prefixedData.length < 1) return prefixedData;

  const completeData = fillNullForecastingValues(
    prefixedData,
    forecastingConfig
  );
  return completeData;
};

//Fill value as null for datetime, whose prediction is not available like 2h, 4h etc
const fillNullForecastingValues = (data, config) => {
  if (data.length < 1) return [];
  let time = data[0].datetime;
  let result = [];

  const {
    difference: { value, unit },
  } = config;

  const nullValueObject = Object.keys(data.slice()[0]).reduce((acc, key) => {
    return Object.assign(acc, { [key]: null });
  }, {});

  const lastDate = new Date(data.slice(-1)[0].datetime).getTime();

  while (new Date(time).getTime() <= lastDate) {
    nullValueObject.datetime = time;
    const newData =
      time === data[0].datetime ? data.shift() : cloneDeep(nullValueObject);

    result.push(newData);
    //Increment time
    time = dayjs(time).add(value, unit).toISOString();
  }

  return result;
};

const exposureAndMean = (data, pollutant) => {
  if (!data || !pollutant) return null;
  return Object.keys(data).reduce(
    (acc, slug) => {
      return {
        mean: Object.assign(acc.mean, {
          [slug]: calculateMean(data[slug].data, pollutant),
        }),
        exposure: Object.assign(acc.exposure, {
          [slug]: calculateExposure(data[slug].data, pollutant),
        }),
      };
    },
    {
      mean: {},
      exposure: {},
    }
  );
};

/*
Returns array of boolean values representing
status of pollutants
*/

const getPollutantStatus = data => {
  return Object.keys(POLLUTANTS).map(key => {
    return {
      key: Object.keys(data).reduce((acc, slug) => {
        if (!data[slug]) return acc;
        return acc || getLastKnownValue(data[slug].data, key) ? true : false;
      }, false),
    };
  });
};

const getNewStation = (allStations, stations, direction) => {
  if (!allStations) return stations[0];
  const slugIndex = allStations
    .map((item, index) => {
      return item.slug === stations[0] ? index : null;
    })
    .filter(val => val !== null)[0];
  switch (direction) {
    case 'next':
      if (slugIndex === allStations.length - 1) {
        return allStations[0];
      } else {
        return allStations[slugIndex + 1];
      }
    case 'previous':
      if (slugIndex === 0) {
        return allStations.slice(-1)[0];
      } else {
        return allStations[slugIndex - 1];
      }
    default:
      return null;
  }
};

const getOnlineStatus = datetime => {
  const date = dayjs(new Date());
  const last = dayjs(new Date(datetime));
  const timeDiff = date.diff(last, 'hours');
  return timeDiff < 7 ? true : false;
};

const calculateAllDataSize = (data, pollutant) => {
  if (!data) return null;
  return Object.keys(data).reduce((acc, slug) => {
    return acc + data[slug].data.filter(val => val[pollutant]).length;
  }, 0);
};

const shouldDurationChange = (duration, earliestIndex) => {
  if (!earliestIndex) return null;
  const selectedDuration = duration
    ? DURATIONS.findIndex(({ value }) => value === duration)
    : DURATIONS.length;
  return selectedDuration > earliestIndex;
};

const getEarliestIndexByData = (data, allStations, max = false) => {
  const index = max ? Object.keys(data).length - 1 : 0;
  return Object.keys(data)
    .map(slug => {
      return getEarliestIndexBySlug(slug, allStations);
    })
    .sort(function (a, b) {
      return a - b;
    })[index];
};

const getEarliestIndexBySlug = (slug, allStations) => {
  const earliest = allStations.reduce((acc, curr) => {
    const { slug: currSlug, earliest } = curr;
    if (currSlug === slug) return earliest;
    return acc;
  }, null);
  return earliest
    ? DURATIONS.findIndex(({ value }) => value === earliest)
    : DURATIONS.length;
};

const isDataAvailable = (slugData, pollutant) => {
  return Object.keys(slugData).every(slug => {
    const size = slugData[slug].data.filter(val => val[pollutant]).length;

    return Boolean(size);
  });
};

const isRadarDataAvailable = data => {
  if (!Array.isArray(data)) return false;

  let monthsWithData = 0;
  for (const value of data) {
    let isDataAvailable = false;
    for (const key in value) {
      if (key !== 'key' && key !== 'month') {
        isDataAvailable = value[key] > 0 ? true : isDataAvailable;
      }
    }
    monthsWithData = isDataAvailable ? monthsWithData + 1 : monthsWithData;
  }
  if (monthsWithData < 2) return false;

  return true;
};

export {
  getForecastingData,
  isRadarDataAvailable,
  isDataAvailable,
  getEarliestIndexBySlug,
  getEarliestIndexByData,
  shouldDurationChange,
  calculateAllDataSize,
  getOnlineStatus,
  getNewStation,
  getPollutantStatus,
  exposureAndMean,
  getFestiveData,
  getPollutant,
  getLastKnownValue,
  getLastUpdate,
  getChartData,
  hasMoreThanHalfData,
};
