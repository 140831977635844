import React, { useState, useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MaterialTooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/DeleteOutline';
import Fade from 'react-reveal/Fade';
import {
  PollutantNameCell,
  SmallPieChart,
  calculateData,
  calculateUpdate,
  getAvailabilityGraph,
  getPollutantData,
} from './table-utils';
import {
  pollutantOptions as Pollutants,
  colors as COLORS,
  Embeds,
} from 'common/config';
import { getLevel } from 'common/utils';
import LocationSelector from './location-selector';
import Cigarette from '../../assets/images/cigarette.svg';
import ShareEmbed from '../embed/share-embed';
import { useLocation } from 'react-router-dom';
import { BreeZoTableEvent } from '../ga-events';

const StyledTableCell = withStyles(theme => ({
  root: {
    fontSize: '12px',
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    textAlign: 'center',
    borderBottom: '1px solid #F4F4F4',
    width: props => (props.fixedwidth ? '3rem' : ''),
  },
}))(TableCell);

const tableStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #F4F4F4',
    borderRadius: 8,
  },
  lineSplit: {
    borderLeft: '1px solid #F4F4F4',
    padding: '0 1rem',
    color: '#000',
  },
  aqiTitle: {
    color: '#000',
    position: 'relative',
    paddingTop: 12,
    paddingBottom: 12,
    borderTopLeftRadius: 8,
  },
  aqiValue: {
    minWidth: '1.6rem',
    padding: '0.25rem 0.25rem 0.25px 0',
  },
  header: {
    paddingRight: '0.25rem',
    paddingLeft: '0.25rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    color: '#000',
    position: 'relative',
  },
  availabilityGraph: {
    display: 'table-cell',
    minWidth: '5rem',
  },
  nameEllipses: {
    minWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0.25rem',
  },
  stationName: {
    maxWidth: '8rem',
  },
  cigaretteImage: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '40%',
    width: '75%',
  },
  delete: {
    opacity: 0.5,
    height: 20,
    '&:hover': {
      opacity: 1,
      transition: 'all ease-in 0.3s',
    },
  },
  [theme.breakpoints.down('md')]: {
    stationName: {
      maxWidth: '6rem',
    },
    nameEllipses: {
      minWidth: '6rem',
    },
    header: {
      width: '1.7rem',
    },
    borderLeft: {
      padding: '0 0.25rem',
      width: '1.7rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    stationName: {
      maxWidth: '4rem',
    },
    nameEllipses: {
      minWidth: '4rem',
    },
  },
}));

export default function SimpleTable(props) {
  const {
    allStations,
    addStation,
    data,
    pollutant,
    changePollutant,
    exposure,
    mean,
    goToNextStation,
    goToPrevStation,
    deleteStation,
    mobile = false,
    tablet = false,
    setSearch,
    search,
  } = props;
  const [hoveredOver, setHoveredOver] = useState(null);

  const { cigarettes, pollutantData } = useMemo(() => calculateData(data), [
    data,
  ]);

  const updated = useMemo(() => calculateUpdate(data), [data]);

  const availabilityGraphData = useMemo(
    () => getAvailabilityGraph(data, pollutant),
    [data, pollutant]
  );
  const classes = tableStyles();
  const stationCount = Object.keys(data).length;
  const { pathname } = useLocation();
  const isEmbed = pathname.includes('embed');

  return (
    <div className={classes.root}>
      <Fade cascade>
        <Table className={classes.table}>
          <TableHead style={{ backgroundColor: '#F4F4F4' }}>
            <TableRow>
              <StyledTableCell className={classes.aqiTitle}>
                AQI
              </StyledTableCell>

              <StyledTableCell
                className={classes.header}
                style={{ paddingTop: 12, paddingBottom: 12 }}
              >
                Monitor
              </StyledTableCell>
              {Object.keys(Pollutants).map(key => {
                if (key !== pollutant && (mobile || tablet)) return null;
                return (
                  <PollutantNameCell
                    googleEvent={BreeZoTableEvent}
                    label={Pollutants[key]}
                    key={key}
                    pollutant={key}
                    selectedPollutant={pollutant}
                    hoveredOver={hoveredOver}
                    setHoveredOver={setHoveredOver}
                    changePollutant={changePollutant}
                  />
                );
              })}
              <StyledTableCell fixedwidth="true" className={classes.lineSplit}>
                Exposure
              </StyledTableCell>
              {!mobile && (
                <StyledTableCell fixedwidth="true" className={classes.header}>
                  Mean
                </StyledTableCell>
              )}
              {!mobile && (
                <StyledTableCell fixedwidth="true" className={classes.header}>
                  <MaterialTooltip
                    title={'Cigarette smoked. Checkout FAQs to know more.'}
                  >
                    <img
                      style={{ width: '1.5rem' }}
                      src={Cigarette}
                      alt="Cigarette smoked"
                      className={classes.cigaretteImage}
                    />
                  </MaterialTooltip>
                </StyledTableCell>
              )}
              {!mobile && (
                <StyledTableCell fixedwidth="true" className={classes.header}>
                  Data
                </StyledTableCell>
              )}
              {!mobile && (
                <StyledTableCell className={classes.header}>
                  Last Updated
                </StyledTableCell>
              )}
              <StyledTableCell
                className={classes.header}
                style={{ borderTopRightRadius: 8 }}
              >
                {!isEmbed && (
                  <MaterialTooltip title={'Embed code'} placement="top-end">
                    <ShareEmbed
                      iconStyle={{
                        width: '15px',
                        fill: '#808080',
                        cursor: 'pointer',
                      }}
                      embedName={Object.keys(Embeds)[0]}
                    />
                  </MaterialTooltip>
                )}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).map((slug, index) => {
              return (
                <TableRow key={slug}>
                  <StyledTableCell
                    className={classes.aqiValue}
                    style={{
                      color: getLevel(data[slug].aqi, 'aqi').color,
                      borderLeft:
                        stationCount > 1 ? `3px solid ${COLORS[index]}` : '',
                    }}
                  >
                    {data[slug] ? data[slug].aqi : '-'}
                  </StyledTableCell>

                  <StyledTableCell className={classes.stationName}>
                    <MaterialTooltip
                      title={data[slug].meta.name}
                      placement="top-end"
                    >
                      <div className={classes.nameEllipses}>
                        {data[slug].meta.name}
                      </div>
                    </MaterialTooltip>
                  </StyledTableCell>
                  {getPollutantData(
                    BreeZoTableEvent,
                    pollutantData[slug],
                    pollutant,
                    hoveredOver,
                    setHoveredOver,
                    changePollutant,
                    mobile,
                    tablet
                  )}
                  <StyledTableCell
                    className={classes.lineSplit}
                    style={{
                      color: exposure[slug] ? exposure[slug].color : 'black',
                    }}
                  >
                    {exposure[slug] ? exposure[slug].value : '-'}
                  </StyledTableCell>
                  {!mobile && (
                    <StyledTableCell
                      fixedwidth="true"
                      style={{
                        color: getLevel(mean[slug], pollutant).color,
                      }}
                    >
                      {mean[slug] || '-'}
                    </StyledTableCell>
                  )}
                  {!mobile && (
                    <StyledTableCell fixedwidth="true">
                      <MaterialTooltip
                        title={
                          cigarettes[slug]
                            ? `Approx ${cigarettes[slug]} cigarettes smoked per day.`
                            : 'Not enough data'
                        }
                      >
                        <div>{cigarettes[slug] || '-'}</div>
                      </MaterialTooltip>
                    </StyledTableCell>
                  )}
                  {!mobile && (
                    <StyledTableCell className={classes.availabilityGraph}>
                      <SmallPieChart
                        data={availabilityGraphData[slug] || []}
                        color={COLORS[index]}
                      />
                    </StyledTableCell>
                  )}
                  {!mobile && (
                    <StyledTableCell
                      style={{ paddingLeft: '0.25rem', minWidth: '6rem' }}
                    >
                      {updated[slug] || '-'}
                    </StyledTableCell>
                  )}
                  <StyledTableCell style={{ paddingLeft: '0.25rem' }}>
                    <Delete
                      className={classes.delete}
                      style={
                        stationCount < 2
                          ? { opacity: 0.1 }
                          : { cursor: 'pointer' }
                      }
                      onClick={() => {
                        if (stationCount >= 2) {
                          deleteStation(slug);
                          BreeZoTableEvent({
                            action: 'clicked on delete station',
                          });
                        }
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fade>
      {stationCount < 3 && addStation && goToNextStation && goToPrevStation && (
        <div
          style={{
            backgroundColor: '#F2F2F2',
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        >
          <LocationSelector
            googleEvent={BreeZoTableEvent}
            onChange={addStation}
            station={Object.keys(data)}
            allStations={allStations}
            goToPrevStation={goToPrevStation}
            goToNextStation={goToNextStation}
            search={search}
            setSearch={setSearch}
          />
        </div>
      )}
    </div>
  );
}
