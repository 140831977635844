import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
// import FullScreenIcon from '@material-ui/icons/Fullscreen';
// import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
// import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { TimeSeriesEvent } from '../ga-events';

const DATA = [
  {
    value: 'points',
    label: 'Show points',
  },
  {
    value: 'grid',
    label: 'Show Grid',
  },
  {
    value: 'healthy',
    label: 'Show healthy limit',
  },
  {
    value: 'interpolation',
    label: 'Show interpolation',
  },
  {
    value: 'mean',
    label: 'Show mean line',
  },
  {
    value: 'annotations',
    label: 'Static annotations',
  },
];

const chartSettingsStyle = makeStyles({
  icon: {
    color: '#171717',
    cursor: 'pointer',
  },
  iconStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const renderSettings = (state, handleChange) => {
  return DATA.map((val, index) => {
    return (
      <Grid item xs={12} sm={6} key={index}>
        <FormControlLabel
          key={index}
          control={
            <Switch
              checked={state[val.value]}
              onChange={handleChange(val)}
              value={val.value}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={val.label}
        />
      </Grid>
    );
  });
};

const ChartSettings = ({ onChange, setSetting, settingState }) => {
  const classes = chartSettingsStyle();

  // TODO: It will be wise to put the static state outside the function
  // definition
  const [state, setState] = useState({
    points: false,
    healthy: true,
    interpolation: true,
    grid: true,
    annotations: true,
    mean: false,
  });

  /**
   * Using useRef and two useEffect to remove warning,
   * USing onChange inside dependency array of useEffect where onChange is  getting used causes recursive calling of useEffect
   * Similar issue :https://github.com/facebook/react/issues/15282
   *
   */

  //Saving onChange prop using useRef ,
  const latestOnChange = useRef(onChange);
  //value of latestOnChange change only when onChange changes
  useEffect(() => {
    latestOnChange.current = onChange;
  }, [onChange]);

  //this useEffect runs on every change in state
  useEffect(() => {
    latestOnChange.current(state);
  }, [state]);

  const handleChange = val => event => {
    setState({ ...state, [val.value]: event.target.checked });
    TimeSeriesEvent({
      action: `toggled ${val.label}`,
      label: 'settings',
    });
  };

  return (
    <>
      <Dialog open={settingState} onClose={() => setSetting(false)}>
        <MuiDialogTitle>
          <Typography className={classes.iconStyle} variant="h6">
            Chart Settings{' '}
            <CloseIcon
              onClick={() => setSetting(false)}
              style={{ cursor: 'pointer' }}
            ></CloseIcon>
          </Typography>
        </MuiDialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            {renderSettings(state, handleChange)}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

/**Will use later  */
//const fullScreenFunction = (full, setFullScreen) => {
//   const ele = document.getElementById('chart');
//   if (document.fullscreen) {
//     if (document.exitFullscreen) document.exitFullscreen();
//     else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
//     else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
//     else if (document.msExitFullscreen) document.msExitFullscreen();
//   } else {
//     if (ele.requestFullscreen) ele.requestFullscreen();
//     else if (ele.mozRequestFullScreen) ele.mozRequestFullScreen();
//     else if (ele.webkitRequestFullscreen) ele.webkitRequestFullscreen();
//     else if (ele.msRequestFullscreen) ele.msRequestFullscreen();
//   }
// };
const Settings = ({ onChange, setSetting, settingState }) => {
  return (
    <ChartSettings
      onChange={onChange}
      setSetting={setSetting}
      settingState={settingState}
    />
  );
};
export default Settings;
