import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const getTheme = () =>
  createMuiTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
    overrides: {
      MuiDialogContent: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          color: '#1E4793',
        },
      },
      MuiDialogTitle: {
        root: {
          borderBottom: '2px solid #a1b9e4',
          background: '#DEEBFD',
          color: '#1E4793',
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: '#0e3384cf',
        },
      },
    },
    palette: {
      primary: {
        main: '#1a61fb',
      },
      secondary: indigo,
    },
  });

export default getTheme;
