import dayjs from 'dayjs';
import { groupBy } from 'lodash';

// TODO: Move this to a dayjs config file
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// TODO: It's used only once, move it there
const categorizeBy = category => {
  return array => groupBy(array, item => item[category] || 'Others');
};

export * from './geo-location';
export { categorizeBy };
