import React from 'react';
import Map from '../mapbox/map';
import EmbedHoc from '../hoc/search-bar-hoc';
import { Loader } from '../loaders';

const EmbedMap = ({
  allStations,
  selectedStations,
  deleteStation,
  zoom,
  latitude,
  longitude,
  onChangeZoom,
  onChangeLatitude,
  onChangeLongitude,
}) => {
  const [loader, setLoader] = React.useState(true);

  const monitorPopUp = allStations.find(
    ({ slug: stationSlug }) => stationSlug === selectedStations[0]
  );

  return (
    <>
      <Loader />
      <div
        style={{
          height: '100%',
          width: '100%',
          minHeight: 500,
          opacity: loader ? 0.3 : 1,
          pointerEvents: loader ? 'none' : '',
        }}
      >
        <Map
          height={'calc(100% - 55px)'}
          width={'100%'}
          isEmbed={true}
          loaderStatus={status => setLoader(status)}
          monitorPopUp={monitorPopUp}
          deleteStation={deleteStation}
          zoom={zoom}
          latitude={latitude}
          longitude={longitude}
          onChangeZoom={onChangeZoom}
          onChangeLatitude={onChangeLatitude}
          onChangeLongitude={onChangeLongitude}
        />
      </div>
    </>
  );
};

export default EmbedHoc(EmbedMap, 'Map', true);
