import React from 'react';

import VizCard from './visualization-card';
import { calculateDistribution } from 'common/utils';
import { Distribution as DialogBox } from './dialog-content';
import Distribution from './distribution-chart';
import { Embeds } from 'common/config';

export default function ({ data, label, pollutant }) {
  const chartData = calculateDistribution(data, pollutant);
  return (
    <VizCard
      embedName={Object.keys(Embeds)[3]}
      title={'Distribution'}
      label={label}
      chart={<Distribution data={chartData} />}
      dialog={<DialogBox />}
    />
  );
}
