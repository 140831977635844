import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import TopPollution from './top-pollution';
import Grid from '@material-ui/core/Grid';

const DEFAULT_CARDS = 3;
function cards(topPollution, show, matchMax, googleEvent, favCheck) {
  //Render 3 loader if topPollution array is empty
  if (topPollution.length === 0) {
    topPollution = new Array(3).fill(0);
  }

  const total_cards = topPollution.length;

  const cards = topPollution
    .slice(0, total_cards)
    .map((val, i) => {
      return (
        <TopPollution
          googleEvent={googleEvent}
          slug={val}
          effect={true}
          isTopPollutant={!favCheck} //check whether component is rendered for top pollutant or not
          isFavPollutant={favCheck} //check whether component is rendered for fav pollutant or not
          matchMax={matchMax}
          index={i}
          show={show}
        />
      );
    })
    .filter(ele => ele !== null);

  return cards;
}

export default function RenderPollutedLocations({
  topPollution,
  showPolluted,
  fav,
  googleEvent,
}) {
  const [show, setShow] = useState(0);
  const theme = useTheme();
  const matchMax = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setShow(showPolluted);
  }, [showPolluted]);

  if (fav) return cards(topPollution, show, matchMax, googleEvent, true);

  return (
    <div>
      <Grid
        spacing={1}
        container
        className={showPolluted === DEFAULT_CARDS && 'topPollutedHide'}
      >
        {cards(topPollution, show, matchMax, googleEvent, false)}
      </Grid>
    </div>
  );
}
