import { useLayoutEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

function getScrollPosition() {
  if (!isBrowser) return { x: 0, y: 0 };

  return { x: window.scrollX, y: window.scrollY };
}

export default function useScrollPosition(effect) {
  useLayoutEffect(() => {
    const handleScroll = () => {
      const currPos = getScrollPosition();
      effect({ currPos });
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });
}
