import orderBy from 'lodash/orderBy';

const getLocations = data => {
  if (!data) return null;
  return orderBy(
    data,
    [station => (station.state || 'Zzzz').toLowerCase()],
    ['asc']
  );
};

export { getLocations };
