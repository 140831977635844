import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Backdrop from '@material-ui/core/Backdrop';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import usePrevious from '../custom-hooks/previous-hook';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { TourEvent } from 'components/ga-events';

import { Redirect } from 'react-router-dom';
import { syncStore } from '../favorite';
import store from 'store2';
import { Auth0Context } from '../../auth/auth0';

const FAVORITE_LOCATIONS_STORE = 'favourite_locations';

const useStyles = makeStyles(theme => ({
  speedDial: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 40,
    left: 'auto',
    position: 'fixed',
    zIndex: '99',
  },
  MuiBackdropRoot: {
    zIndex: 99,
  },
}));

export default function MenuFab(props) {
  const { station, setSearch, setSetting, openTour } = props;
  const prevStation = usePrevious(station);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [actions, setActions] = useState([]);
  const favState = store(FAVORITE_LOCATIONS_STORE)
    ? store(FAVORITE_LOCATIONS_STORE).includes(station)
    : false;
  const [fav, favChange] = useState(favState);
  const { isAuthenticated, logout, login } = useContext(Auth0Context);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const favState = store(FAVORITE_LOCATIONS_STORE)
      ? store(FAVORITE_LOCATIONS_STORE).includes(station)
      : false;

    station !== prevStation && favChange(favState);

    const actions = [
      {
        icon: <InfoRoundedIcon />,
        name: 'Tour',
        action: () => {
          openTour(true);
          TourEvent({ action: 'start tour from fab menu' });
        },
      },
      {
        icon: <SettingsRoundedIcon />,
        name: 'Setting',
        action: () => {
          setOpen(false);
          setSetting(true);
        },
      },
      {
        icon: <PersonRoundedIcon />,
        name: isAuthenticated ? 'Logout' : 'Login',
        action: isAuthenticated ? logout : login,
      },
      {
        icon: <SearchRoundedIcon />,
        name: 'Search',
        action: () => setSearch(true),
        fabProps: {
          'data-tut': 'tour_search_mobile',
        },
      },
      {
        icon: fav ? (
          <FavoriteRoundedIcon style={{ color: '#ff738b' }} />
        ) : (
          <FavoriteBorderRoundedIcon />
        ),
        name: 'Favorite',
        action: () => {
          syncStore(station);
          favChange(!fav);
        },
      },
      {
        icon: <HomeRoundedIcon />,
        name: 'Home',
        action: () => setRedirect(true),
      },
    ];

    setActions(actions);
  }, [
    station,
    setActions,
    fav,
    favChange,
    isAuthenticated,
    logout,
    login,
    setSearch,
    redirect,
    setRedirect,
    setSetting,
    prevStation,
    setOpen,
    openTour,
  ]);

  return (
    <>
      {redirect && <Redirect to="/" />}
      <Backdrop open={open} className={classes.MuiBackdropRoot} />
      <SpeedDial
        id="menu_speeddial"
        ariaLabel="Main menu"
        size="medium"
        color="primary"
        className={classes.speedDial}
        hidden={false}
        icon={
          <SpeedDialIcon
            data-tut="tour_menu"
            icon={<AppsRoundedIcon />}
            openIcon={<CloseRoundedIcon />}
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={'up'}
      >
        {actions.map(action => (
          <SpeedDialAction
            color="primary"
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.action}
            FabProps={action.fabProps}
          />
        ))}
      </SpeedDial>
    </>
  );
}
