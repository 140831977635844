import React from 'react';
import {
  pollutantOptions as POLLUTANTS,
  colors as COLORS,
} from 'common/config';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { ReferenceLine, ReferenceArea } from 'recharts';
import dayjs from 'dayjs';
import { durations as DURATION, getRangeWithLabel } from 'common/config/index';
import { getFestiveData } from '../../utils/chart-utils';

const BANDS = getRangeWithLabel;

function getSlug(label) {
  return label.substring(0, label.lastIndexOf('-'));
}

const tooltipStyles = makeStyles({
  root: {
    padding: '0.5rem',
    fontSize: 12,
    '& > p': {
      margin: 0,
    },
  },
});

//convert DURATION array of objects into object of object
const FORMATS = DURATION.reduce((acc, curr) => {
  acc[curr.value] = curr;
  return acc;
}, {});

const ReferenceLineLabel = props => {
  const {
    text,
    color,
    strokeDash,
    viewBox: { x, y, width },
    middle,
  } = props;

  const xAxisPlacement = middle ? width / 2 : x + width;
  // const xAxisPlacement = width / 2;

  return (
    <g transform={`translate(${xAxisPlacement},${y - 12.5})`}>
      {!middle && (
        <line
          x1={0}
          y1={12.5}
          stroke={color}
          x2={25}
          y2={12.5}
          strokeWidth={0.5}
          strokeDasharray={strokeDash}
          fill="none"
          fillOpacity={1}
        />
      )}
      <rect
        x="25"
        y="0"
        width="75"
        height="25"
        stroke={color}
        strokeWidth={0.5}
        strokeDasharray={strokeDash}
        rx={2}
        fillOpacity="1"
        fill="#fff"
      ></rect>
      <text
        x={25 + 17}
        y={16}
        fill={color}
        style={{
          fontSize: 10,
        }}
      >
        {text}
      </text>
    </g>
  );
};

const PredictedReferenceArea = (data, mobile) => {
  if (data.length < 1) return null;
  return (
    <ReferenceArea
      x1={data[0].datetime}
      x2={data.slice(-1)[0].datetime}
      stroke="grey"
      label={<ReferenceAreaLabel text="PREDICTED" mobile={mobile} />}
      fillOpacity={0.25}
      strokeOpacity={0.2}
    />
  );
};

const SafeLine = (pollutant, getSafeLimit, matches) => {
  const safeLimit = getSafeLimit(pollutant);
  return (
    <ReferenceLine
      y={safeLimit.value}
      stroke={safeLimit.color}
      strokeWidth={0.5}
      label={
        <ReferenceLineLabel
          x={500}
          color={safeLimit.color}
          middle={matches}
          text={`Safe < ${safeLimit.value}`}
        />
      }
    />
  );
};

const MeanLine = (mean, matches, color = '#8884d8') => {
  return (
    <ReferenceLine
      stroke={color}
      strokeDasharray={'3 3'}
      y={mean}
      label={
        <ReferenceLineLabel
          strokeDash={'3 3'}
          color={color}
          middle={matches}
          text={`Mean: ${mean}`}
        />
      }
    />
  );
};

const festiveDates = (data, festiveDates, pollutant) => {
  const result = festiveDates.map(value => {
    const date = getFestiveData(data, value.datetime, pollutant);
    return {
      datetime: date.datetime,
      payload: date.payload,
      name: value.name,
    };
  });

  return result;
};

const FestiveLine = (date, payload, matches, text, pollutant) => {
  if (!date) return null;
  const dateText = dayjs(date).format("D, MMM 'YY");
  return (
    <ReferenceLine
      key={text}
      stroke="#000"
      x={date}
      label={
        <FestiveLineLabel
          text={text}
          date={dateText}
          color={'#000'}
          payload={payload}
          matches={matches}
          pollutant={pollutant}
        />
      }
    />
  );
};

const FestiveLineLabel = props => {
  const {
    text,
    color,
    payload,
    matches,
    date,
    pollutant,
    viewBox: { x, height },
  } = props;
  //default :right side of annotation
  let xTranslate = x + 10;
  // show left side of annotation in case of overflowing
  if (matches && xTranslate > 300) {
    xTranslate = x - 100;
  }

  //True if payload contains more than one station data
  const multi = payload.length > 1;
  return (
    <g transform={`translate(${xTranslate},${height / 20})`}>
      {multi &&
        payload.map((val, index) => {
          return (
            <rect
              key={index}
              x={0}
              y={(index + 2) * 14 - 9}
              width={9}
              height={9}
              style={{ fill: COLORS[index] }}
            ></rect>
          );
        })}
      <text
        x={0}
        y={0}
        fill={color}
        style={{
          fontSize: 12,
        }}
      >
        <tspan x={0} y={0}>
          {text}
        </tspan>
        <tspan x={0} y={14}>
          {date}
        </tspan>
        {payload.map((val, index) => {
          return (
            /** x is 12 when there are color label box for multi stations*/
            <tspan key={index} x={multi ? 12 : 0} y={(index + 2) * 14}>
              {`${POLLUTANTS[pollutant]} : ${val[pollutant] || '-'}`}
            </tspan>
          );
        })}
      </text>
    </g>
  );
};

const CustomCursor = props => {
  const { payload, points, stroke } = props;
  if (!payload[0].payload[payload[0].name]) return null;
  return (
    <g>
      <line
        x1={points[0].x}
        y1="0"
        x2={points[0].x}
        y2={points[1].y}
        style={{ stroke: stroke, strokeWidth: 1 }}
      />
    </g>
  );
};

const CustomToolTip = props => {
  const { label, payload, active, duration, pollutant, multi } = props;
  const classes = tooltipStyles();
  if (!active) return null;
  const date = dayjs(label).format(FORMATS[duration].format);

  //Array to record the rendered color labels and don't render them again at point where
  //forecasting and actual pollution level meets
  const colors = [];

  return (
    <Paper className={classes.root} style={{ backgroundColor: 'white' }}>
      <p>{date}</p>
      {payload.map((point, index) => {
        const color = multi ? point.color : point.fill;
        if (colors.includes(color)) return null;
        colors.push(color);
        return (
          <p key={index}>
            <svg width={9} height={9}>
              <rect width={9} height={9} style={{ fill: color }}></rect>
            </svg>
            <span style={{ marginLeft: 5 }}>
              {POLLUTANTS[pollutant]} : {point.value}
            </span>
          </p>
        );
      })}
    </Paper>
  );
};

const CustomizedAxisTick = props => {
  const { x, y, payload, duration, mobile } = props;
  let ticks = null;

  if (FORMATS[duration].ticks)
    ticks = mobile
      ? FORMATS[duration].ticks.mobile
      : FORMATS[duration].ticks.desktop;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={18}
        textAnchor="middle"
        fill="#666"
        style={{ fontSize: 12 }}
      >
        {ticks ? dayjs(payload.value).format(ticks.format) : ticks}
      </text>
    </g>
  );
};

const ReferenceBands = props => {
  const {
    viewBox: { x },
    yAxis: { scale },
    pollutant,
  } = props;

  const bands = BANDS.map(
    ({
      color,
      range: {
        [pollutant]: [y0, y1],
      },
    }) => {
      return {
        color,
        y: scale(y1),
        height: scale(y0) - scale(y1),
      };
    }
  );

  return (
    <g>
      {bands.map(({ color, y, height }, index) => (
        <rect
          key={index}
          x={x}
          y={y}
          width={10}
          // FIXME: Find out why is it `NaN`
          height={isNaN(height) ? 0 : height}
          fill={color}
          fillOpacity={0.7}
        />
      ))}
    </g>
  );
};

const getTicks = (data, key, mobile, duration) => {
  let currentFormat = FORMATS[duration];

  if (data.length < 1) return null;
  const length = data.length;

  const ticks = [];
  let ticksCount = currentFormat.ticks.desktop.count + 1;

  if (mobile) ticksCount = currentFormat.ticks.mobile.count + 1;

  if (!mobile) ticks.push(data[0][key]);

  if (data.length < ticksCount) ticksCount = data.length;
  for (let i = 1; i < ticksCount; i++) {
    const index = Math.ceil((length / ticksCount) * i);
    ticks.push(data[index][key]);
  }

  if (!mobile) ticks.push(data[data.length - 1][key]);

  return ticks;
};

const RenderLegend = props => {
  const { payload, stations } = props;

  return (
    <div style={{ display: 'flex' }}>
      {payload.map((entry, index) => {
        const { dataKey } = entry;

        //Don't render legends for forecasted points cause that will be duplicate
        if (dataKey.includes('forecasting')) return null;

        return (
          <div
            key={`item-${index}`}
            style={{
              padding: '5px 10px',
              marginRight: 10,
              fontSize: 12,
            }}
          >
            <svg width={10} height={10}>
              <rect width={10} height={10} style={{ fill: entry.color }}></rect>
            </svg>
            <span style={{ marginLeft: 10 }}>
              {stations.get(getSlug(entry.value))}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const ReferenceAreaLabel = ({
  text,
  viewBox: { x, y, width, height },
  mobile,
}) => {
  const xCoordinate = x + width / 2 - 24;
  const yCoordinate = height + y - 10;

  return (
    <text
      x={xCoordinate}
      y={mobile ? yCoordinate + 20 : yCoordinate}
      style={{
        fontSize: 10,
      }}
      transform={`rotate(${mobile ? -90 : 0} ${xCoordinate} ${yCoordinate})`}
    >
      {text}
    </text>
  );
};

export {
  PredictedReferenceArea,
  ReferenceAreaLabel,
  SafeLine,
  getTicks,
  MeanLine,
  FORMATS,
  FestiveLine,
  ReferenceLineLabel,
  CustomizedAxisTick,
  CustomToolTip,
  CustomCursor,
  festiveDates,
  ReferenceBands,
  RenderLegend,
};
