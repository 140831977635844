import i18n from 'i18next';
import store from 'store2';
import dayjs from 'dayjs';
import { initReactI18next } from 'react-i18next';
import resources from './translation';

const lang = store('language');
dayjs.locale(resources[lang || 'en'].dayjs);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang || 'en',

    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
