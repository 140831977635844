import React from 'react';
import EmbedHoc from '../hoc/embed-data-hoc';
import Distribution from '../chart/distribution-component';
import { getDuration } from 'common/config';
import { colors as COLORS } from 'common/config';
import { makeStyles } from '@material-ui/core/styles';

const embedStyles = makeStyles(theme => ({
  embed: {
    display: 'grid',
    columnGap: '15%',
    gridTemplateColumns: '1fr 2fr',
  },
  [theme.breakpoints.down('sm')]: {
    embed: {
      display: 'grid',
      columnGap: '5%',
      gridTemplateColumns: '1fr 1fr',
    },
  },
  [theme.breakpoints.down('xs')]: {
    embed: {
      display: 'block',
      height: '100%',
      '& > div': {
        margin: '12px 0',
      },
    },
  },
}));

const Embed = props => {
  const { data, pollutant, allStations, selectedStations, duration } = props;

  const stations = selectedStations.map(slug => {
    return allStations.find(({ slug: stationSlug }) => stationSlug === slug);
  }, {});

  const classes = embedStyles();

  return (
    <div style={{ height: '100%', width: '100%', marginBottom: 50 }}>
      <div className={classes.embed}>
        <div>
          {Object.keys(data).map(slug => {
            return (
              <Distribution
                key={slug}
                pollutant={pollutant}
                label={`Last ${getDuration(duration).title}`}
                data={data[slug].data}
              />
            );
          })}
        </div>
        <div style={{ marginTop: '12px' }}>
          <p style={{ fontSize: 14 }}>Station</p>
          {stations &&
            stations.map(({ name }, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '12px 0',
                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <svg width="14" height="14">
                        <rect width="14" height="14" fill={COLORS[index]} />
                      </svg>
                    </div>
                    <div style={{ fontSize: 14 }}>{name}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default EmbedHoc(Embed, 'Distribution Pie-chart', true);
