import React from 'react';
import EmbedHoc from '../hoc/embed-data-hoc';
import { exposureAndMean } from '../../utils/chart-utils';
import Table from '../chart/table';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const EmbedTable = props => {
  const {
    data,
    pollutant,
    allStations,
    deleteStation,
    onChangePollutant,
  } = props;
  const { exposure, mean } = exposureAndMean(data, pollutant);
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div style={{ height: '100%', width: '100%', marginBottom: 50 }}>
      <Table
        allStations={allStations}
        data={data}
        pollutant={pollutant}
        exposure={exposure}
        mean={mean}
        tablet={tablet}
        mobile={mobile}
        changePollutant={onChangePollutant}
        deleteStation={deleteStation}
      />
    </div>
  );
};

export default EmbedHoc(EmbedTable, 'Table');
