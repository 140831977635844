import React from 'react';
import { pollutantFullNames as POLLUTANT_NAMES } from 'common/config';

const PollutantSelectors = ({ pollutant }) => {
  const DATA = {
    pm25:
      'PM2.5 particles are fine particles with a diameter of 2.5 micrometers or smaller. They occur mainly through combustion (such as stubble burning,vehicular emissions). PM2.5 particles can travel deeper into your respiratory system and even enter your bloodstream, leading to coughing,tightness in your chest and wheezing. In some cases, it can also lead to severe diseases such as chronic obstructive pulmonary disease (COPD) and cancer. They are the primary polluters in India',
    pm10:
      'PM10 primarily consists of naturally occurring particles such as dust which are generally visible to the human eye. It is also influenced by human activity such as vehicular movement and construction, which increase the amount of dust circulating in the air. They are particle that can enter our respiratory system and harm us, but can also be easily protected against, due to their bigger size.',
    o3:
      'Ozone at the ground level is the main ingredient of “smog” and is also harmful and toxic to people and the environment',
    so2:
      'The main sources of emission for sulphur dioxide are industries and vehicles. Sulphur dioxide is dangerous to the human body as it irritate the areas of the body which air passes through, i.e nose, throat and lungs. This generally leads to coughing or wheezing fits which can be severe for people who already have respiratory issues',
    nox:
      'The release of nitrogen oxides in the air is a natural phenomenon However, human activities (such as combustion) have pushed nitrogen levels in the air to unhealthy and unsustainable levels. Long term exposure can decrease lung function, increase the risk of respiratory conditions and increases the response to allergens.',
    co:
      'The greatest source of CO in the air comes from any activity which burn fossil fuels (eg: cars, industries). Breathing air with a high concentration of CO reduces the amount of oxygen that can be transported in the blood stream to critical organs like the heart and brain, which can cause dizziness, confusion and unconsciousness.',
  };

  return (
    <>
      <h4> {POLLUTANT_NAMES[pollutant]}</h4>
      <p>{DATA[pollutant]}</p>
    </>
  );
};

const RadarGraph = () => {
  return (
    <>
      The Radar Graph allows you to easily understand the mean pollution level
      on a month-by-month basis. You can compare and contrast air pollution
      levels across years to understand whether air pollution has been
      decreasing or increasing
    </>
  );
};

const Distribution = () => {
  return (
    <>
      Distribution shows you the split between air quality levels across the
      time-frame chosen in the graph above, allowing you to judge which air you
      have been exposed to the most
    </>
  );
};

const Exposure = () => {
  return (
    <>
      Exposure lets you understand how badly you are exposed to polluted air by
      comparing it to the recommended exposure level based on the time frame and
      stations chosen in the graph above. The green bar represents "healthy
      exposure levels" as per WHO recommendations.
    </>
  );
};

const MeanLevel = () => {
  return (
    <>
      This graph gives you a comparison between the recommended mean air
      pollution levels (green bar) and the actual mean air pollution levels
      based on the time frames and the stations chosen above.
    </>
  );
};

const CalendarHeatmap = () => {
  return (
    <>
      The Calendar Heatmap is an easy way to understand average air pollution
      levels on a day-by-day basis across the entire year.
    </>
  );
};

export {
  CalendarHeatmap,
  MeanLevel,
  Exposure,
  Distribution,
  RadarGraph,
  PollutantSelectors,
};
