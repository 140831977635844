import React from 'react';
import ContentLoader from 'react-content-loader';
import Fade from 'react-reveal/Fade';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: '1rem 0.5rem',
    marginTop: '0rem',
    height: '13rem',
  },
}));

const RegionalDashboardLoader = () => {
  const classes = useStyles();

  return (
    <Fade cascade>
      <div className={classes.root}>
        <ContentLoader
          height={544}
          width={330}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="200" height="30" />
          <rect x="0" y="40" rx="0" ry="0" width="330" height="30" />
          <rect x="0" y="80" rx="0" ry="0" width="330" height="30" />
          <rect x="0" y="120" rx="0" ry="0" width="330" height="230" />
          <rect x="0" y="360" rx="0" ry="0" width="330" height="30" />
          <rect x="0" y="400" rx="0" ry="0" width="330" height="30" />
        </ContentLoader>
      </div>
    </Fade>
  );
};

export default RegionalDashboardLoader;
