import React from 'react';
import { makeStyles } from '@material-ui/styles';
import WeatherIcon from '@material-ui/icons/Terrain';

const TemperatureIcon = WeatherIcon;
const HumidityIcon = WeatherIcon;
const WindIcon = WeatherIcon;
const data = {
  temp: '23/48 C',
  hum: '16 mm',
  wind: '34km/h',
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minWidth: '13rem',
    justifyContent: 'space-between',
  },
  value: {
    fontSize: 10,
    color: '#c8c8c8',
  },
});

export default function Weather() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <TemperatureIcon />
        <div className={classes.value}>{data['temp']}</div>
      </div>
      <div>
        <HumidityIcon />
        <div className={classes.value}>{data['hum']}</div>
      </div>
      <div>
        <WindIcon />
        <div className={classes.value}>{data['wind']}</div>
      </div>
    </div>
  );
}
