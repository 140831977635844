import Axios from 'axios';
import { envOptions } from '../../config';
const { root_URL } = envOptions;
const url = `${root_URL}/region`;

const getRegions = () => {
  return Axios.get(`${url}`);
};

export { getRegions };
