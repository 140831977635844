import React from 'react';
import { withStyles } from '@material-ui/core';

import { pollutantRangeReference } from 'common/config';
import { getLevel } from 'common/utils';

// TODO: Add this component
const styles = theme => ({
  table: {
    width: '100%',
    color: theme.palette.text.primary,
    padding: '0 16px',
    margin: '20px 0',
    borderCollapse: 'collapse',
  },
  heading: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: 0,
  },

  tableRow: {
    borderBottom: `2px solid rgba(174,174,174,0.4)`,
    height: 40,
    cursor: 'default',
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
    '& td': {
      opacity: 0.7,
      '&:nth-last-child(1)': {
        opacity: 1,
      },
    },
  },
  band: {
    paddingLeft: 20,
  },
  bandColor: {
    display: 'inline-block',
    height: 12,
    width: 12,
    borderRadius: 4,
    marginRight: 12,
  },
  reference: {
    opacity: 0.7,
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'right',
    color: theme.palette.text.primary,
  },
  referenceLink: {
    color: theme.palette.type === 'dark' ? '#ca9df3' : '#0000ee',
  },
  [theme.breakpoints.down('md')]: {
    root: {
      margin: '15px 30px',
    },
    table: {
      textAlign: 'left',
    },
    reference: {
      textAlign: 'center',
    },
  },
});

const getBands = pollutant => {
  const ranges = pollutantRangeReference[pollutant].slice(0, -1);

  return ranges.map((value, index) => {
    const band = [value === 0 ? value : ++value, ranges[index + 1] || 1000];
    return { band, ...getLevel(pollutant, value) };
  });
};

const RangeTable = ({ data, pollutant, classes }) => {
  const bands = getBands(pollutant);

  return (
    <div className={classes.root}>
      <h3 className={classes.heading}>
        {pollutant.toUpperCase()} Reference Chart
      </h3>
      <table className={classes.table}>
        <tbody>
          {bands.slice(0, -1).map(({ band, label, color }, index) => {
            const { value } = data.find(d => d.originalLabel === label) || {};
            return (
              <tr key={index} className={classes.tableRow}>
                <td className={classes.band}>
                  <span
                    className={classes.bandColor}
                    style={{
                      background: color,
                    }}
                  />
                </td>
                <td>{`${band[0]} - ${band[1]}`}</td>
                <td>{label}</td>
                <td>{value || 0}%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={classes.reference}>
        AQI reference taken from{' '}
        <a
          href="https://en.wikipedia.org/wiki/Air_quality_index"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.referenceLink}
        >
          this
        </a>{' '}
        site
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(RangeTable);
