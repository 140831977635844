import {
  pollutantRangeReference,
  pollutantLabelColorReference,
} from '../config';

/**
 *
 * @param {*} width =width of rectangle formed by graph , x[n]-x[0]
 * @param {*} pollutant  = Pollutant
 * @param {*} area  = area of formed graph by input values
 */

function getAreaLabel(width, pollutant, area) {
  for (
    let index = 1;
    index < pollutantRangeReference[pollutant].length;
    index++
  ) {
    const height = pollutantRangeReference[pollutant][index];
    const area2 = height * width;
    if (area2 > area) {
      return pollutantLabelColorReference[index - 1];
    }
  }
  return pollutantLabelColorReference[pollutantLabelColorReference.length - 1];
}

/**
 *
 * @param {*} arr = an array of objects ex.{pm25:34}
 * @param {*} pollutant =  = an array of objects ex.{pm25:34}
 * @param {*} limit = Minimum percentage of required non null values in input. Default is 50%
 */
export default function calculateExposure(arr, pollutant, limit = 0.4) {
  if (!arr || !pollutant) return null;

  //Create array containing good non null values values of pollutant
  //filter input data by deleting null values and create mapped array in {x:time,y:values} form

  const filteredData = arr
    .filter(value => value[pollutant])
    .map(value => {
      const date = new Date(value.datetime);
      return {
        y: value[pollutant],
        x: date.getTime(),
      };
    });

  //return 0 if percentage of input non null values is not sufficient
  if (filteredData.length <= 0 || filteredData.length / arr.length < limit) {
    return 0;
  }

  //Get area under the graph by calculating area of rectangle
  const goodArea =
    pollutantRangeReference[pollutant][1] *
    ((filteredData[filteredData.length - 1].x - filteredData[0].x) / 3600000);

  // Get area under the graph from real input values
  const realArea = filteredData
    //.slice()
    .reduce(function (area, current, index, array) {
      if (index !== 0) {
        return (
          area +
          0.5 *
            (current.y + array[index - 1].y) *
            ((current.x - array[index - 1].x) / 3600000)
        );
      } else {
        return area;
      }
    }, 0);

  //return ratio

  const result = getAreaLabel(
    (filteredData[filteredData.length - 1].x - filteredData[0].x) / 3600000,
    pollutant,
    realArea
  );
  result['value'] = (realArea / goodArea).toFixed(1);
  return result;
}
