import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { festiveDates, getTicks } from './chart-inner-components';
import SingleStationChart from './single-station-chart';
import MultiStationChart from './multi-station-chart';
import NoData from '../../assets/images/no-data.svg';
import {
  getChartData,
  calculateAllDataSize,
  getForecastingData,
} from '../../utils/chart-utils';
import { makeStyles } from '@material-ui/core';
import usePrevious from '../custom-hooks/previous-hook';
import { isEqual } from 'lodash';
const FESTIVALS = [
  {
    datetime: '2018-11-07T12:30:44.582Z',
    name: 'Diwali',
  },
  {
    datetime: '2019-10-27T12:30:44.582Z',
    name: 'Diwali',
  },
  {
    datetime: '2020-03-25T00:00:00.000Z',
    name: 'Corona Virus First Wave',
  },
  {
    datetime: '2020-11-14T10:04:28.000Z',
    name: 'Diwali',
  },
  {
    datetime: '2021-03-13T10:04:28.000Z',
    name: 'Corona Virus Second Wave',
  },
  {
    datetime: '2021-11-04T10:04:28.000Z',
    name: 'Diwali',
  },
  {
    datetime: '2022-10-24T10:04:28.000Z',
    name: 'Diwali',
  },
];

const chartStyles = makeStyles(theme => ({
  // TODO: Too much CSS, try to reduce it
  noData: {
    marginLeft: 'calc(4rem - 5px)',
    width: 'calc(100% - calc(4rem - 5px))',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  [theme.breakpoints.between('xs', 960)]: {
    noData: {
      // TODO: We need to find a better way to handle the margin thing.
      // Research about it, try different approaches.
      // This CSS is dangerous
      width: 'calc(100% + 15vw - 70px)',
      marginLeft: '70px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    noData: {
      // TODO: We need to find a better way to handle the margin thing.
      // Research about it, try different approaches.
      // This CSS is dangerous
      width: 'calc(100% - 20px)',
      marginLeft: '70px',
    },
  },
}));

const Chart = ({
  data,
  pollutant,
  duration,
  settings,
  mean,
  hideLegend,
  smallView,
  classNameNoData, //TODO: fix custom class name var through design system
}) => {
  const classes = chartStyles();
  const [chartData, setChartData] = useState([]);
  const [forecasting, setForecasting] = useState([]);
  const [stationName, setStationName] = useState(null);
  const [xAxisTicks, setXAxisTicks] = useState(null);
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.between(960, 'md'));
  const iPadSize = useMediaQuery(theme.breakpoints.between('sm', 960));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const prevData = usePrevious(data);

  useEffect(() => {
    if (isEqual(prevData, data)) return;
    let stationMap = new Map();

    Object.keys(data).forEach(slug => {
      stationMap.set(slug, data[slug].meta.name);
    });
    const forecastData = getForecastingData(data, duration);
    setForecasting(forecastData);
    setStationName(stationMap);
    const chart = getChartData(data);

    const combinedData = chart.concat(forecastData);
    setChartData(combinedData);

    setXAxisTicks(
      getTicks(combinedData, 'datetime', mobile || smallView, duration)
    );
  }, [prevData, data, duration, mobile, smallView]);

  const dataSize = calculateAllDataSize(data, pollutant);
  const festiveDataPoints = festiveDates(data, FESTIVALS, pollutant);

  if (!Boolean(dataSize)) {
    return (
      <div className={`${classes.noData} ${classNameNoData}`}>
        <img src={NoData} width="50%" height="50%" alt="No data available" />
        Not enough data!!!
      </div>
    );
  }

  const commonChartProps = {
    chartData: chartData,
    forecasting: forecasting,
    settings: settings,
    xAxisTicks: xAxisTicks,
    festiveDataPoints: festiveDataPoints,
    pollutant: pollutant,
    mean: mean,
    duration: duration,
    data: data,
    tablet: tablet,
    mobile: mobile,
    iPadSize: iPadSize,
    smallView: smallView,
  };
  return (
    <>
      {Object.keys(data).length === 1 ? (
        <SingleStationChart {...commonChartProps} />
      ) : (
        <MultiStationChart
          stationName={stationName}
          hideLegend={hideLegend}
          {...commonChartProps}
        />
      )}
    </>
  );
};
//default props settings because settings is not part of props coming for embed component
Chart.defaultProps = {
  settings: {
    points: true,
    healthy: true,
    interpolation: true,
    grid: true,
    annotations: true,
    mean: false,
  },
};

// Todo Splits & simplify this file into chunks
export default Chart;
