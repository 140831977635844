import Axios from 'axios';

function setAxiosHeaderToken(token) {
  if (!token) return;
  Axios.interceptors.request.use(
    function (config) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

export { setAxiosHeaderToken };
