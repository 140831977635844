import ReactGA from 'react-ga';

export default function (eventData) {
  const { category = 'Map', label = 'map interaction' } = eventData;
  ReactGA.event({
    category: category,
    label: label,
    ...eventData,
  });
}
