import React from 'react';
import {
  SafeLine,
  MeanLine,
  FestiveLine,
  CustomizedAxisTick,
  CustomToolTip,
  CustomCursor,
  ReferenceBands,
  PredictedReferenceArea,
} from './chart-inner-components';
import {
  AreaChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Area,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';
import { getLevel, getSafeLimit } from 'common/utils';
import { darken } from '@material-ui/core/styles/colorManipulator';

//TODO: Find a new way to solve smallView - can't append new variable evrytime
const getChartWidth = (tablet, iPadSize, mobile, smallView) => {
  return tablet
    ? '100%'
    : mobile || iPadSize
    ? '118.5%'
    : smallView
    ? '71%'
    : '110.8%';
};

export default function SingleStationChart(props) {
  const {
    chartData,
    settings,
    xAxisTicks,
    festiveDataPoints,
    pollutant,
    mean,
    tablet,
    duration,
    data,
    forecasting,
    mobile,
    iPadSize,
    smallView,
  } = props;

  return (
    <ResponsiveContainer
      width={getChartWidth(tablet, iPadSize, mobile, smallView)}
      height="100%"
      style={(mobile || smallView) && { paddingTop: '20px' }}
    >
      <AreaChart
        data={chartData}
        margin={{
          right: mobile || tablet || iPadSize || smallView ? 0 : 130,
          left: tablet ? 20 : smallView ? -35 : 0,
          top: 0,
          bottom: mobile || smallView ? 15 : 30,
        }}
      >
        {PredictedReferenceArea(forecasting || [], mobile || smallView)}
        {/* TODO: Try to use `hide` property wherever possible */}
        {settings.grid && <CartesianGrid stroke="#F4F4F4" vertical={false} />}
        {settings.mean &&
          Object.keys(data).map(slug => {
            return MeanLine(mean[slug], tablet);
          })}
        {settings.annotations &&
          festiveDataPoints.map(value => {
            if (!value) return null;
            return FestiveLine(
              value.datetime,
              value.payload,
              tablet,
              value.name,
              pollutant
            );
          })}
        {settings.healthy &&
          SafeLine(
            pollutant,
            getSafeLimit,
            iPadSize || mobile || tablet || smallView
          )}
        <ReferenceArea shape={<ReferenceBands pollutant={pollutant} />} />

        <YAxis
          domain={[0, dataMax => (dataMax <= 1000 ? dataMax + 10 : 1000)]}
          tickLine={false}
          axisLine={false}
          tick={{
            stroke: tablet ? '#878787' : '#c8c8c8',
            strokeWidth: tablet ? 0.7 : 0.5,
            fontSize: 12,
            transform: mobile ? 'translate(45,-6)' : '',
          }}
        />

        <XAxis
          tickLine={true}
          dataKey="datetime"
          tick={
            <CustomizedAxisTick
              duration={duration}
              mobile={mobile || smallView}
            />
          }
          ticks={xAxisTicks}
          minTickGap={0}
          interval={0}
        />
        {Object.keys(data).map(slug => {
          return (
            <Area
              key={slug}
              type="monotone"
              dataKey={`${slug}-${pollutant}`}
              connectNulls={settings.interpolation}
              dot={settings.points}
              stroke={darken(getLevel(mean[slug], pollutant).color, 0.005)}
              strokeWidth={mobile ? 1 : 1}
              strokeOpacity={mobile ? 0.4 : 0.8}
              fillOpacity={0.3}
              fill={getLevel(mean[slug], pollutant).color || 'grey'}
              isAnimationActive={settings.points ? false : true}
            />
          );
        })}

        {Object.keys(data).map(slug => {
          return (
            <Area
              key={`${slug}-forecast`}
              type="monotone"
              dataKey={`forecasting-${slug}-${pollutant}`}
              connectNulls={settings.interpolation}
              dot={settings.points}
              strokeDasharray="5 5"
              stroke={darken(getLevel(mean[slug], pollutant).color, 0.005)}
              strokeWidth={mobile ? 1 : 1}
              strokeOpacity={mobile ? 0.4 : 0.8}
              fillOpacity={0.3}
              fill={getLevel(mean[slug], pollutant).color || 'grey'}
              isAnimationActive={settings.points ? false : true}
            />
          );
        })}

        <Tooltip
          content={
            <CustomToolTip
              duration={duration}
              pollutant={pollutant}
              multi={false}
            />
          }
          cursor={<CustomCursor />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
