import React from 'react';
import EmbedHoc from '../hoc/embed-data-hoc';
import { exposureAndMean } from '../../utils/chart-utils';
import ChartComponent from '../chart/chart-component';
import { Loader } from '../loaders';
import StationList from './station-list';
import { makeStyles } from '@material-ui/core/styles';

const embedStyles = makeStyles(theme => ({
  stations: {
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    stations: {
      width: '100%',
    },
  },
}));

const EmbedChart = props => {
  const {
    data,
    pollutant,
    chartSettings: settings,
    allStations,
    selectedStations,
    duration,
    deleteStation,
  } = props;

  const classes = embedStyles();
  const { mean } = exposureAndMean(data, pollutant);
  const stations = selectedStations.map(slug => {
    return allStations.find(({ slug: stationSlug }) => stationSlug === slug);
  }, {});
  if (!selectedStations || !data || Object.keys(data).length < 1) {
    return <Loader />;
  }

  return (
    <div style={{ height: '100%', width: '100%', marginBottom: 50 }}>
      <div
        style={{
          width: 'calc(100% + 30px)',
          height: '50vh',
          margin: '10px -60px',
          marginTop: 50,
          gridArea: 'chart',
        }}
      >
        <ChartComponent
          pollutant={pollutant}
          data={data}
          mean={mean}
          duration={duration}
          settings={settings}
          hideLegend={true}
        />
      </div>
      <div className={classes.stations}>
        <p style={{ fontSize: 14 }}>Stations</p>
        {stations && (
          <StationList
            stations={stations}
            selectedStations={selectedStations}
            deleteStation={deleteStation}
          />
        )}
      </div>
    </div>
  );
};

export default EmbedHoc(EmbedChart, 'Time series graph');
