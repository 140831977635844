import React from 'react';
import EmbedHoc from '../hoc/embed-data-hoc';
import { exposureAndMean } from '../../utils/chart-utils';
import MeanChart from '../chart/mean';
import ExposureChart from '../chart/exposure';
import StationList from './station-list';

import { makeStyles } from '@material-ui/core/styles';
const embedStyles = makeStyles(theme => ({
  embed: {
    display: 'grid',
    gridColumnGap: '5%',
    gridTemplateColumns: '3fr 3fr 4fr',
    height: '100%',
  },
  stations: {
    marginTop: '12px',
    width: '35%',
  },
  [theme.breakpoints.down('sm')]: {
    embed: {
      display: 'grid',
      gridColumnGap: '5%',
      gridTemplateColumns: '3fr 3fr',
      height: '100%',
    },
    stations: {
      marginTop: '12px',
      width: '35%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    embed: {
      display: 'block',
      height: '100%',
      '& > div': {
        margin: '12px 0',
      },
    },
    stations: {
      marginTop: '12px',
      width: '100%',
    },
  },
}));
const Embed = props => {
  const {
    data,
    pollutant,
    allStations,
    selectedStations,
    duration,
    deleteStation,
  } = props;
  const { exposure, mean } = exposureAndMean(data, pollutant);

  const stations = selectedStations.map(slug => {
    return allStations.find(({ slug: stationSlug }) => stationSlug === slug);
  }, {});

  const classes = embedStyles();

  return (
    <div style={{ height: '100%', width: '100%', marginBottom: 50 }}>
      <div className={classes.embed}>
        <ExposureChart exposure={exposure} duration={duration} />

        <MeanChart pollutant={pollutant} duration={duration} mean={mean} />
      </div>
      <div className={classes.stations}>
        <p style={{ fontSize: 14 }}>Stations</p>
        {stations && (
          <StationList
            stations={stations}
            selectedStations={selectedStations}
            deleteStation={deleteStation}
          />
        )}
      </div>
    </div>
  );
};

export default EmbedHoc(Embed, 'Mean & Exposure');
