import React from 'react';
import { colors as COLORS } from 'common/config';
import Delete from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';

const listStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labels: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  svgLabel: {
    width: '18px',
    height: '18px',
  },
  name: {
    fontSize: 14,
  },
  deleteIcon: {
    fontSize: 20,
  },
  label: {
    marginTop: 2,
    marginRight: 10,
  },
  [theme.breakpoints.down('xs')]: {
    svgLabel: {
      width: '12px',
      height: '12px',
    },
    name: {
      fontSize: 12,
    },
    deleteIcon: {
      fontSize: 18,
    },
    label: {
      marginTop: -3,
    },
  },
}));

export default function StationList({
  stations,
  selectedStations,
  deleteStation,
}) {
  const classes = listStyles();
  return stations.map(({ name, slug }, index) => {
    return (
      <div style={{ margin: '12px 0' }}>
        <div className={classes.root}>
          <div className={classes.labels}>
            <div className={classes.label}>
              <svg className={classes.svgLabel}>
                <rect className={classes.svgLabel} fill={COLORS[index]} />
              </svg>
            </div>
            <div className={classes.name}>{name}</div>
          </div>
          <div>
            <Delete
              className={classes.deleteIcon}
              style={
                selectedStations.length < 2
                  ? { opacity: 0.1 }
                  : { cursor: 'pointer' }
              }
              onClick={() =>
                selectedStations.length < 2 ? null : deleteStation(slug)
              }
            />
          </div>
        </div>
      </div>
    );
  });
}
