import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TrafficIcon from '@material-ui/icons/Traffic';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifySelf: 'flex-end',
    marginLeft: 'auto',
    alignSelf: 'center',
  },
  suggestion: {
    textAlign: 'center',
    padding: '0 0.5rem',
  },
  icon: {
    fontSize: '2rem',
  },
  text: {
    fontSize: 12,
    color: '#171717',
  },
});

export default function Suggestions() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.suggestion}>
        <TrafficIcon className={classes.icon} />
        <div className={classes.text}>Suggestion Text</div>
      </div>
      <div className={classes.suggestion}>
        <TrafficIcon className={classes.icon} />
        <div className={classes.text}>Suggestion Text</div>
      </div>
      <div className={classes.suggestion}>
        <TrafficIcon className={classes.icon} />
        <div className={classes.text}>Suggestion Text</div>
      </div>
    </div>
  );
}
