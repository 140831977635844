import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import UpArrow from '@material-ui/icons/ArrowUpward';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

const styles = theme => ({
  icon: {
    position: 'fixed',
    bottom: 31,
    right: 31,
    borderRadius: '50%',
    background: theme.palette.text.secondary,
    padding: '0.5rem',
    color: '#fff',
    cursor: 'pointer',
  },
});

// TODO: Refactor this to Hooks

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    this.showTopButtonDebounced = _.debounce(
      this.showTopButton.bind(this),
      300
    );
  }

  componentDidMount() {
    window.addEventListener('scroll', this.showTopButtonDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.showTopButtonDebounced);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  showTopButton() {
    this.setState({
      visible: window.scrollY > window.innerHeight,
    });
  }
  render() {
    const { visible } = this.state;
    const { classes } = this.props;
    if (!visible) return null;

    return (
      <Tooltip placement="left" title="Scroll to top">
        <UpArrow className={classes.icon} onClick={this.scrollToTop} />
      </Tooltip>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ScrollToTop);
