//  https://www.geodatasource.com/developers/javascript
const calculateDistance = (coord1, coord2) => {
  const { longitude: lon1, latitude: lat1 } = coord1;
  const { longitude: lon2, latitude: lat2 } = coord2;
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    return dist * 60 * 1.1515 * 1.609344;
  }
};

// TODO: Move these functions to the common utils
const findNearestStation = (stations, currentLocation) => {
  let nearestLocation = null;
  let distance = Number.MAX_SAFE_INTEGER;

  for (const station of stations) {
    const currentDistance = calculateDistance(currentLocation, station);
    if (currentDistance < distance) {
      nearestLocation = station;
      distance = currentDistance;
    }
  }

  return { station: nearestLocation, distance };
};

const currentLocation = displayErrorMessage => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve(position);
      },
      error => {
        const message =
          error.code === 1
            ? 'Location access denied'
            : 'Some internal error occurred';
        displayErrorMessage(message);
        reject(message);
      },
      { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
    );
  });
};

export { calculateDistance, findNearestStation, currentLocation };
