import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';
import Fade from 'react-reveal/Fade';

const styles = theme => ({
  root: {
    width: '95%',
    margin: '1rem 0.5rem',
    height: '13rem',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    root: {
      width: '94%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      width: '100%',
    },
  },
});

const PollutedLoader = ({ classes }) => {
  return (
    <Fade cascade>
      <div className={classes.root}>
        <ContentLoader
          height={200}
          width={200}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="2" y="0" rx="4" ry="4" width="113" height="25" />
          <rect x="2" y="45" rx="4" ry="4" width="180" height="55" />
          <rect x="2" y="115" rx="4" ry="4" width="180" height="25" />
        </ContentLoader>
      </div>
    </Fade>
  );
};

export default withStyles(styles, { withTheme: true })(PollutedLoader);
