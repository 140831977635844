import React, { Suspense } from 'react';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

// For state in routers
import { RouterToUrlQuery } from 'react-url-query';
import { __RouterContext as RouterContext } from 'react-router';

import Drawer from '@material-ui/core/Drawer';
import Callback from './auth/callback';
// Helper Components
import { Sidebar, Demo } from './components';
import { Loader } from './components/loaders';
// All Embeds
import MapEmbed from './components/embed/embed-map';
import BarEmbed from './components/embed/embed-bar-chart';
import DistributionEmbed from './components/embed/embed-distribution-chart';
import HeatMapEmbed from './components/embed/embed-heatmap';
import RadarEmbed from './components/embed/embed-radar';
import TableEmbed from './components/embed/embed-table';
import ChartEmbed from './components/embed/embed-chart';
// All Pages
import Home from './pages/home';
import ComingSoon from './pages/coming-soon';
import AllMonitors from './pages/monitors';
import Chart from './pages/chart';
import Embed from './pages/embed';
import SecuredRoute from './auth/secured-route';

import Location from './pages/location';
import ViewStats from './pages/view-stats';

const Embeds = ({ match }) => (
  <div>
    <Route path={match.url} exact component={Embed} />
    <Route
      path={match.url + '/timeseries-graph'}
      exact
      component={ChartEmbed}
    />
    <Route path={match.url + '/map'} exact component={MapEmbed} />
    <Route
      path={match.url + '/distribution'}
      exact
      component={DistributionEmbed}
    />
    <Route path={match.url + '/heatmap'} exact component={HeatMapEmbed} />
    <Route path={match.url + '/radar'} exact component={RadarEmbed} />
    <Route path={match.url + '/table'} exact component={TableEmbed} />
    <Route path={match.url + '/bar'} exact component={BarEmbed} />
  </div>
);
const MetricsMonitoring = React.lazy(() => import('./pages/monitoring'));
const MonitorStats = React.lazy(() => import('./pages/monitor-stats'));
const APICounter = React.lazy(() => import('./pages/api-counter'));
const RegionDashboard = React.lazy(() => import('./pages/region-dashboard'));

const checkLocations = (monitors, component, loader = Loader) =>
  monitors && monitors.length > 0 ? component : loader;

export default function AppRouter({
  history,
  sidebarOpen,
  toggleDrawer,
  locations,
}) {
  return (
    <Router history={history}>
      <RouterToUrlQuery routerContext={RouterContext}>
        <>
          <Drawer
            anchor="left"
            onClick={() => toggleDrawer('left')}
            open={sidebarOpen}
          >
            <div
              className="app_sidebar"
              tabIndex={0}
              role="button"
              onClick={() => toggleDrawer('left')}
              onKeyDown={() => toggleDrawer('left')}
            >
              <Sidebar />
            </div>
          </Drawer>

          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                path="/"
                exact
                component={checkLocations(locations, Home)}
              />

              <Route path="/demo" exact component={Demo} />

              <Route path="/coming-soon" exact component={ComingSoon} />
              <Route
                path="/monitors"
                exact
                component={checkLocations(locations, AllMonitors)}
              />
              {/* Components depending on locations will be instantiated as such */}
              <Route
                path="/chart"
                exact
                component={checkLocations(locations, Chart)}
              />
              <Route
                path="/embed"
                component={checkLocations(locations, Embeds)}
              />
              <Route
                path="/location"
                exact
                component={checkLocations(locations, Location)}
              />
              <Route
                path="/location"
                exact
                component={checkLocations(locations, Location)}
              />
              <Route path="/breezo-embed" exact component={ComingSoon} />

              {/* TODO: Combine region dashboard route into one */}
              {/* Regional Dashboard Router - AQI */}
              <SecuredRoute
                path="/region/:region"
                exact
                component={checkLocations(locations, RegionDashboard)}
              ></SecuredRoute>

              {/* Regional Dashboard Router - Monitor */}
              <SecuredRoute
                path="/region/:region/monitors"
                exact
                component={checkLocations(locations, RegionDashboard)}
              ></SecuredRoute>

              <SecuredRoute
                path="/metrics-monitoring"
                exact
                component={MetricsMonitoring}
              />
              <SecuredRoute
                path="/monitor-stats"
                exact
                component={MonitorStats}
              />
              <SecuredRoute path="/view-stats" exact component={ViewStats} />
              <Route path="/callback" exact component={Callback} />
              <SecuredRoute path="/api-counter" exact component={APICounter} />

              {/**Redirect to homepahe when url is invalid */}
              <Route render={() => <Redirect to={{ pathname: '/' }} />} />
            </Switch>
          </Suspense>
        </>
      </RouterToUrlQuery>
    </Router>
  );
}
