import React from 'react';
import StateCard from '../components/state-card';
import { categorizeBy } from '../utils';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Header, Footer } from '../layout';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 calc(10rem - 16px)',
    overflow: 'hidden',
  },
  [theme.breakpoints.down('md')]: {
    root: {
      padding: '0rem calc(5rem - 16px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '0 calc(2rem - 16px)',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: '0 calc(0.5rem - 16px)',
    },
  },
}));

const AllMonitors = () => {
  const locations = useSelector(state => state.locations);
  const stationsCategorized = categorizeBy('state')(locations);
  const states = orderBy(
    Object.keys(stationsCategorized),
    [state => state.toLowerCase()],
    ['asc']
  );

  const classes = useStyles();
  return (
    <>
      <Header />
      <div className={classes.root}>
        {states.map(state => (
          <StateCard
            state={state}
            key={state}
            data={stationsCategorized[state]}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};
export default AllMonitors;
