import React, { Component } from 'react';
import store from 'store2';

import dayjs from 'dayjs';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';

import TranslateIcon from '@material-ui/icons/Translate';

import languages from '../translation';

const styles = theme => ({
  translateIcon: {
    height: 40,
    width: 40,
  },
  icon: {
    color: '#C8C8C8',
  },
  dropdown: {
    marginTop: 5,
  },
  [theme.breakpoints.down('sm')]: {
    translateIcon: {
      height: 20,
      width: 20,
      margin: '0 8px',
    },
    dropdown: {
      marginTop: 20,
    },
  },
  [theme.breakpoints.up('md')]: {
    paddingFix: {
      padding: '12px',
    },
  },
});

// TODO: Refactor to hooks
class TranslateComponent extends Component {
  state = {
    open: false,
    currentLan: this.props.i18n.language,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleClick = lng => () => {
    this.props.i18n.changeLanguage(lng, (err, t) => {
      if (err) console.log(err);
      store('language', lng);
      dayjs.locale(languages[lng].dayjs);
      this.setState({ ...this.state, currentLan: lng, open: false });
    });
  };

  render() {
    const { open, currentLan } = this.state;
    const { classes } = this.props;
    return (
      <>
        <IconButton
          className={(classes.translateIcon, classes.paddingFix)}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          onClick={this.handleToggle}
        >
          <TranslateIcon className={classes.icon} />
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              className={classes.dropdown}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper elevation={2}>
                <ClickAwayListener
                  onClick={this.handleClose}
                  onClickAway={this.handleClose}
                >
                  <MenuList>
                    {Object.keys(languages).map(key => (
                      <MenuItem
                        key={key}
                        selected={currentLan === key}
                        onClick={this.handleClick(key)}
                      >
                        {languages[key].label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles, { withTheme: true })
)(TranslateComponent);
