import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import ReduxPromise from 'redux-promise';
import initialState from './reducers/initialState';

import { analytics } from './middlewares';

const createStoreWithMiddleware = applyMiddleware(
  ReduxPromise,
  analytics
)(createStore);

const store = createStoreWithMiddleware(reducers, initialState);

export default store;
