import 'dayjs/locale/hi';
import 'dayjs/locale/pa-in';

// TODO: Move to three different files
// Also build a mechanism to check if the one translation exists in all the 3 languages.
export default {
  en: {
    dayjs: 'en',
    label: 'English (English)',
    translation: {
      lastUpdated: 'Last Updated',
      mean: 'Mean',
      meanLevel: 'Mean Level',
      exposure: 'Exposure',
      radarGraph: 'Radar Graph',
      distribution: 'Distribution',
      breezo: 'BreeZo ',
      visualizer: 'Visualizer ',
      airPollutionData: 'Air Pollution Data ',
      aqi: 'AQI ',
      news: 'News',
      inNews: 'In the $t(news)',
      moreNews: 'More $t(news)',
      ago: 'Ago',
      noLocationFound:
        'No location found on Record. Please click on Locate button.',
      nearestStation: 'Nearest Station to ',
      currentLocation: 'your current location is ',
      lastKnownLocation: 'your last known location is ',
      kms: 'kms ',
      away: 'away',
      polluted: 'Polluted ',
      locations: 'Locations ',
      latest: 'Latest ',
      map: 'Map',
      station: 'Station ',
      compare: 'Compare ',
      calendar: 'Calendar ',
      heatmap: 'Heatmap ',
      embed: 'Embed ',
      nav: {
        home: 'Home',
        top: 'Top $t(polluted) $t(locations)',
        news: '$t(latest) $t(news)',
        map: '$t(polluted) $t(map)',
        compare: '$t(station) $t(compare)',
        heatmap: '$t(calendar) $t(heatmap)',
        embed: '$t(breezo) $t(embed)',
      },
      signIn: 'Sign In',
      signOut: 'Sign Out',
      breezoWhat: 'What is $t(breezo)',
      breezoWho: 'Who are we?',
    },
  },
  hi: {
    dayjs: 'hi',
    label: 'Hindi (हिंदी)',
    translation: {
      lastUpdated: 'आखरी अपडेट',
      mean: 'माध्य',
      meanLevel: 'मतलब स्तर',
      exposure: 'अनावरण',
      radarGraph: 'रडार ग्राफ',
      distribution: 'वितरण',
      breezo: 'ब्रीज़ो ',
      visualizer: 'विसुआलिज़ाएर ',
      airPollutionData: 'वायु प्रदूषण डेटा ',
      aqi: 'अक़ुई ',
      news: 'ख़बर ',
      inNews: '$t(news) में',
      moreNews: 'और $t(news)',
      ago: 'पहले',
      noLocationFound:
        'रिकॉर्ड पर कोई स्थान नहीं मिला। कृपया लोकेट बटन पर क्लिक करें।',
      nearestStation: 'निकटतम स्टेशन ',
      currentLocation: 'आपका वर्तमान स्थान से है ',
      lastKnownLocation: 'आपके अंतिम ज्ञात स्थान के अनुसार ',
      polluted: 'प्रदूषित',
      locations: 'स्थान ',
      latest: 'ताज़ा ',
      map: 'नक्शा ',
      station: 'स्टेशन ',
      compare: 'तुलना ',
      calendar: 'कैलेंडर ',
      nav: {
        home: 'होम ',
        top: 'टॉप $t(polluted) $t(locations)',
        news: '$t(latest) $t(news)',
        map: '$t(polluted) $t(map)',
        compare: '$t(station) $t(compare)',
        heatmap: '$t(calendar) $t(heatmap)',
        embed: '$t(breezo) $t(embed)',
      },
      kms: 'किलोमीटर ',
      away: 'दूर',
      signIn: 'साइन इन ',
      signOut: 'साइन आउट',
      breezoWhat: '$t(breezo) क्या है?',
      breezoWho: 'हम कौन है?',
    },
  },
  pa: {
    dayjs: 'pa-in',
    label: 'Punjabi (ਪੰਜਾਬੀ)',
    translation: {
      lastUpdated: 'ਆਖਰੀ ਵਾਰ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ',
      mean: '.ਸਤ',
      meanLevel: 'ਮਤਲਬ ਦਾ ਪੱਧਰ',
      exposure: 'ਐਕਸਪੋਜਰ',
      distribution: 'ਵੰਡ',
      radarGraph: 'ਰਾਡਾਰ ਗ੍ਰਾਫ',
      breezo: 'ਬ੍ਰੀਜ਼ੋ ',
      visualizer: 'ਵਿਸ਼੍ਹ੍ਵਲਾਇਜ਼ਯਰ ',
      airPollutionData: 'ਹਵਾ ਪ੍ਰਦੂਸ਼ਣ ਡਾਟਾ ',
      aqi: 'ਅਕ਼ੂਈ ',
      news: 'ਖ਼ਬਰਾਂ ',
      inNews: '$t(news) ਵਿਚ',
      moreNews: 'ਹੋਰ ਖ਼ਬਰਾਂ',
      ago: 'ਪਹਿਲਾਂ',
      noLocationFound: 'ਰਿਕਾਰਡ ਤੇ ਕੋਈ ਸਥਾਨ ਨਹੀਂ ਮਿਲਿਆ | ਲੱਭੋ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰੋ ',
      nearestStation: 'ਸਭ ਤੋਂ ਨਜ਼ਦੀਕੀ ਸਟੇਸ਼ਨ ',
      currentLocation: 'ਤੁਹਾਡੀ ਮੌਜੂਦਾ ਸਥਾਨ ਦੇ ਅਨੁਸਾਰ ਹੈ ',
      lastKnownLocation: 'ਤੁਹਾਡੇ ਆਖਰੀ ਪਛਾਣ ਵਾਲੇ ਸਥਾਨ ਅਨੁਸਾਰ',
      polluted: 'ਪ੍ਰਦੂਸ਼ਣ',
      locations: 'ਸਥਾਨ ',
      latest: 'ਤਾਜ਼ਾ ',
      map: 'ਮੈਪ ',
      station: 'ਸਟੇਸ਼ਨ ',
      compare: 'ਤੁਲਨਾ ',
      calendar: 'ਕੈਲੰਡਰ ',
      nav: {
        home: 'ਹੋਮ ',
        top: 'ਟਾਪ $t(polluted) $t(locations)',
        news: '$t(latest) $t(news)',
        map: '$t(polluted) $t(map)',
        compare: '$t(station) $t(compare)',
        heatmap: '$t(calendar) $t(heatmap)',
        embed: '$t(breezo) $t(embed)',
      },
      kms: 'ਕਿਲੋਮੀਟਰ ',
      away: 'ਦੂਰ ',
      signIn: 'ਸਾਇਨ ਇਨ',
      signOut: 'ਸਾਇਨ ਔਟ',
      breezoWhat: '$t(breezo) ਕੀ ਹੈ?',
      breezoWho: 'ਅਸੀਂ ਕੌਣ ਹਾਂ?',
    },
  },
};
