import React, { Component } from 'react';
import ReactMapGL, {
  GeolocateControl,
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
} from 'react-map-gl';
import ErrorBoundary from '../error-boundary';
import MonitorPin from './monitor-pin';
import { getStations } from 'common/api/geolocation';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { getLevel } from 'common/utils';
import { isEqual } from 'lodash';
import ShareEmbed from '../embed/share-embed';
import { mapbox_token as TOKEN } from 'common/config';
import { Embeds } from 'common/config';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import TopPollution from '../home/top-pollution';
import MapEvent from '../ga-events/map';

const styles = theme => ({
  fullscreenControlStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '10px',
  },
  navStyle: {
    position: 'absolute',
    top: 36,
    left: 0,
    margin: '10px',
  },
  shareButtonStyle: {
    position: 'absolute',
    top: 185,
    left: 0,
    margin: '10px',
  },
  geolocateStyle: {
    position: 'absolute',
    top: 140,
    left: 0,
    margin: 10,
  },
  mapstyle: {
    margin: 0,
  },
  shareButtonContainer: {
    backgroundColor: '#fff',
    width: '29px',
    height: '29px',
    textAlign: 'center',
    borderRadius: 4,
    cursor: 'pointer',
    boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
    '&:hover': {
      transition: 'all ease-in 0.3s',
      backgroundColor: '#ECECEC',
    },
  },
  [theme.breakpoints.down('xs')]: {
    shareButtonStyle: {
      display: 'none',
    },
  },
});

class MapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 23.182719,
        longitude: 80.768218,
        zoom: 4.2,
      },
      stations: [],
      monitorInfo: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.setViewPort();
    this.getData();
  }

  setViewPort() {
    const { viewport } = this.state;
    const {
      monitorPopUp = null,
      zoom = viewport.zoom,
      latitude = viewport.latitude,
      longitude = viewport.longitude,
    } = this.props;

    const newViewport = {
      latitude: latitude,
      longitude: longitude,
      zoom: zoom,
    };
    this.setState({
      monitorInfo: monitorPopUp,
      viewport: newViewport,
    });
  }

  async getData() {
    const { data } = await getStations();
    const { locations, loaderStatus } = this.props;
    const stations = locations
      .filter(location => location.latitude && location.longitude)
      .map(location => {
        const { slug } = location;
        location['status'] = 1;
        const station = find(data, { slug });
        if (!station) location['status'] = 0;
        return {
          ...location,
          ...station,
        };
      });

    this.setState({
      ...this.state,
      stations,
      loading: false,
    });

    if (loaderStatus) loaderStatus(false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      monitorPopUp,
      onChangeLatitude,
      onChangeLongitude,
      onChangeZoom,
    } = this.props;

    const {
      viewport: { zoom, latitude, longitude },
    } = this.state;

    const {
      viewport: {
        zoom: preZoom,
        latitude: preLatitude,
        longitude: preLongitude,
      },
    } = prevState;

    if (onChangeLatitude && onChangeLongitude && onChangeZoom) {
      if (preLatitude !== latitude) onChangeLatitude(latitude);
      if (preLongitude !== longitude) onChangeLongitude(longitude);
      if (preZoom !== zoom) onChangeZoom(zoom);
    }

    if (!isEqual(prevProps.monitorPopUp, monitorPopUp) && monitorPopUp) {
      const { latitude, longitude } = monitorPopUp;

      const newViewport = {
        latitude: latitude,
        longitude: longitude,
        zoom: 10.2,
      };

      this.setState({ monitorInfo: monitorPopUp, viewport: newViewport });
    }
  }

  renderCityMarker = (monitor, index) => {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={monitor.longitude}
        latitude={monitor.latitude}
      >
        <MonitorPin
          size={15}
          color={
            monitor.pm25 !== null && monitor.pm25 !== undefined
              ? getLevel(monitor.pm25, 'pm25').color
              : '#c9c8c3'
          }
          onClick={() => {
            this.setState({ monitorInfo: monitor });
            MapEvent({ action: 'clicked on monitor pin', label: monitor.slug });
          }}
          status={monitor['status']}
        />
      </Marker>
    );
  };

  renderPopup() {
    const { monitorInfo } = this.state;
    const { deleteStation } = this.props;

    return (
      monitorInfo && (
        <Popup
          dynamicPosition={true}
          tipSize={15}
          sortByDepth={true}
          anchor="top"
          longitude={monitorInfo.longitude}
          latitude={monitorInfo.latitude}
          closeOnClick={false}
          onClose={() => {
            this.setState({ monitorInfo: null });
            if (deleteStation) deleteStation(monitorInfo.slug);
          }}
        >
          <div style={{ height: 200, width: 240 }}>
            {/**Disable click event if chart opened inside embed */}
            <TopPollution
              googleEvent={MapEvent}
              slug={monitorInfo}
              effect={false}
              disableClickEvent={Boolean(deleteStation)}
            />
          </div>
        </Popup>
      )
    );
  }
  onViewportChange = viewport => {
    if (8.088306 > viewport.latitude) {
      viewport.latitude = 8.088306;
    }
    if (viewport.latitude > 34.216496) {
      viewport.latitude = 34.216496;
    }
    if (72.8 > viewport.longitude) {
      viewport.longitude = 72.8;
    }
    if (viewport.longitude > 95.24) {
      viewport.longitude = 95.24;
    }
    this.setState({ viewport });
  };

  locateViewportChange = viewport => {
    viewport.zoom = 9;
    this.setState({ viewport });
  };

  render() {
    const { isEmbed, classes } = this.props;
    return (
      <>
        <ReactMapGL
          {...this.state.viewport}
          width={this.props.width}
          height={this.props.height}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          // mapStyle="mapbox://styles/mapbox/basic-v9"
          onViewportChange={this.onViewportChange}
          mapboxApiAccessToken={TOKEN}
          className={classes.mapstyle}
          scrollZoom={false}
        >
          {this.state.stations.map(this.renderCityMarker)}

          {this.renderPopup()}

          <div
            style={{ zIndex: '-1' }}
            onClick={() => MapEvent({ action: 'clicked on locate me' })}
          >
            {/**wrapping geo locate control under error boundary to handle error thrown by component */}
            <ErrorBoundary>
              <GeolocateControl
                onViewportChange={this.locateViewportChange}
                className={classes.geolocateStyle}
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
              />
            </ErrorBoundary>
          </div>
          <div
            className={classes.fullscreenControlStyle}
            onClick={() => MapEvent({ action: 'toggled fullscreen mode' })}
          >
            <FullscreenControl />
          </div>
          <div
            className={classes.navStyle}
            onClick={() => MapEvent({ action: 'clicked on map controllers' })}
          >
            <NavigationControl />
          </div>
          {!isEmbed && (
            <div className={classes.shareButtonStyle}>
              <div className={classes.shareButtonContainer}>
                <ShareEmbed
                  iconStyle={{ width: 15, marginTop: 7 }}
                  embedName={Object.keys(Embeds)[6]}
                />
              </div>
            </div>
          )}
        </ReactMapGL>
      </>
    );
  }
}

const mapStateToProps = ({ locations }) => ({
  locations,
});

export default compose(connect(mapStateToProps), withStyles(styles))(MapBox);
