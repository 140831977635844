import Axios from 'axios';
import { envOptions } from '../config';
const { root_URL } = envOptions;

const getStations = async () => {
  let data = [];
  const res = await Axios.get(
    `${root_URL}/api/assets?pageDirection=NEXT&type=aq-ground-monitors&page_size=500`
  );

  res.data.edges.forEach(({ node }) =>
    data.push({
      ...node,
      slug: node.id,
      latitude: node.bbox.coordinates[1],
      longitude: node.bbox.coordinates[0],
      earliest: '1y',
    })
  );
  return { data };
};

export { getStations };
