import React from 'react';
import { compose } from 'redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { Loader } from '../components/loaders';
import { updateSnackbar } from 'common/store/actions';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { findNearestStation } from '../utils';

const urlPropsQueryConfig = {
  latitude: { type: UrlQueryParamTypes.number },
  longitude: { type: UrlQueryParamTypes.number },
};

function onError(dispatch, history) {
  //had to use dispatch hook inside setTimeout, other wise throwing error
  setTimeout(
    () =>
      dispatch(
        updateSnackbar(
          'Use correct latitude and longitude to go to nearest station'
        )
      ),
    0
  );
  setTimeout(() => history.push('/'), 500);
  return;
}

function Location(props) {
  const { latitude, longitude } = props;
  const locations = useSelector(state => state.locations);
  const history = useHistory();
  const dispatch = useDispatch();

  const coordinates = {
    latitude,
    longitude,
  };
  const { station } = findNearestStation(locations, coordinates);

  if (!station) {
    onError(dispatch, history);
    return <Loader />;
  }
  const { slug } = station;
  const url = `/chart?duration=1d&pollutant=pm25&stations=${slug}`;
  history.replace(url);

  return <Loader />;
}

export default compose(addUrlProps({ urlPropsQueryConfig }))(Location);
