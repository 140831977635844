import React, { useState } from 'react';
import useScrollPosition from '../custom-hooks/scroll-hook';
import PollutantRanges from './mobile-pollutant-range-block';
import { useTheme } from '@material-ui/core/styles';

export default function ScrollColorScale({ pollutant }) {
  const [show, setShow] = useState(true);
  const theme = useTheme();

  useScrollPosition(({ currPos }) => {
    const { y } = currPos;

    //Use this const height if window object is undefined
    const mobileHeight = theme.breakpoints.values.sm;
    //window check for ssr
    const windowHeight = window ? window.innerHeight : mobileHeight;
    const pageHeight = document.body.scrollHeight;
    const isScrollEnd = windowHeight + y >= pageHeight - 40;

    //Show color scale only when scroll reaches half of the window height and not at the bottom of page
    setShow(!isScrollEnd);
  });

  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: -1,
        left: 0,
        zIndex: 10,
        backgroundColor: '#fff',
        width: '100%',
      }}
    >
      <PollutantRanges pollutant={pollutant} />
    </div>
  );
}
