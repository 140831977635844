const chartTourConfigDesktop = [
  {
    selector: '[data-tut="tour_search_desktop"]',
    content: `Add station to compare across multiple stations and to find pollutant related detail over here.`,
  },
  {
    selector: '[data-tut="tour_duration"]',
    content: `Click here to see data for different bands of time.`,
  },
  {
    selector: '[data-tut="tour_pollutant_desktop"]',
    content: `Click and select different pollutants like PM2.5, PM10, NOx etc.`,
  },
  {
    selector: '[data-tut="tour_next_prev_desktop"]',
    content: `Click arrows to view other stations.`,
  },
  {
    selector: '[data-tut="tour_fav"]',
    content: `Click the heart icon to favourite a station. This will appear in your favourite locations list on the home page.`,
  },
  {
    selector: '[data-tut="tour_share"]',
    content: `Share your insights with friends & family through different social media.`,
  },
];

const chartTourConfigMobile = [
  {
    selector: '[data-tut="tour_duration"]',
    content: `Tap here to see data for different bands of time.`,
  },
  {
    selector: '[data-tut="tour_pollutant_mobile"]',
    content: `Tap card to select different pollutants like PM2.5, PM10, NOx.`,
  },
  {
    selector: '[data-tut="tour_next_prev_mobile"]',
    content: `Swipe left or right to view nearby stations.`,
  },
  {
    selector: '[id="Mainmenu-action-4"]',
    content: `Tap here to go back to home page.`,
  },
  {
    selector: '[id="Mainmenu-action-3"]',
    content: `Tap the heart icon to favourite a station. This will appear in your favourite locations list on the home page.`,
  },
  {
    selector: '[id="Mainmenu-action-2"]',
    content: `Tap to search for monitors across India`,
  },
  {
    selector: '[id="Mainmenu-action-1"]',
    content: `Tap here to login and get access to extra features.`,
  },
  {
    selector: '[id="Mainmenu-action-0"]',
    content: `Tap here to change setting of pollutant chart.`,
  },
];

export { chartTourConfigDesktop, chartTourConfigMobile };
