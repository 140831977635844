import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import {
  PolarAngleAxis,
  Radar,
  RadarChart,
  PolarGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import usePrevious from '../custom-hooks/previous-hook';
import {
  colors as COLORS,
  YEARS,
  getCommonYearSelector,
  durations,
} from 'common/config';
import { getMeasurements as getData } from 'common/api/measurements';
import dayjs from 'dayjs';
import { differenceWith, omit } from 'lodash';
import { updateSnackbar } from 'common/store/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isRadarDataAvailable } from '../../utils/chart-utils';
import { Auth0Context } from '../../auth/auth0';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
  },
});

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const RenderCustomizedTick = props => {
  const {
    x,
    y,
    payload: { value },
    data,
  } = props;

  const { month } = data.find(({ key }) => key === value);

  return (
    <text
      x={x}
      y={y}
      fill="#323232"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontSize: 10,
      }}
    >
      {month}
    </text>
  );
};

const tooltipStyles = makeStyles({
  root: {
    padding: '0.5rem',
    fontSize: 12,
    '& > p': {
      margin: 0,
    },
  },
});

const CustomToolTip = props => {
  const { label, payload } = props;
  if (!payload) return null;
  const classes = tooltipStyles();
  const month = MONTHS[label];

  return (
    <Paper className={classes.root}>
      <p>{month}</p>

      {payload.map(val => {
        return (
          <p>
            <svg width={9} height={9}>
              <rect width={9} height={9} style={{ fill: val.fill }}></rect>
            </svg>
            <span>
              {' '}
              {val.name}: {val.value || 'N/A'}
            </span>
          </p>
        );
      })}
    </Paper>
  );
};

/*
rawData : array of objects, where object has two keys slug and data.
pollutant
year : year for which data is need to represent (selected year)

return: array containing monthly data of stations
*/
export function getMonthlyMean(rawData, pollutant, year) {
  if (!rawData || !pollutant || !year) return null;
  return Object.keys(rawData).map(stationSlug => {
    return rawData[stationSlug]
      .reduce(
        (acc, { [pollutant]: val, datetime }, index) => {
          if (index === 1) return acc;
          const date = dayjs(datetime);

          if (val && date.year() === +year) {
            const month = date.month();

            const { value, count, ...other } = acc[month];

            acc[month] = {
              ...other,
              value: value + val,
              count: count + 1,
              slug: stationSlug,
            };
          }
          return acc;
        },
        [...Array(12).keys()].map(key => ({
          month: MONTHS[key],
          value: 0,
          count: 0,
          slug: stationSlug,
        }))
      )
      .map(({ month, value, count, slug }, index) => ({
        key: index,
        month: month.substr(0, 3),
        [`${slug}-mean`]: count ? Math.round(value / count) : 0,
      }));
  });
}

export function combinedData(data) {
  if (data.length < 1) return null;
  //data is an array of arrays and array at index 0 is used to combine fields from other array
  //in data
  const result = data[0].slice().map((value, index) => {
    return data.reduce((acc, stationData) => {
      return Object.assign(acc, stationData[index]);
    }, value);
  });
  return result;
}

const Chart = function Chart({
  year,
  stations,
  pollutant,
  updateSnackbar,
  changeYear,
  loaderStatus,
}) {
  const { isAuthenticated } = useContext(Auth0Context);
  const classes = useStyles();
  const [rawData, setRawData] = useState({});
  const [loader, setLoader] = useState(true);
  const prevStations = usePrevious(stations);

  useEffect(() => {
    const added = differenceWith(stations, prevStations);
    const deleted = differenceWith(prevStations, stations);
    const privateDurations = durations
      .filter(el => el.isPrivate)
      .map(el => el.value);
    if (added.length > 0) {
      setLoader(true);
      const promises = added.map(async stationMeta => {
        const { slug } = stationMeta;
        try {
          const finalDuration =
            !isAuthenticated && privateDurations.includes(stationMeta.earliest)
              ? '1y'
              : stationMeta.earliest;
          const { data } = await getData(slug, finalDuration);

          return {
            slug: slug,
            data: data.data,
          };
        } catch (err) {
          updateSnackbar('Oops!!! Error while getting radar data');
          return null;
        }
      });

      Promise.all(promises).then(function (result) {
        const data = result
          .filter(val => val)
          .reduce((acc, curr) => {
            const { slug, data } = curr;
            return Object.assign(acc, { [slug]: data });
          }, rawData);

        setRawData(data);
        setLoader(false);
      });

      changeYear(getCommonYearSelector(stations, year));
    }

    if (deleted.length > 0) {
      const { slug } = deleted[0];
      setRawData(omit(rawData, slug));
      changeYear(getCommonYearSelector(stations, year));
    }
  }, [
    prevStations,
    isAuthenticated,
    stations,
    updateSnackbar,
    rawData,
    pollutant,
    year,
    changeYear,
  ]);

  if (loaderStatus) loaderStatus(loader);

  const data = getMonthlyMean(rawData, pollutant, year);
  const chartData = combinedData(data);

  if (!isRadarDataAvailable(chartData) && Object.keys(rawData).length > 0) {
    const total_years = YEARS.length;
    const currentYearIndex = YEARS.findIndex(val => val === year);
    if (currentYearIndex < total_years - 1) {
      const previousYearIndex = currentYearIndex + 1;
      changeYear(YEARS[previousYearIndex]);
    }
  }

  return (
    <ResponsiveContainer className={classes.root} width="100%" height="80%">
      <RadarChart data={chartData} isAnimationActive={true}>
        <PolarAngleAxis
          dataKey="key"
          isAnimationActive={true}
          tick={<RenderCustomizedTick data={chartData} />}
        />
        <PolarGrid isAnimationActive={true} />
        {stations.map(({ slug }, index) => {
          return (
            <Radar
              isAnimationActive={true}
              key={slug}
              dataKey={`${slug}-mean`}
              stroke="#8884d8"
              fill={COLORS[index]}
              fillOpacity={0.4}
              name="Mean"
            />
          );
        })}

        <Tooltip content={<CustomToolTip />} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const matchStateToProps = ({ snackbar }) => {
  return {
    snackbar,
  };
};

export default compose(
  connect(matchStateToProps, {
    updateSnackbar,
  })
)(Chart);
